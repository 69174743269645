import React from 'react'

function FetchingCreditscorePopup() {
  return (
    <div className='text-center'>
    <h6 className='text-center pt-5 mt-1'> Please wait while we are fetching your bureau report.</h6>
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="80"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    style={{ margin: "20px" }}
    className='justify-content-center pt-4'
  >
    <circle
      cx="50"
      cy="50"
      r="40"
      stroke="#3498db"
      strokeWidth="4"
      fill="none"
      strokeLinecap="round"
    >
      <animate
        attributeName="stroke-dashoffset"
        dur="2s"
        repeatCount="indefinite"
        from="0"
        to="502"
      />
      <animate
        attributeName="stroke-dasharray"
        dur="2s"
        repeatCount="indefinite"
        values="150.79644737231007 100.53096491487338;1 150"
      />
    </circle>
  </svg>
  </div>
  )
}

export default FetchingCreditscorePopup