import React, { useState, useEffect } from "react";
import { MdOutlineFileUpload } from "react-icons/md";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import ArthaFormControl from "../../fields/ArthaFormControl";
import { InputLabel, MenuItem, Select } from "@mui/material";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import ArthaClearButton from "../../fields/ArthaClearButton";
import Cancel from "./images/Cancel_1.png";

const ManualKYCAddressVerification = ({
    open,
    handleClose,
    popupLable,
    handleSaveFileName,
    handleDocumentType,
    handleDocumentData,
    initialDocumentType,
    initialDocumentData,
    selectedFileName,
    handleDeleteFileInParent
}) => {
    const [addressType, setAddressType] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [base64Data, setBase64Data] = useState("");
    const [isContinueButtonDisabled, setIsContinueButtonDisabled] = useState(true);
    const [tempDeletedFile, setTempDeletedFile] = useState(false);

    useEffect(() => {
        if (open) {
            setAddressType(initialDocumentType || "");
            setBase64Data(initialDocumentData || "");
            setTempDeletedFile(false);
            if (selectedFileName) {
                setSelectedFile({ name: selectedFileName });
                setIsContinueButtonDisabled(false);
            }
        }
    }, [open, initialDocumentType, initialDocumentData, selectedFileName]);

    const handleUploadFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFile(file);
            setTempDeletedFile(false);
            setIsContinueButtonDisabled(false);

            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.split(",")[1];
                setBase64Data(base64String);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleDeleteFile = () => {
        setSelectedFile(null);
        setBase64Data("");
        setIsContinueButtonDisabled(true);
        setTempDeletedFile(true);
    };

    const handleSave = () => {
        if (tempDeletedFile) {
            handleSaveFileName(null);
            handleDocumentData("");
            handleDocumentType("");
            handleDeleteFileInParent();
        } else {
            handleSaveFileName(selectedFile?.name || selectedFileName);
            handleDocumentType(addressType);
            handleDocumentData(base64Data || initialDocumentData);
        }
        handleClose(false);
    };

    const handleCancel = () => {
        setTempDeletedFile(false);
        if (selectedFileName) {
            setSelectedFile({ name: selectedFileName });
            setAddressType(initialDocumentType || "");
            setBase64Data(initialDocumentData || "");
            setIsContinueButtonDisabled(!initialDocumentType);
        }
        handleClose(true);
    };

    const handleDocumentTypeChange = (e) => {
        const newType = e.target.value;
        setAddressType(newType);
        setIsContinueButtonDisabled(!(selectedFile || selectedFileName));
    };

    return (
        <Dialog open={open} onClose={handleCancel} maxWidth="lg">
            <DialogContent>
                <div className="row d-flex justify-content-center">
                    <div className="col-12 text-center">
                        Upload {popupLable} Address Proof
                    </div>
                </div>
                <div className="col-md-8 mt-3 mt-md-4 mx-auto">
                    <ArthaFormControl fullWidth>
                        <InputLabel id="addressType-label">Type of Proof</InputLabel>
                        <Select
                            labelId="addressType-label"
                            id="addressType"
                            name="addressType"
                            value={addressType}
                            onChange={handleDocumentTypeChange}
                        >
                            <MenuItem value="" disabled>Type of Proof</MenuItem>
                            <MenuItem value="adharCard">Adhar Card</MenuItem>
                            <MenuItem value="electricityBill">Electricity Bill</MenuItem>
                            <MenuItem value="others">Others</MenuItem>
                        </Select>
                    </ArthaFormControl>
                </div>
                <div className="uploadadhar mt-4 row">
                    {(!tempDeletedFile && (selectedFile || selectedFileName)) && (
                        <div className="uploaded-image-container col-8">
                            <p className="doc-label">Document 1</p>
                            <div className="col-12">
                                <span className="" style={{ marginRight: "30px" }}>
                                    {selectedFile?.name || selectedFileName}
                                </span>
                                <span
                                    className=""
                                    onClick={handleDeleteFile}
                                    title="Delete"
                                    style={{ width: "50px" }}
                                >
                                    <img src={Cancel} alt="Cancel" />
                                </span>
                            </div>
                        </div>
                    )}

                    {(tempDeletedFile || (!selectedFile && !selectedFileName)) && (
                        <span>
                            <label
                                htmlFor="fileInput"
                                className="upload-label d-flex justify-content-center"
                            >
                                <span className="upload-icon">
                                    <MdOutlineFileUpload />
                                </span>
                                <span className="mt-1">Add File</span>
                            </label>
                            <input
                                type="file"
                                id="fileInput"
                                style={{ display: "none" }}
                                onChange={handleUploadFileChange}
                            />
                        </span>
                    )}
                </div>

                <div className="row mt-4">
                    <div className="col-6 d-flex justify-content-end">
                        <ArthaClearButton
                            type="button"
                            className="custom_btn"
                            onClick={handleCancel}
                        >
                            Cancel
                        </ArthaClearButton>
                    </div>
                    <div className="col-6 d-flex justify-content-start">
                        <ArthaContinueButton
                            type="button"
                            className="orange custom_btn"
                            onClick={handleSave}
                            disabled={isContinueButtonDisabled || !addressType}
                        >
                            Continue
                        </ArthaContinueButton>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default ManualKYCAddressVerification;