import React, { useEffect, useRef, useState } from "react";
import "./personalLoan.css";
import ArthaTextField from "../../fields/ArthaTextField";
import ArthaFormControl from "../../fields/ArthaFormControl";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import ArthaClearButton from "../../fields/ArthaClearButton";
import { toast } from "react-toastify";
import { propertyLoanBackPageAPI, propertyLoanFetchApplicantName, propertyLoanPropertyBackAPI } from "../../actioncreators/actioncreators";
import { useNavigate } from "react-router-dom";
import { handleSignOut } from "./SessionExpiredApi";



const PropertyThankYou = ({changeApplicantStatus}) => {
    const [errorDisplayed, setErrorDisplayed] = useState(false);
    const navigate = useNavigate();
    const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed

    const handleApiError = (error) => {
        if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed
    
        setErrorDisplayed(true); // Set the state to true
        errorDisplayedRef.current = true; // Set the ref to true
    
        if (error.response && error.response.status === 401) {
          handleSignOut();
          toast.error(
            "Your session has expired. You will be redirected to the Login Page."
          );
          navigate("/");
        } else if (error.response && error.response.status === 429) {
          toast.error(
            "Too Many Requests: You have exceeded the rate limit. Please try again later."
          );
        } else {
          toast.error(
            "There appears to be a technical issue connecting to our servers. Could you please try again later."
          );
        }
    
        // Reset the error flag after a certain time
        setTimeout(() => {
          setErrorDisplayed(false);
          errorDisplayedRef.current = false;
        }, 2000); // 2 seconds
      };

 

    const handleBack = async () => {
        try {
          const response = await propertyLoanPropertyBackAPI();
          if (response?.data?.businessStatusCode === 2) {
            changeApplicantStatus(response?.data?.previous_page);
          } else {
            toast.info(response.data.message);
          }
        } catch (error) {
          handleApiError(error);
          console.error("Error fetching loan data:", error);
        }
      };

    const handleContinue = () => {
        changeApplicantStatus("");
    };

    return (
        <>
            <div className="container border bg-white rounded-lg shadow-lg mt-5 p-4">
                <div className="text-center" style={{ marginTop: '30px' }}>
                    <p className="leading-relaxed text-gray-700 fw-bold ml-2" style={{ fontSize: "20px" }}>Property Details have been added Successfully! </p>
                    <p className="leading-relaxed text-gray-800" style={{ fontSize: "18px" }}>Click 'Continue' to add more Properties or Proceed with loan application.</p>
                    {/* <h5><span><h4>Applicant details are added Sucessfully.</h4></span> Click 'Continue' to add more Applicant or Proceed with loan application</h5> */}
                    {/* <h5 className="fw-bolder">You can update the current applicant details by clicking 'Back'.</h5> */}
                    <p className="leading-relaxed text-gray-800" style={{ fontSize: "18px" }}>You can update current property details by clicking on 'Back'</p>
                </div>


                <div className="row mt-5">
                    <div className="col-6 mt-5 d-flex justify-content-end mb-2">
                        <ArthaClearButton
                            variant="contained border border-dark"
                          onClick={handleBack}
                        // disabled={!isFormValid}
                        // style={clearButtonStyle}
                        >
                            Back
                        </ArthaClearButton>
                    </div>
                    <div className="col-6 mt-5 d-flex justify-content-start mb-2">
                        <ArthaContinueButton
                            variant="contained"
                            onClick={handleContinue}
                        //   style={buttonStyle}
                        >

                            Continue
                        </ArthaContinueButton>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PropertyThankYou;