import React, { useState, useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import ArthaClearButton from "../../fields/ArthaClearButton";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import ArthaTextField from "../../fields/ArthaTextField";
import { FaCheck, FaExclamationCircle } from "react-icons/fa";
import ManualKYCAddressVerification from "./ManualKYCAddressVerification";
import { BusinessfetchDistStateAPI, propertyLoanfetchDistStateAPI } from "../../actioncreators/actioncreators";
import { toast } from "react-toastify";
import { handleSignOut } from "./SessionExpiredApi";
import { useNavigate } from "react-router-dom";
const BusinessLoanAddNewPopUp = (props) => {
  const [permanentAddressLine1, setPermanentAddressLine1] = useState("");
  const [permanentAddressLine2, setPermanentAddressLine2] = useState("");
  const [permanentPincode, setPermanentPincode] = useState("");
  const [permanentCity, setPermanentCity] = useState("");
  const [permanentState, setPermanentState] = useState("");
  const [isContinueButtonEnabled, setIsContinueButtonEnabled] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedDocumentType, setDocumentType] = useState("");
  const [selectedDocumentData, setDocumentData] = useState("");
  const [isPermanentAddressProofVerified, setIsPermanentAddressProofVerified] =
    useState(false);
  const [isCurrentAddressProofVerified, setIsCurrentAddressProofVerified] =
    useState(false);
  const fileInputRefPermanent = useRef(null);
  const [pincodeError, setPincodeError] = useState("");
  // PopUpCode
  const [dialogOpen, setDialogOpen] = useState(false);
  const [popupLable, setPopupLable] = useState("");
  //Enable Button

  useEffect(() => {
    if (permanentPincode.length === 6) {
      fetchCityAndState();
    }
  }, [permanentPincode]);
  const navigate = useNavigate();
  const fetchCityAndState = async () => {
    try {
      let data = {
        pinCode: permanentPincode,
      };
      const response = await propertyLoanfetchDistStateAPI(data);

      const { district, stateName } = response.data;
      if (district && stateName && response.data.businessStatusCode === 2) {
        setPermanentCity(district);
        setPermanentState(stateName);
        setPincodeError(""); // Clear error if data is valid
      } else {
        setPermanentCity("");
        setPermanentState("");
        // setPincodeError("Please enter a valid 6-digit pincode");
      }
      console.log(
        "response.data.businessStatusCode",
        response.data.businessStatusCode
      );
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await handleSignOut();
        toast.error(
          "Your Session has expired.You will be redirected to Login Page."
        );
        navigate("/");
      } else if (error.response && error.response.status === 429) {
        toast.error(
          "Too Many Requests: You have exceeded the rate limit. Please try again later."
        );
      } else {
        toast.error(
          "There appears to be a technical issue connecting to our servers. Could you please try again later."
        );
      }
      console.error("Error fetching city and state:", error);
    }
  };

  useEffect(() => {
    const areAllFieldsFilled =
      permanentAddressLine1 &&
      permanentAddressLine2 &&
      permanentPincode &&
      permanentCity &&
      permanentState;
    const isFileUploaded = selectedFileName !== ""; // Check if a file is uploaded
    const isPincodeValid =
      permanentPincode.length === 6 && /^[1-9][0-9]{5}$/.test(permanentPincode);

    setIsContinueButtonEnabled(
      areAllFieldsFilled && isFileUploaded && !pincodeError
    );
  }, [
    permanentAddressLine1,
    permanentAddressLine2,
    permanentPincode,
    permanentCity,
    permanentState,
    selectedFileName,
    pincodeError
  ]);

  const resetStateValues = () => {
    setPermanentAddressLine1("");
    setPermanentAddressLine2("");
    setPermanentPincode("");
    setPermanentCity("");
    setPermanentState("");
    setSelectedFileName("");
    setDocumentType("");
    setDocumentData("");
    setIsPermanentAddressProofVerified(false);
    setIsCurrentAddressProofVerified(false);
    setPincodeError("");
  };

  const openPopupForPermenent = () => {
    setPopupLable("Permenent");
    setDialogOpen(true);
  };

  const openPopupForCurrent = () => {
    setPopupLable("Current");
    setDialogOpen(true);
  };

  const handleCloseDialog = (isCancel) => {
    setDialogOpen(false);
    if (!isCancel) {
        if (popupLable === "Permenent") {
            setIsPermanentAddressProofVerified(true);
        } else {
            setIsCurrentAddressProofVerified(true);
        }
    }
};


  const handleCancelDialog = () => {
    if (popupLable === "Permenent") {
      setIsPermanentAddressProofVerified(false);
    }else {
      setIsCurrentAddressProofVerified(false);
  }
    setDialogOpen(false);
    setIsContinueButtonEnabled(false); // Disable the save button
  };

  const handleClose = () => {
    props.closeModal(props.popupLable);
  };

  const handleFileChangePermanent = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("Selected permanent address file:", file);
      setIsPermanentAddressProofVerified(true);
      setSelectedFileName(file.name); // Update selectedFileName
      setDocumentType(file.addressType);
      setDocumentData(file.base64Data);
    } else {
      setIsPermanentAddressProofVerified(false);
      setSelectedFileName(""); // Clear selectedFileName
      setDocumentType("");
      setDocumentData("");
    }
  };

  const handleFileChangeCurrent = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("Selected current address file:", file);
      setIsCurrentAddressProofVerified(true);
    } else {
      setIsCurrentAddressProofVerified(false);
    }
  };

  const handleSave = () => {
    props.addPermanentAddress({
      addressLine1: permanentAddressLine1,
      addressLine2: permanentAddressLine2,
      pincode: permanentPincode,
      city: permanentCity,
      state: permanentState,
      fileName: selectedFileName,
      documentType: selectedDocumentType, // Add these
      documentData: selectedDocumentData  // Add these
    });
    props.document({
      documentName: selectedFileName,
      documentType: selectedDocumentType,
      documentProof: selectedDocumentData,
    });
    resetStateValues();
    props.onsaveClose(props.popupLable);
  };

  const handleSaveFileName = (fileName) => {
    setSelectedFileName(fileName);
  };

  const handleDocumentType = (addressType) => {
    setDocumentType(addressType);
  };
  
  const handleDocumentData = (base64Data) => {
    setDocumentData(base64Data);
  };

  const handlePincodeChange = (e) => {
    let validPincode = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    const enteredPincode = validPincode; // Store the original entered value before slicing

    validPincode = validPincode.slice(0, 6); // Ensure the pincode is not longer than 6 digits

    setPermanentPincode(validPincode);

    // Reset city and state if pincode is cleared
    if (validPincode.length !== 6) {
      setPermanentCity("");
      setPermanentState("");
      setPincodeError("Please enter a valid 6-digit pincode");
    } else {
      // Check pincode validity and set error message
      if (validPincode.length === 6 && /^[1-9][0-9]{5}$/.test(validPincode)) {
        setPincodeError("");
      } else {
        setPincodeError("Please enter a valid 6-digit pincode");
      }
    }

    // Keep the error message if the user enters more than 6 digits
    if (enteredPincode.length > 6) {
      setPincodeError("Please enter a valid 6-digit pincode");
    }
    if (enteredPincode.length === 0) {
      setPincodeError("");
    }

    // Check if Pincode has exactly 6 digits before enabling Continue button
    const isPincodeValid =
      validPincode.length === 6 && /^[1-9][0-9]{5}$/.test(validPincode);
    const areAllFieldsFilled =
      permanentAddressLine1 && validPincode && permanentCity && permanentState;
    const isFileUploaded = selectedFileName !== ""; // Check if a file is uploaded
    setIsContinueButtonEnabled(
      areAllFieldsFilled && isFileUploaded && isPincodeValid
    );
  };

  const handleDeleteFileInParent = () => {
    if (popupLable === "Permenent") {
        setIsPermanentAddressProofVerified(false);
    } else {
        setIsCurrentAddressProofVerified(false);
    }
    setIsContinueButtonEnabled(false); // Disable the save button
};

  return (
    <div>
      {props.isOpen && props.popupLable && (
        <div className="modal row">
          <div className="modal-content col-md-6">
            <h6> Add New {props.popupLable} Address</h6>
            <div className="row">
              <div className="col-md-6 mt-3">
                <ArthaTextField
                  name="permanentAddressLine1"
                  fullWidth
                  label="Address Line 1"
                  value={permanentAddressLine1}
                  onChange={(e) => {
                    const validAddress = e.target.value.replace(
                      /[^A-Za-z0-9\s\-,.]/g,
                      ""
                    );
                    setPermanentAddressLine1(validAddress);
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </div>

              <div className="col-md-6 mt-3">
                <ArthaTextField
                  name="permanentAddressLine2"
                  fullWidth
                  label="Address Line 2"
                  value={permanentAddressLine2}
                  onChange={(e) => setPermanentAddressLine2(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className="col-md-6 mt-5 mt-md-3">
                <ArthaTextField
                  name="permanentPincode"
                  label="Pincode"
                  fullWidth
                  value={permanentPincode}
                  onChange={handlePincodeChange}
                  // onChange={(e) => {
                  //   let validPincode = e.target.value.replace(/\D/g, "");
                  //   validPincode = validPincode.slice(0, 6);
                  //   console.log(validPincode);
                  //   setPermanentPincode(validPincode);
                  //   console.log(permanentPincode);

                  // }}
                  InputLabelProps={{ shrink: true }}
                  maxLength={6}
                />
                {pincodeError && (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {pincodeError}
                  </div>
                )}
              </div>
              <div className="col-md-6 mt-3 mt-md-3">
                <ArthaTextField
                  name="permanentCity"
                  label="City"
                  fullWidth
                  value={permanentCity}
                  onChange={(e) => {
                    let validCity = e.target.value.replace(/[^A-Za-z\s]/g, ""); // Allow only alphabets and spaces
                    setPermanentCity(validCity);
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className="col-md-6 mt-3 mt-md-3">
                <ArthaTextField
                  name="permanentState"
                  label="State"
                  fullWidth
                  value={permanentState}
                  onChange={(e) => {
                    let validState = e.target.value.replace(/[^A-Za-z\s]/g, ""); // Allow only alphabets and spaces
                    setPermanentState(validState);
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <label className="col-8 col-md-4 mt-3 mt-md-4 ">
                <p className="msg">Address Proof</p>
                {props.popupLable === "Permenent" ? (
                  isPermanentAddressProofVerified ? (
                    <>
                      <FaCheck className="check-icon" />
                      <span
                        className="mt-2 upload-lable"
                        style={{ position: "relative", top: "-10px" }}
                      >
                        {selectedFileName}
                      </span>
                    </>
                  ) : (
                    <>
                      <FaExclamationCircle className="badge-icon" />
                      <span
                        className="mt-2 upload-lable"
                        style={{ position: "relative", top: "-10px" }}
                      >
                        Not Uploaded
                      </span>
                    </>
                  )
                ) : isCurrentAddressProofVerified ? (
                  <>
                    <FaCheck className="check-icon" />
                    <span
                      className="mt-2 upload-lable"
                      style={{ position: "relative", top: "-10px" }}
                    >
                      {selectedFileName}
                    </span>
                  </>
                ) : (
                  <>
                    <FaExclamationCircle className="badge-icon" />
                    <span
                      className="mt-2 upload-lable"
                      style={{ position: "relative", top: "-10px" }}
                    >
                      Not Uploaded
                    </span>
                  </>
                )}
              </label>

              {/* Upload Button */}
              <div className="col-4 col-md-2 mt-4">
                <input
                  type="file"
                  ref={fileInputRefPermanent}
                  style={{ display: "none" }}
                  onChange={
                    props.popupLable === "Permenent"
                      ? handleFileChangePermanent
                      : handleFileChangeCurrent
                  }
                />
                {props.popupLable === "Permenent" ? (
                  isPermanentAddressProofVerified ? (
                    <Button
                      variant="contained"
                      className="upload_btn btn_white shadow-none mt-3"
                      onClick={openPopupForPermenent}
                    >
                      Edit
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className="upload_btn btn_white shadow-none mt-3"
                      onClick={openPopupForPermenent}
                    >
                      Upload
                    </Button>
                  )
                ) : isCurrentAddressProofVerified ? (
                  <Button
                    variant="contained"
                    className="upload_btn btn_white shadow-none mt-3"
                    onClick={openPopupForCurrent}
                  >
                    Edit
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    className="upload_btn btn_white shadow-none mt-3"
                    onClick={openPopupForCurrent}
                  >
                    Upload
                  </Button>
                )}
                {/* ManualKYCAddressVerification component */}
                <ManualKYCAddressVerification
                  open={dialogOpen}
                  popupLable={popupLable}
                  handleClose={handleCloseDialog}
                  handleCancelDialog={handleCancelDialog}
                  selectedFileName={selectedFileName}
                  handleSaveFileName={handleSaveFileName}
                  handleDocumentType={handleDocumentType}
                  handleDocumentData={handleDocumentData}
                  initialDocumentType={selectedDocumentType} // Add these props
                  initialDocumentData={selectedDocumentData}
                  handleDeleteFileInParent={handleDeleteFileInParent}
                />
              </div>
            </div>

            {/* Save and Cancel buttons */}
            <div className="row mt-4 mx-auto">
              <div className="col-6 d-flex justify-content-end mt-4">
                <ArthaClearButton variant="contained" onClick={handleClose}>
                  Cancel
                </ArthaClearButton>
              </div>
              <div className="col-6 d-flex justify-content-start mt-4">
                <ArthaContinueButton
                  variant="contained"
                  onClick={handleSave}
                  disabled={!isContinueButtonEnabled}
                  style={
                    isContinueButtonEnabled
                      ? {}
                      : {
                          opacity: 0.7,
                          cursor: "not-allowed",
                          background: "rgb(255, 115, 0)",
                          color: "white",
                        }
                  }
                >
                  Save
                </ArthaContinueButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BusinessLoanAddNewPopUp;