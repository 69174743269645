import React, { useEffect, useState, useRef } from "react";
import "./personalLoan.css";
import ArthaContinueButton from "../../fields/ArthaContinueButton.js";
import ArthaClearButton from "../../fields/ArthaClearButton.js";
import dayjs from "dayjs";
import ArthaTextField from "../../fields/ArthaTextField1.js";
import EligibilityCheckForm from "./EligibilityCheckForm.jsx";
import { useNavigate } from "react-router-dom";
import { handleSignOut } from "./SessionExpiredApi.jsx";
import LoadingSpinner from "../../fields/spinner.jsx";
import ArthaFormControl from "../../fields/ArthaFormControl.js";
import ArthaInputLabel from "../../fields/ArthaInputLabel.js";
import {
    propertyLoanFetchDataAPI,
    propertyLoanStoreDetailsAPI,
    propertyLoanUserDetailsAPI,
    propertyLoanCreateLoanApplicationAPI,
    propertyLoanStorePropertyDetailsAPI,
    propertyLoanfetchDistStateAPI,
    propertyLoanFetchPropertyDETAILS,
} from "../../actioncreators/actioncreators.js";
import { toast } from "react-toastify";
import { Select, MenuItem, Button, Dialog } from "@mui/material";
import PinCodeValidation from "./pinCodeValidation.jsx";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import UploadDeceasedDoc from "./UploadDeceasedDoc.jsx";
import { use } from "react";
import UploadPropertyDoc from "./UploadPropertyDoc.jsx";
import { useSelector } from "react-redux";
const UpdatePropertyDetails = ({ onSubmit, onCancel, changeStatus, changeApplicantStatus, }) => {
    const isAddPropertyClicked = useSelector((state) => state.Addpropertyclicked);
    console.log("isAddPropertyClicked", isAddPropertyClicked);

    const hasFetched = useRef(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const [ap_id, setApid] = useState("");
    const [loan_app_id, setLoanAppId] = useState("");
    const [propertyTypeDropdown, setPropertyTypeDropdown] = React.useState([
        "Flat/Apartment",
        "Bungalow",
        "Independent House",
        "Office Space",
        "Shop",
        "Chawl",
        "Commercial Complex",
        "Industrial Property (e.g., factory, warehouse)",
        "Residential Plot/NA Plot",
        "Row House",
        "Studio Apartment",
        "Penthouse",
        "Mixed-Use Property (Residential + Commercial)"
    ]);
    const [propertyJurisdictionDropdown, setPropertyJurisdictionDropdown] = React.useState([]);
    const [propertyAgeDropdown, setPropertyAgeDropdown] = React.useState([]);
    const [propertyOwnerDropdown, setPropertyOwnerDropdown] = React.useState([]);
    const [propertyOwnerNumberDropdown, setPropertyOwnerNumberDropdown] = React.useState([]);
    const [showEligibilityCheckForm, setShowEligibilityCheckForm] =
        useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [errorDisplayed, setErrorDisplayed] = useState(false);
    const errorDisplayedRef = useRef(false);
    const [pinCodeError, setPinCodeError] = useState("");
    const [pinCodeError1, setPinCodeError1] = useState("");
    const [pinCodeErrors, setPinCodeErrors] = useState([]);
    const [isAddressAdded, setAddressAdded] = React.useState(false);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [selectedDocs, setSelectedDocs] = useState({ document: [] });


    const [formData, setFormData] = useState({
        propertyType: "",
        propertyId: "",
        propertyJurisdiction: "",
        propertyOwnership: "",
        builtupType: "",
        propertyStatus: "",
        stageOfConstruction: "",
        expectedDateOfPossession: "",
        percentageOfWorkCompleted: "",
        ownershipPeriod: "",
        plotArea: "",
        builtUpArea: "",
        carpetArea: "",
        propertyPin: "",
        numberOfOwners: "",
        propertyAge: "",
        propertyValuation: "",
        currentAddressPincode: "",
        fullName: "",
    });
    const [currentAddress, setCurrentAddress] = useState({
        line1: "",
        line2: "",
        pincode: "",
        city: "",
        state: "",

    });
    useEffect(() => {
        const fetchPropertyDetails = async () => {
            try {
                const response = await propertyLoanFetchPropertyDETAILS();
                console.log("FetchPropertyDETAILS", response);

                if (response && response.data) {
                    const details = response.data.PropertyDetails[0];

                    console.log("FetchPropertyDETAILS", details);
                    setFormData((prevFormData) => {
                        console.log("Form Data updated:", {
                            ...prevFormData,
                            propertyType: details.propertyType || "",
                            propertyId: details.propertyId || "",
                            propertyStatus: details.propertyOwnershipStatus || "",
                            propertyJurisdiction: details.propertyJurisdiction || "",
                            propertyOwnership: details.propertyOwnership || "",
                            builtupType: details.builtupType || "",
                            stageOfConstruction: details.stageOfConstruction || "",
                            // expectedDateOfPossession: (details.expectedDateOfPossession) || "",
                            expectedDateOfPossession: details.expectedDateOfPossession
                                ? new Date(details.expectedDateOfPossession).toISOString().split("T")[0] // Format to YYYY-MM-DD
                                : "", // Format to YYYY-MM-DD:  || "",
                            percentageOfWorkCompleted: details.percentageOfWorkComplete || "",
                            ownershipPeriod: details.ownershipPeriod || "",
                            plotArea: details.plotArea || "",
                            builtUpArea: details.buildUpArea || "",
                            carpetArea: details.carpetArea || "",
                            propertyPin: details.propertyPin || "",
                            numberOfOwners: details.numberOfOwners || "",
                            propertyAge: details.propertyAge || "",
                            propertyValuation: details.propertyValuation || "",
                            currentAddressPincode: details.currentAddressPincode || "",
                            fullName: details.fullName || "",

                        });
                        return {
                            ...prevFormData,
                            propertyType: details.propertyType || "",
                            propertyId: details.propertyId || "",
                            propertyStatus: details.propertyOwnershipStatus || "",
                            propertyJurisdiction: details.propertyJurisdiction || "",
                            propertyOwnership: details.propertyOwnership || "",
                            builtupType: details.builtupType || "",
                            stageOfConstruction: details.stageOfConstruction || "",
                            // expectedDateOfPossession: details.expectedDateOfPossession || "",
                            expectedDateOfPossession: details.expectedDateOfPossession
                                ? new Date(details.expectedDateOfPossession).toISOString().split("T")[0] // Format to YYYY-MM-DD
                                : "",
                            percentageOfWorkCompleted: details.percentageOfWorkComplete || "",
                            ownershipPeriod: details.ownershipPeriod || "",
                            plotArea: details.plotArea || "",
                            builtUpArea: details.buildUpArea || "",
                            carpetArea: details.carpetArea || "",
                            propertyPin: details.propertyPin || "",
                            numberOfOwners: details.numberOfOwners || "",
                            propertyAge: details.propertyAge || "",
                            propertyValuation: details.propertyValuation || "",
                            currentAddressPincode: details.currentAddressPincode || "",
                            fullName: details.fullName || "",

                        };
                    });
                    setCurrentAddress({
                        line1: details.propertyAddress.address.line1 || "",
                        line2: details.propertyAddress.address.line2 || "",
                        pincode: details.propertyPin || "",
                        city: details.propertyAddress.address.city || "",
                        state: details.propertyAddress.address.state || ""
                    });
                    // setAddressAdded(true); // Set address added to true
                    const propertyAddress = details.propertyAddress?.address || {};
                    if (
                        propertyAddress.line1 &&
                        propertyAddress.city &&
                        propertyAddress.state &&
                        details.propertyPin
                    ) {
                        setAddressAdded(true);
                    } else {
                        setAddressAdded(false);
                    }
                    if (details.documents) {
                        setSelectedDocs({
                            document: details.documents.map((doc) => ({
                                documentType: doc.documentType,
                                documentName: doc.documentName,
                            })),
                        });
                    } else {
                        setSelectedDocs({ document: [] });
                    }
                    // setSelectedDocs(details.documents || []);
                } else {
                    throw new Error("No data received");
                }
            } catch (err) {
                console.error("Error fetching property details:", err);
            }
        };

        fetchPropertyDetails();
    }, []);

    const goToSummaryPage = () => {
        changeApplicantStatus("PROPERTY_SUMMARY_DETAILS");
    };

    const handleApiError = (error) => {
        if (errorDisplayedRef.current) return;

        setErrorDisplayed(true); // Set the state to true
        errorDisplayedRef.current = true; // Set the ref to true

        if (error.response && error.response.status === 401) {
            handleSignOut();
            toast.error(
                "Your session has expired. You will be redirected to the Login Page."
            );
            navigate("/");
        } else if (error.response && error.response.status === 429) {
            toast.error(
                "Too Many Requests: You have exceeded the rate limit. Please try again later."
            );
        } else {
            toast.error(
                "There appears to be a technical issue connecting to our servers. Could you please try again later."
            );
        }
        // Reset the error flag after a certain time
        setTimeout(() => {
            setErrorDisplayed(false);
            errorDisplayedRef.current = false;
        }, 2000); // 2 seconds
    };

    const fetchAddressDetails = async (pincode) => {
        try {
            let data = {
                pinCode: pincode,
            };
            const response = await propertyLoanfetchDistStateAPI(data);
            const { district, stateName } = response.data;
            setCurrentAddress((prevState) => ({
                ...prevState,
                city: district || "",
                state: stateName || "",
            }));
        } catch (error) {
            console.error("Error fetching address details:", error);
            setCurrentAddress((prevState) => ({
                ...prevState,
                city: "",
                state: "",
            }));
        }
    };

    useEffect(() => {
        const fetchDropDownData = async () => {
            const apiPayloads = [
                { source: "json_value", type: "LAP_LOAN", key: "PROPERTY_TYPE", dropdownType: "PROPERTY_TYPE" },
                { source: "json_value", type: "LAP_LOAN", key: "PROPERTY_OWNEDBY", dropdownType: "PROPERTY_OWNEDBY" },
                { source: "json_value", type: "LAP_LOAN", key: "NUMBER_OF_OWNERS", dropdownType: "NUMBER_OF_OWNERS" },
                { source: "json_value", type: "LAP_LOAN", key: "PROPERTY_AGE", dropdownType: "PROPERTY_AGE" },
                { source: "json_value", type: "LOAN_AGAINST_PROPERTY", key: "PROPERTY_JURISDICTION", dropdownType: "PROPERTY_JURISDICTION" },
            ];

            // Fetch each dropdown value
            for (const payload of apiPayloads) {
                await fetchDropDownValues(payload, payload.dropdownType);
            }
        };
        fetchDropDownData();
    }, []);

    // useEffect(() => {
    //     if (!hasFetched.current) {
    //         hasFetched.current = true; // Set flag to true to prevent subsequent calls

    //         const fetchData1 = async () => {
    //             try {
    //                 // First API call: Create Loan Application
    //                 const response = await propertyLoanCreateLoanApplicationAPI();
    //                 if (response?.businessStatusCode === 2) {
    //                     setApid(response?.ap_id || "");
    //                     setLoanAppId(response?.loan_app_id || "");
    //                 }
    //                 const lapLoanApplicationStatus = response?.status || "";

    //                 // Second API call: Fetch user details
    //                 const fetchData = async () => {
    //                     try {
    //                         const response1 = await propertyLoanUserDetailsAPI();
    //                         console.log("response1", response1);

    //                         setFormData(() => ({
    //                             fullName: response1?.fullName || "", // Fallback to empty string if undefined
    //                             currentAddressPincode: response1?.pinCode || "", // Fallback to empty string if undefined
    //                         }));

    //                         // Check if the property array is valid and set form data accordingly
    //                         if (Array.isArray(response1?.property) && response1?.property.length > 0) {
    //                             setPropertyForms(response1.property);
    //                         } else {
    //                             // Default form in case the property array is empty or undefined
    //                             setPropertyForms([{
    //                                 propertyID: "",
    //                                 fullName: "",
    //                                 currentAddressPincode: "",
    //                                 propertyType: "",
    //                                 propertyJurisdiction: "",
    //                                 propertyOwnership: "",
    //                                 builtupType: "",
    //                                 stageOfConstruction: "",
    //                                 expectedDateOfPossession: "",
    //                                 percentageOfWorkCompleted: "",
    //                                 ownershipPeriod: "",
    //                                 plotArea: "",
    //                                 builtUpArea: "",
    //                                 carpetArea: "",
    //                                 propertyPin: "",
    //                                 numberOfOwners: "",
    //                                 propertyAge: "",
    //                                 propertyValuation: "",
    //                             }]);
    //                         }
    //                     } catch (error) {
    //                         handleApiError(error);
    //                         console.error("Error fetching user data:", error);
    //                     }
    //                 };

    //                 // Fetch user details after loan application status
    //                 await fetchData();

    //                 // Fetch dropdown values after fetching user details
    //                 const fetchDropDownData = async () => {
    //                     const apiPayloads = [
    //                         { source: "json_value", type: "LAP_LOAN", key: "PROPERTY_TYPE", dropdownType: "PROPERTY_TYPE" },
    //                         { source: "json_value", type: "LAP_LOAN", key: "PROPERTY_OWNEDBY", dropdownType: "PROPERTY_OWNEDBY" },
    //                         { source: "json_value", type: "LAP_LOAN", key: "NUMBER_OF_OWNERS", dropdownType: "NUMBER_OF_OWNERS" },
    //                         { source: "json_value", type: "LAP_LOAN", key: "PROPERTY_AGE", dropdownType: "PROPERTY_AGE" },
    //                         { source: "json_value", type: "LOAN_AGAINST_PROPERTY", key: "PROPERTY_JURISDICTION", dropdownType: "PROPERTY_JURISDICTION" },
    //                     ];

    //                     // Fetch each dropdown value
    //                     for (const payload of apiPayloads) {
    //                         await fetchDropDownValues(payload, payload.dropdownType);
    //                     }
    //                 };

    //                 await fetchDropDownData();

    //                 // Navigate after fetching data
    //                 navigate("/home/loanagainstproperty", {
    //                     state: {
    //                         lapLoanApplicationStatus,
    //                     },
    //                 });
    //             } catch (error) {
    //                 handleApiError(error);
    //                 console.error("Error creating loan application:", error);
    //             }
    //         };

    //         fetchData1();
    //     }
    // }, [navigate, ap_id, loan_app_id]); // Added 'navigate' as a dependency
    const fetchDropDownValues = async (apiPayload, dropdownType) => {
        try {
            const response = await propertyLoanFetchDataAPI(apiPayload);
            const data = response?.data?.[0] || {}; // Safely accessing the response

            // Handling different dropdown values based on type
            if (dropdownType === "PROPERTY_JURISDICTION") {
                setPropertyJurisdictionDropdown(data.List || []); // Fallback to empty array if undefined
                // } else if (dropdownType === "PROPERTY_TYPE") {
                //     setPropertyTypeDropdown(data.PropertyType || []); // Fallback to empty array if undefined
            } else if (dropdownType === "PROPERTY_OWNEDBY") {
                setPropertyOwnerDropdown(data.PropertyOwnedBy || []); // Fallback to empty array if undefined
            } else if (dropdownType === "NUMBER_OF_OWNERS") {
                setPropertyOwnerNumberDropdown(data.NumberOfOwners || []); // Fallback to empty array if undefined
            } else if (dropdownType === "PROPERTY_AGE") {
                setPropertyAgeDropdown(data.PropertyAge || []); // Fallback to empty array if undefined
            }
        } catch (error) {
            handleApiError(error);
            console.error("Error fetching dropdown data:", error);
        }
    };

    useEffect(() => {
        validateForm();
    }, []);

    // Fetching dropdown Values




    // const validateForm = () => {
    //   const {


    //     propertyType,
    //     propertyJurisdiction,
    //     numberOfOwners,
    //     propertyAge,
    //     propertyOwnership,
    //     propertyPin,
    //     propertyValuation,
    //   } = formData;

    //   const isValid =


    //     propertyType !== "" &&
    //     propertyJurisdiction !== "" &&
    //     numberOfOwners !== "" &&
    //     propertyAge !== "" &&
    //     propertyOwnership !== "" &&
    //     propertyPin !== "" &&
    //     propertyValuation !== "";

    //   setIsFormValid(isValid);
    // };


    const [openAddressDialog, setOpenAddressDialog] = useState(false);
    const openAddrDialog = () => {
        setOpenAddressDialog(true);
        setPincodeError('')
    }
    const closeAddrDialog = () => {
        if (isAddressAdded) {
            setOpenAddressDialog(false);
        } else {
            setOpenAddressDialog(false);
            setCurrentAddress({
                'line1': '',
                'line2': '',
                'pincode': '',
                'city': '',
                'state': ''
            })
        }
    }

    const [pincodeError, setPincodeError] = React.useState('');
    const handleAddressChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name == 'pincode' && value == '000000') {
            setPincodeError('Please enter a valid 6-digit pincode')
        }
        setCurrentAddress(prevAddress => ({
            ...prevAddress,
            [name]: value
        }));
        checkFormFilled();
    }

    const isPincodeValid = (pincode) => /^\d{6}$/.test(pincode);
    const isCityValid = (city) => /^[a-zA-Z\s]+$/.test(city);
    const isStateValid = (state) => /^[a-zA-Z\s]+$/.test(state);
    const [isFormFilled, setIsFormFilled] = useState(false);
    const checkFormFilled = () => {
        if (!currentAddress) return;

        const { line1, line2, pincode, city, state } = currentAddress;

        const isLine1Filled = line1 && line1.trim() !== '' && /^[a-zA-Z0-9,\s]+$/.test(line1);
        const isLine2Filled = line2 && line2.trim() !== '' && /^[a-zA-Z0-9,\s]+$/.test(line2);
        const isPincodeFilled = pincode && pincode.trim() !== '' && /^\d{6}$/.test(pincode); // Assuming pincode is 6 digits
        const isCityFilled = city && city.trim() !== '' && /^[a-zA-Z\s]+$/.test(city);
        const isStateFilled = state && state.trim() !== '' && /^[a-zA-Z\s]+$/.test(state);

        let isPincodeValidated = isPincodeFilled && isPincodeValid(pincode);
        if (pincode == '000000') {
            isPincodeValidated = false;
        }
        if (isPincodeValidated) {
            setPincodeError('');
        }
        const isCityValidated = isCityFilled && isCityValid(city);
        const isStateValidated = isStateFilled && isStateValid(state);

        if (
            isLine1Filled &&
            isLine2Filled &&
            isPincodeFilled &&
            isCityFilled &&
            isStateFilled &&
            isPincodeValidated &&
            isCityValidated &&
            isStateValidated
        ) {
            setIsFormFilled(true);
        } else {
            setIsFormFilled(false);
        }

    };

    const handleFileChangeID = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setFormData({
                    ...formData,
                    registrationCertificateDoc: {
                        ...formData.registrationCertificateDoc,
                        documentData: e.target.result.split(",")[1], // Assuming base64 data
                        documentName: file.name,
                        documentType: file.type,
                    },
                });
            };
            reader.readAsDataURL(file);
        }
    };

    const removeExistingDoc = (documentId) => {
        const [documentType] = documentId.split('-');
        setSelectedDocs(prev => ({
            ...prev,
            document: prev.document.filter(doc => doc.documentType !== documentType)
        }));
    };

    const handleUploadDocument = () => {
        setPopupOpen(true);
    };

    const handlePopupClose = (selectedFileNames) => {
        // If selectedFileNames is empty array, it means all documents were removed
        if (selectedFileNames.length === 0) {
            setSelectedDocs(prev => ({
                ...prev,
                // document: []
            }));
        }
        else {
            // Update with new document list
            setSelectedDocs(prev => ({
                ...prev,
                document: selectedFileNames
            }));
        }
        setPopupOpen(false);
    };

    useEffect(() => {
        checkFormFilled();
    }, [formData, currentAddress]);

    const handleClear = () => {
        changeStatus("PROPERTY_SUMMARY_DETAILS");
        changeApplicantStatus("PropertySummary");
    };

    useEffect(() => {
        const pinCodeInput = document.getElementById("pin-code-inpt");
        if (pinCodeInput) {
            pinCodeInput.focus();
        }
    }, []);

    const validateForm = () => {
        const {
            propertyType,
            propertyJurisdiction,
            numberOfOwners,
            propertyAge,
            propertyOwnership,
            propertyPin,
            propertyValuation,
            carpetArea,
            builtUpArea,
            stageOfConstruction,
            expectedDateOfPossession,
            percentageOfWorkCompleted,
            ownershipPeriod,
        } = formData;

        // Base required fields
        let requiredFields = [
            propertyType,
            propertyJurisdiction,
            numberOfOwners,
            propertyAge,
            propertyOwnership,
            propertyPin,
            propertyValuation,
        ];

        // Add conditional fields based on conditions
        if (propertyType !== "Residential Plot/NA Plot") {
            requiredFields.push(carpetArea, builtUpArea);
        }

        if (stageOfConstruction === "Under Construction") {
            requiredFields.push(expectedDateOfPossession, percentageOfWorkCompleted);
        }

        if (stageOfConstruction === "Complete") {
            requiredFields.push(ownershipPeriod);
        }

        // Validate that all required fields are non-empty, ensure only strings are trimmed
        const isFormFieldsValid = requiredFields.every((field) => {
            if (typeof field === 'string') {
                return field.trim() !== "";
            }
            return field !== "" && field !== null && field !== undefined;
        });

        // Validate address and documents
        const isAddressValid = isAddressAdded === true;
        const isDocumentsValid = selectedDocs.document && selectedDocs.document.length > 0;

        // Combine all validations
        const isValid = isFormFieldsValid && isAddressValid && isDocumentsValid;

        // Update the form validity state
        setIsFormValid(isValid);

        // Debugging logs
        console.log("Fields Valid:", isFormFieldsValid);
        console.log("Address Valid:", isAddressValid);
        console.log("Documents Valid:", isDocumentsValid);
        console.log("Form Valid:", isValid);
    };




    // UseEffect to validate whenever formData changes
    useEffect(() => {
        validateForm();
    }, [formData, selectedDocs, isAddressAdded]); // Track changes in formData, selectedDocs, and address state



    const closeModal = () => {
        setIsOpen(false);
        let inpt = document.getElementById("pin-code-inpt");
        inpt.focus();
    };

    const [propertyForms, setPropertyForms] = useState([
        {
            propertyID: "",
            fullName: "",
            currentAddressPincode: "",
            propertyType: "",
            propertyJurisdiction: "",
            propertyOwnership: "",
            builtupType: "",
            stageOfConstruction: "",
            expectedDateOfPossession: "",
            percentageOfWorkCompleted: "",
            ownershipPeriod: "",
            plotArea: "",
            builtUpArea: "",
            carpetArea: "",
            propertyPin: "",
            numberOfOwners: "",
            propertyAge: "",
            propertyValuation: "",
        },
    ]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        let updatedValue = value;

        // Apply validation specifically for propertyValuation to allow only numbers
        if (["propertyValuation", "plotArea", "builtUpArea", "propertyPin"].includes(name)) {
            updatedValue = updatedValue.replace(/[^0-9]/g, ""); // Remove non-numeric characters
            if (name === "propertyPin") updatedValue = updatedValue.slice(0, 6); // Limit to 6 digits
        }

        // setFormData((prevData) => ({
        //     ...prevData,
        //     [name]: updatedValue,
        // }));
        setFormData((prevData) => {
            let updatedFormData = { ...prevData, [name]: updatedValue };

            // Reset and handle "numberOfOwners" logic when "propertyOwnership" changes
            if (name === "propertyOwnership") {
                if (value === "Solo") {
                    updatedFormData.numberOfOwners = "1"; // Set numberOfOwners to 1
                } else {
                    updatedFormData.numberOfOwners = ""; // Reset numberOfOwners
                }
            }

            return updatedFormData;
        });
    };

    const handleDateChange = (date, name) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: date,
        }));
    };

    const handleChange1 = (e) => {
        const { name, value } = e.target;
        let updatedValue = value;
        if (name === "currentAddressPincode") {
            updatedValue = updatedValue.replace(/\D/g, "").slice(0, 6);
            const isPinCodeValid1 = /^\d{6}$/.test(updatedValue);
            if (!isPinCodeValid1) {
                setPinCodeError1("Pincode must be 6 digits");
            } else {
                setPinCodeError1("");
            }
        }

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    // useEffect(() => {
    //     validateForm();
    // }, [formData, propertyForms]);

    // const validateForm = () => {
    //     const { fullName, currentAddressPincode } = formData;

    //     // Basic validation for the primary form
    //     const isMainFormValid = fullName !== "" && currentAddressPincode !== "";

    //     // Validate each property form
    //     const areAllPropertiesValid = propertyForms?.every((form) => {
    //         const {
    //             propertyType,
    //             propertyJurisdiction,
    //             numberOfOwners,
    //             propertyAge,
    //             propertyOwnership,
    //             builtupType,
    //             plotArea,
    //             propertyPin,
    //             propertyValuation,
    //         } = form;

    //         // Check if propertyPin contains exactly 6 digits
    //         const isPinValid = /^\d{6}$/.test(propertyPin) && propertyPin !== "000000" && !propertyPin.startsWith("0");

    //         return (
    //             propertyType !== "" &&
    //             propertyJurisdiction !== "" &&
    //             // numberOfOwners !== "" &&
    //             propertyAge !== "" &&
    //             propertyOwnership !== "" &&
    //             builtupType !== "" &&

    //             propertyPin !== "" &&
    //             isPinValid && // Ensure pincode is valid
    //             propertyValuation !== ""
    //         );
    //     });

    //     // Set the form validity based on both the main form and all properties
    //     setIsFormValid(isMainFormValid && areAllPropertiesValid);
    // };

    const handleRemoveProperty = (index) => {
        const updatedForms = propertyForms.filter((_, i) => i !== index);
        setPropertyForms(updatedForms);
    };

    const handleContinue = async (values, setSubmitting) => {

        // changeStatus("PROPERTY_DETAILS")
        // changeApplicantStatus("PropertyOwnership");

        setIsButtonClicked(true);
        setIsLoading(true);

        const data = {

            property: [
                {
                    propertyID: formData.propertyId,
                    propertyJurisdiction: formData.propertyJurisdiction,
                    propertyType: formData.propertyType,
                    propertyOwnership: formData.propertyOwnership,
                    numberOfOwners: formData.numberOfOwners,
                    propertyValuation: formData.propertyValuation,
                    propertyAge: formData.propertyAge,
                    propertyPin: formData.propertyPin,
                    propertyOwnershipStatus: formData.propertyStatus,
                    builtupType: formData.builtupType,
                    buildUpArea: formData.builtUpArea,
                    plotArea: formData.plotArea,
                    carpetArea: formData.carpetArea,
                    stageOfConstruction: formData.stageOfConstruction,
                    expectedDateOfPossession: formData.expectedDateOfPossession,
                    expectedDateOfPossession: formData.expectedDateOfPossession
                        ? dayjs(formData.expectedDateOfPossession).format("YYYY-MM-DD")
                        : "",

                    percentageOfWorkComplete: formData.percentageOfWorkCompleted || "NA",
                    ownershipPeriod: formData.ownershipPeriod || "",

                    // propertyAddress: currentAddress,
                    propertyAddress: {
                        address: {
                            "addressType": "BOTH", // You can modify this to be dynamic if needed
                            line1: currentAddress.line1,  // Assuming currentAddress is an object with these properties
                            line2: currentAddress.line2,
                            line3: currentAddress.line3,
                            city: currentAddress.city,
                            dist: currentAddress.dist,
                            state: currentAddress.state,
                            country: currentAddress.country,
                            pin: currentAddress.pin
                        }
                    },
                    documents: selectedDocs.document,

                }
            ]
        };

        console.log("Property Details Data:", data);
        try {


            const response = await propertyLoanStorePropertyDetailsAPI(data);

            if (response.businessStatusCode === 2) {
                changeStatus("PROPERTY_SUMMARY_DETAILS");
                changeApplicantStatus(response.nextPage);
            }
            // else if (
            //         response.businessStatusCode === 1 &&
            //         response.businessStatusSubCode === 1.1
            //     ) {
            //         toast.info(response.message);
            //     } else if (
            //         response.businessStatusCode === 1 &&
            //         response.businessStatusSubCode === 1.3
            //     ) {
            //         toast.warning(response.message);
            //         navigate("/home");
            //     } else {
            //         setShowEligibilityCheckForm(false);
            //         setIsOpen(true);
            //     }
        } catch (error) {
            handleApiError(error);
            // changeStatus("PROPERTY_DETAILS")
            // changeApplicantStatus("PropertyOwnership");
            console.error("Error fetching loan data:", error);
        } finally {
            setIsLoading(false);
            setIsButtonClicked(false);
        }
    };
    return (
        <>
            {!showEligibilityCheckForm ? (
                <>
                    {/* <h6 className="text mt-4">Initial Applicant DetailsHere</h6>

          <form
            className="mt-4 border border-dark p-3"
            style={{ borderRadius: "10px" }}
          >
            <div className="row mt-2 ">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <ArthaTextField
                  id="outlined-basic"
                  name="fullName"
                  value={formData.fullName || ""}
                  onChange={(e) => handleChange1(e)}
                  label="Full Name"
                  fullWidth
                  variant="outlined"
                  disabled
                  className="form-control fs-5 custom-disabled-input"
                />
                <span className="msg">As per PAN</span>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <ArthaTextField
                  name="currentAddressPincode"
                  type="text"
                  maxLength="6"
                  value={formData.currentAddressPincode || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value) && value.length <= 6) {
                      handleChange1(e);
                    }
                  }}
                  label="Pincode of your current address"
                  id="pin-code-inpt"
                  fullWidth
                  inputMode="numeric"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={Boolean(pinCodeError1)}
                  helperText={pinCodeError1}
                  autoFocus
                />
              </div>
            </div>
          </form> */}
                    <div className="d-flex">
                        <h6 className="text ">Property Details</h6>
                        <div
                            onClick={goToSummaryPage}
                            className="text fw-bold"
                            style={{
                                color: "orange",
                                marginLeft: "auto",
                                textDecoration: "none",
                                cursor: "pointer",
                            }}
                        >
                            Go To Property Summary
                        </div>
                    </div>
                    <div className="row mt-4" style={{ "border": "1px solid #a39898", padding: "10px", borderRadius: "10px" }}>
                        <div className="row mt-4">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                                <ArthaFormControl fullWidth>
                                    <ArthaInputLabel
                                        id="propertyJurisdiction-label"
                                        shrink={!!formData.propertyJurisdiction} // Shrinks label when value exists
                                    >
                                        Property Jurisdiction
                                    </ArthaInputLabel>
                                    <Select
                                        name="propertyJurisdiction"
                                        value={formData.propertyJurisdiction || "Not Available"} // Set default if value is unavailable
                                        onChange={(e) =>
                                            setFormData((prev) => ({
                                                ...prev,
                                                propertyJurisdiction: e.target.value,
                                            }))
                                        }
                                        disabled={isAddPropertyClicked !== true}// Disable if not in editable mode
                                        fullWidth
                                    >
                                        {propertyJurisdictionDropdown?.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                        {!propertyJurisdictionDropdown?.length && (
                                            <MenuItem value="Not Available">Not Available</MenuItem>
                                        )}
                                    </Select>
                                </ArthaFormControl>
                            </div>

                            {/* <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-md-0">
        <ArthaFormControl fullWidth>
            <ArthaInputLabel id="propertyStatus-label"  shrink={!!formData.propertyStatus}>
                Property Ownership Status
            </ArthaInputLabel>
            <ArthaTextField
                labelId="propertyStatus-label"
             
                name="propertyStatus"
                value={formData.propertyStatus || ""} // Default value is set as empty string
                onChange={handleChange}
                InputProps={{
                    readOnly: !isAddPropertyClicked,  // Read-only property
                }}
                fullWidth
            />
        </ArthaFormControl>
    </div> */}
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-md-0">
                                <ArthaFormControl fullWidth>
                                    <ArthaInputLabel id="propertyStatus-label" shrink={!!formData.propertyStatus}>
                                        Property Ownership Status
                                    </ArthaInputLabel>
                                    <Select
                                        name="propertyStatus"
                                        value={formData.propertyStatus || ""} // Default value is set as empty string
                                        onChange={(e) => {
                                            setFormData((prevFormData) => ({
                                                ...prevFormData,
                                                propertyStatus: e.target.value,
                                            }));
                                        }}
                                        // disabled={!isAddPropertyClicked} // Disable if not in edit mode
                                        disabled={isAddPropertyClicked !== true}
                                        fullWidth
                                    >
                                        {["Freehold", "Leasehold"].map((status) => (
                                            <MenuItem key={status} value={status}>
                                                {status}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </ArthaFormControl>
                            </div>

                        </div>


                        <div className="row mt-3 mt-md-4">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                                <ArthaFormControl fullWidth>
                                    <ArthaInputLabel id="propertyOwnership-label " shrink={!!formData.propertyOwnership}>
                                        Property Ownership Type
                                    </ArthaInputLabel>
                                    <Select
                                        labelId="propertyOwnership-label"
                                        id="propertyOwnership"
                                        name="propertyOwnership"
                                        value={formData.propertyOwnership || ""}
                                        onChange={handleChange}
                                        disabled={isAddPropertyClicked !== true}
                                    >
                                        {propertyOwnerDropdown?.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </ArthaFormControl>
                            </div>

                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                                <ArthaFormControl fullWidth>
                                    <ArthaInputLabel id="numberOfOwners-label">
                                        Number of Owners in the Property
                                    </ArthaInputLabel>
                                    <Select
                                        labelId="numberOfOwners-label"
                                        id="numberOfOwners"
                                        name="numberOfOwners"
                                        value={formData.numberOfOwners || ""}
                                        onChange={handleChange}
                                        disabled={isAddPropertyClicked !== true || formData.propertyOwnership === "Solo"}
                                    >
                                        {propertyOwnerNumberDropdown?.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </ArthaFormControl>

                            </div>
                        </div>

                        <div className="row mt-3 mt-md-4">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                                <ArthaFormControl fullWidth>
                                    <ArthaInputLabel id="propertyAge-label" shrink={!!formData.propertyAge}>
                                        Property Age
                                    </ArthaInputLabel>
                                    <ArthaTextField
                                        labelId="propertyAge-label"
                                        id="propertyAge"
                                        name="propertyAge"
                                        value={formData.propertyAge || ""}
                                        onChange={handleChange}
                                        disabled={isAddPropertyClicked !== true}
                                    />

                                </ArthaFormControl>
                            </div>


                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-md-0">
                                <ArthaTextField
                                    name="propertyPin"
                                    type="text"
                                    maxLength="6"
                                    value={formData.propertyPin || ""}
                                    onChange={handleChange}
                                    // disabled
                                    disabled={isAddPropertyClicked !== true}
                                    label="Property Pincode"
                                    id="pin-code-inpt"
                                    fullWidth
                                    inputMode="numeric"
                                />
                            </div>

                        </div>

                        <div className="row mt-3 mt-md-4">

                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                                <ArthaFormControl fullWidth>
                                    <ArthaInputLabel id="propertyType-label">
                                        Property Type
                                    </ArthaInputLabel>
                                    <Select

                                        labelId="propertyType-label"
                                        id="propertyType"
                                        name="propertyType"
                                        value={formData.propertyType || ""}
                                        onChange={handleChange}
                                    >
                                        {propertyTypeDropdown?.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </ArthaFormControl>
                            </div>
                            {/* <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-md-0">
                                <ArthaFormControl fullWidth>
                                    <ArthaInputLabel id="builtupType-label">
                                        Builtup Type
                                    </ArthaInputLabel>
                                    <Select
                                        labelId="builtupType-label"
                                        id="builtupType"
                                        name="builtupType"
                                        value={formData.builtupType || ""}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="Residential - Flat">Residential - Flat</MenuItem>
                                        <MenuItem value="Residential - Bungalow">Residential - Bungalow</MenuItem>
                                        <MenuItem value="Commercial - shop">Commercial - shop</MenuItem>
                                        <MenuItem value="Office">Office</MenuItem>
                                        <MenuItem value="Warehouse">Warehouse</MenuItem>
                                        <MenuItem value="Non-Agriculture Plot">Non-Agriculture Plot</MenuItem>
                                    </Select>
                                </ArthaFormControl>
                            </div> */}
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-md-0">
                                <ArthaFormControl fullWidth>
                                    <ArthaInputLabel id="stageofConstruction-label">
                                        Stage of Construction
                                    </ArthaInputLabel>
                                    <Select
                                        labelId="stageofConstruction-label"
                                        id="stageOfConstruction"
                                        name="stageOfConstruction"
                                        value={formData.stageOfConstruction || ""}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="Complete">Complete</MenuItem>
                                        <MenuItem value="Under Construction">Under Construction</MenuItem>
                                    </Select>
                                </ArthaFormControl>
                            </div>
                        </div>




                        <div className="row mt-3 mt-md-3">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                                <ArthaTextField
                                    name="carpetArea"
                                    type="text"
                                    value={formData.carpetArea || ""}
                                    onChange={handleChange}
                                    label="Carpet Area (sq/ft)"
                                    id="carpetArea"
                                    fullWidth
                                    inputMode="numeric"
                                    style={{ display: formData.propertyType !== "Residential Plot/NA Plot" ? 'block' : 'none' }}
                                />
                            </div>

                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                                <ArthaTextField
                                    name="builtUpArea"
                                    type="text"
                                    value={formData.builtUpArea || ""}
                                    onChange={handleChange}
                                    label="Built Up Area (sq/ft)"
                                    id="builtUpArea"
                                    fullWidth
                                    inputMode="numeric"
                                    style={{ display: formData.propertyType !== "Residential Plot/NA Plot" ? 'block' : 'none' }}
                                />
                            </div>



                        </div>

                        <div className="row mt-3 mt-md-3">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <ArthaTextField
                                    type="text"
                                    name="propertyValuation"
                                    value={formData.propertyValuation || ""}
                                    onChange={handleChange}
                                    label="Approximate Property Valuation (₹)"
                                    fullWidth
                                />
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                                <ArthaTextField
                                    name="plotArea"
                                    type="text"
                                    value={formData.plotArea || ""}
                                    onChange={handleChange}
                                    inputMode="numeric"
                                    label="Plot Area (sq/ft)"
                                    id="plotArea"
                                    fullWidth

                                    style={{ display: ["Bungalow", "Residential Plot/NA Plot", "Commercial Complex"].includes(formData.propertyType) || formData.propertyType === "" ? 'block' : 'none' }}
                                />
                            </div>


                        </div>

                        {/* <div className="row mt-3 mt-md-3">
                         
                        </div> */}

                        {formData.stageOfConstruction === "Under Construction" && (
                            <div className="row mt-3 mt-md-3">
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-md-0">
                                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Expected Date of Possession"
                                            value={formData.expectedDateOfPossession ? dayjs(formData.expectedDateOfPossession) : null} // Ensure Dayjs object

                                            onChange={(date) => handleDateChange(date, "expectedDateOfPossession")}
                                            renderInput={(params) => <ArthaTextField {...params} fullWidth />}
                                        />
                                    </LocalizationProvider> */}
                                    <ArthaTextField
                                        type="date"
                                        name="expectedDateOfPossession"
                                        label="Expected Date of Possession"
                                        InputLabelProps={{
                                            shrink: true, // Keep the label above the field
                                        }}
                                        onKeyDown={(e) => e.preventDefault()}
                                        value={formData.expectedDateOfPossession || ""} // Handle initial empty state
                                        onChange={(e) => handleDateChange(e.target.value, "expectedDateOfPossession")} // Pass the value and field name
                                        inputProps={{
                                            
                                            min: new Date().toISOString().split("T")[0], // Example: Restrict dates to today or earlier
                                        }}
                                        fullWidth
                                    />
                                    {/* Show an error message if the date is invalid */}
                                    {!formData.expectedDateOfPossession && (
                                        <p className="msg" style={{ color: "red" }}>
                                            Please enter a valid date.
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-md-0">
                                    <ArthaFormControl fullWidth>
                                        <ArthaInputLabel id="percentageOfWorkCompleted-label">
                                            Percentage of Work Completed
                                        </ArthaInputLabel>
                                        <Select
                                            labelId="percentageOfWorkCompleted-label"
                                            id="percentageOfWorkCompleted"
                                            name="percentageOfWorkCompleted"
                                            value={formData.percentageOfWorkCompleted || ""}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="0-10">0 - 10</MenuItem>
                                            <MenuItem value="11-20">11 - 20</MenuItem>
                                            <MenuItem value="21-30">21 - 30</MenuItem>
                                            <MenuItem value="31-40">31 - 40</MenuItem>
                                            <MenuItem value="41-50">41 - 50</MenuItem>
                                            <MenuItem value="51-60">51 - 60</MenuItem>
                                            <MenuItem value="61-70">61 - 70</MenuItem>
                                            <MenuItem value="71-80">71 - 80</MenuItem>
                                            <MenuItem value="81-90">81 - 90</MenuItem>
                                            <MenuItem value="91-100">91 - 100</MenuItem>
                                        </Select>
                                    </ArthaFormControl>
                                </div>
                            </div>


                        )}
                        <div className="row mt-3 mt-md-4">

                            {formData.stageOfConstruction === "Complete" && (
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-md-0">
                                    <ArthaFormControl fullWidth>
                                        <ArthaInputLabel id="ownershipPeriod-label">
                                            Ownership Period Year(s)
                                        </ArthaInputLabel>
                                        <Select
                                            labelId="ownershipPeriod-label"
                                            id="ownershipPeriod"
                                            name="ownershipPeriod"
                                            value={formData.ownershipPeriod || ""}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="0-10">0 - 10</MenuItem>
                                            <MenuItem value="11-20">11 - 20</MenuItem>
                                            <MenuItem value="21-30">21 - 30</MenuItem>
                                            <MenuItem value="31-40">31 - 40</MenuItem>
                                            <MenuItem value="41-50">41 - 50</MenuItem>
                                            <MenuItem value="51-60">51 - 60</MenuItem>
                                            <MenuItem value="61-70">61 - 70</MenuItem>
                                            <MenuItem value="71-80">71 - 80</MenuItem>
                                            <MenuItem value="81-90">81 - 90</MenuItem>
                                            <MenuItem value="91-100">91 - 100</MenuItem>
                                        </Select>
                                    </ArthaFormControl>
                                </div>

                            )}


                        </div>




                        <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-4 mb-4">
                            <h6 className="text mt-4">Property Address</h6>

                            <>
                                <div className="row">

                                    {isAddressAdded ? (
                                        <>
                                            <div className="col-8">
                                                <FaExclamationCircle className="badge-icon" color="green" style={{ marginTop: "0" }} />
                                                <span className="mt-2 upload-lable"> Address Added! </span>
                                            </div>
                                            <div className="col-4">
                                                <Button
                                                    variant="contained"
                                                    className="upload_btn btn_white shadow-none"
                                                    onClick={openAddrDialog}
                                                    style={{ fontSize: '11px !important' }}
                                                >
                                                    Edit
                                                </Button>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="col-7">
                                                <FaExclamationCircle className="badge-icon" style={{ marginTop: "3px" }} />
                                                <span className=" upload-lable" style={{ marginTop: "10px !important" }}> Not added </span>
                                            </div>
                                            <div className="col-5">
                                                <Button
                                                    variant="contained"
                                                    className="upload_btn btn_white shadow-none "
                                                    onClick={openAddrDialog}
                                                    style={{ fontSize: '11px !important' }}
                                                >
                                                    Add Address
                                                </Button>
                                            </div>
                                        </>
                                    )}

                                </div>
                            </>
                        </div>
                    </div>
                    <>
                        <Dialog
                            open={openAddressDialog}

                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <div className="modal row">
                                <div className="modal-content col-md-6">
                                    {!isAddressAdded ? <h6> Add New Address</h6> : <h6>Edit Address</h6>}
                                    <div className="row">
                                        <div className="col-md-6 mt-3">
                                            <ArthaTextField
                                                name="line1"
                                                fullWidth
                                                value={currentAddress && currentAddress.line1}
                                                onChange={(e) => { handleAddressChange(e) }}
                                                label="Address Line 1"

                                            />
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <ArthaTextField
                                                name="line2"
                                                onChange={(e) => { handleAddressChange(e) }}
                                                value={currentAddress && currentAddress.line2}
                                                fullWidth
                                                label="Address Line 2"
                                            />
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <ArthaTextField
                                                name="pincode"
                                                fullWidth
                                                value={currentAddress && currentAddress.pincode}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value
                                                        .replace(/\D/g, "")
                                                        .slice(0, 6);
                                                    handleAddressChange({
                                                        target: {
                                                            name: "pincode",
                                                            value: inputValue,
                                                        },
                                                    });
                                                    setPincodeError(
                                                        inputValue.length === 6
                                                            ? ""
                                                            : "Invalid pincode"
                                                    );

                                                    if (inputValue.length === 6) {
                                                        fetchAddressDetails(inputValue);
                                                    } else {
                                                        // Clear city and state if pincode is cleared
                                                        setCurrentAddress((prevState) => ({
                                                            ...prevState,
                                                            city: "",
                                                            state: "",
                                                        }));
                                                    }
                                                    checkFormFilled();
                                                }}
                                                label="Pincode"
                                            // InputLabelProps={{ shrink: true }}
                                            />
                                            <span style={{ color: "red", fontSize: "12px" }}>
                                                {pincodeError}
                                            </span>
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <ArthaTextField
                                                name="city"
                                                fullWidth
                                                value={currentAddress && currentAddress.city}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value.replace(
                                                        /[^a-zA-Z]/g,
                                                        ""
                                                    );
                                                    handleAddressChange({
                                                        target: {
                                                            name: "city",
                                                            value: inputValue,
                                                        },
                                                    });
                                                }}
                                                label="City"
                                            // InputLabelProps={{ shrink: true }} // Add this line
                                            />
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <ArthaTextField
                                                name="state"
                                                fullWidth
                                                value={currentAddress && currentAddress.state}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value.replace(
                                                        /[^a-zA-Z]/g,
                                                        ""
                                                    );
                                                    handleAddressChange({
                                                        target: {
                                                            name: "state",
                                                            value: inputValue,
                                                        },
                                                    });
                                                }}
                                                label="State"
                                            // InputLabelProps={{ shrink: true }} // Add this line
                                            />
                                        </div>
                                    </div>


                                    <div className="row mt-4 mx-auto">
                                        <div className="col-6 d-flex justify-content-end mt-4">
                                            <ArthaClearButton variant="contained" onClick={closeAddrDialog}>
                                                Cancel
                                            </ArthaClearButton>
                                        </div>
                                        <div className="col-6 d-flex justify-content-start mt-4">
                                            <ArthaContinueButton
                                                variant="contained"
                                                disabled={!isFormFilled}
                                                onClick={() => { setOpenAddressDialog(false); setAddressAdded(true) }}
                                            >
                                                Save
                                            </ArthaContinueButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </>

                    <h6 className="text mt-5">Property Documents</h6>
                    <div className="row mt-4" style={{ border: "1px solid #a39898", borderRadius: "10px", padding: "20px" }}>
                        <div className="col-12 col-sm-12 col-md- col-lg-6 mt-2">
                            <div className="row mt-1">
                                <div className="col-9">
                                    <h6 className="text font-weight-bold mb-2">Supporting Documents</h6>
                                    {selectedDocs.document && selectedDocs.document.length > 0 ? (
                                        selectedDocs.document.map((doc, index) => (
                                            <React.Fragment key={index}>
                                                <FaCheckCircle
                                                    className="badge-icon"
                                                    // style={{ marginTop: "-7px", color: "green" }}
                                                    style={{ marginTop: "3px", color: "green" }}
                                                />
                                                <span
                                                    className="mt-2 upload-lable"
                                                    // style={{ position: "relative", top: "-10px" }}
                                                    style={{ marginTop: "10px !important" }}
                                                >
                                                    {doc.documentType} <br />
                                                </span>
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        <div>
                                            <FaExclamationCircle className="badge-icon" style={{ marginTop: "3px" }} />
                                            <span
                                                className="mt-5 upload-lable"
                                                // style={{ position: "relative" }}
                                                style={{ marginTop: "10px !important" }}
                                            >
                                                Not Uploaded
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <div className="col-2">
                                    <input
                                        type="file"
                                        style={{ display: "none" }}
                                        onChange={handleFileChangeID}
                                    />
                                    <Button
                                        variant="contained"
                                        className="upload_btn btn_white shadow-none mt-3"
                                        onClick={handleUploadDocument}
                                    >
                                        Upload
                                    </Button>
                                    <UploadPropertyDoc
                                        open={isPopupOpen}
                                        existingDocuments={selectedDocs.document || selectedDocs}
                                        onRemove={removeExistingDoc}
                                        handleClose={handlePopupClose}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="row mt-3 mb-2 mt-md-2">

                                <div className="col-sm-12 col-md-6 mt-md-3 col-lg-6 text-end">
                                    {propertyForms.length > 1 && (
                                        <ArthaClearButton
                                            variant="contained"
                                            onClick={() => handleRemoveProperty(index)}
                                        >
                                            Remove Property
                                        </ArthaClearButton>
                                    )}
                                </div>
                            </div> */}


                    {/* <div className="col-12 mt-4">
                        <ArthaClearButton variant="contained" onClick={handleAddProperty}>
                            Add Property
                        </ArthaClearButton>
                    </div> */}
                    <div className="row mt-4 mx-auto">
                        <div className="col-6 d-flex justify-content-end mt-4">
                            <ArthaClearButton variant="contained" onClick={handleClear}>
                                Back
                            </ArthaClearButton>
                        </div>

                        <div className="col-6 d-flex justify-content-start mt-4">
                            <ArthaContinueButton
                                variant="contained"
                                onClick={handleContinue}
                                // disabled={!isFormValid}
                                style={{
                                    backgroundColor: isFormValid ? "rgba(255, 115, 0, 1)" : "rgb(255 98 0 / 39%)",
                                    pointerEvents: isFormValid ? "auto" : "none",
                                }}
                            >
                                {isLoading ? (
                                    <LoadingSpinner style={{ pointerEvents: "none" }} />
                                ) : (
                                    "Add Property - Owners"
                                )}
                            </ArthaContinueButton>

                        </div>
                    </div>
                </>
            ) : (
                <EligibilityCheckForm
                    onCancel={() => setShowEligibilityCheckForm(false)}
                />
            )
            }
        </>
    );
};

export default UpdatePropertyDetails;