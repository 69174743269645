

import React, { useState } from "react";
import { MdOutlineFileUpload } from "react-icons/md";
import { TextField, Button, InputLabel, Select, MenuItem, Dialog } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import ArthaClearButton from "../../fields/ArthaClearButton";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import ArthaFormControl from "../../fields/ArthaFormControl";
import ArthaTextField from "../../fields/ArthaTextField";
import { FaExclamationCircle } from "react-icons/fa";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const PreviousSalariedEmpDetails = ({ worksince, prevSalariedEmployer, setPrevSalariedEmployer, salariedPreviousAddress, setSalariedPreviousAddress }) => {
    const [employerDropdown, setEmployerDropdown] = React.useState(["Artha", "Aimscript"])
    const [employerTypeDropdown, setEmployerTypeDropdown] = React.useState(["Public", "Private"])
    const [bankNameDropdown, setBankNameeDropdown] = React.useState(["ICICI", "SBI"])
    const [isAddressAdded, setAddressAdded] = React.useState(false);

    const [registrationDocument, setRegistrationDocument] = React.useState({});
    const [openAddressDialog, setOpenAddressDialog] = useState(false);
    const [pincodeError, setPincodeError] = React.useState('');
    const isPincodeValid = (pincode) => /^\d{6}$/.test(pincode);
    const isCityValid = (city) => /^[a-zA-Z\s]+$/.test(city);
    const isStateValid = (state) => /^[a-zA-Z\s]+$/.test(state);
    const [isFormFilled, setIsFormFilled] = useState(false);
    const openAddrDialog = () => {
        setOpenAddressDialog(true);
        setPincodeError('')
    }
    const handleFileChangeID = (e) => {
        const selectedFile = e.target.files[0];
    };
    const handleAddressChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name == 'pincode' && value == '000000') {
            setPincodeError('Please enter a valid 6-digit pincode')
        }
        setSalariedPreviousAddress(prevAddress => ({
            ...salariedPreviousAddress,
            [name]: value
        }));
        checkFormFilled();
    }
    const checkFormFilled = () => {
        if (!salariedPreviousAddress) return;

        const { line1, line2, pincode, city, state } = salariedPreviousAddress;

        const isLine1Filled = line1 && line1.trim() !== '' && /^[a-zA-Z0-9\s]+$/.test(line1);
        const isLine2Filled = line2 && line2.trim() !== '' && /^[a-zA-Z0-9\s]+$/.test(line2);
        const isPincodeFilled = pincode && pincode.trim() !== '' && /^\d{6}$/.test(pincode); // Assuming pincode is 6 digits
        const isCityFilled = city && city.trim() !== '' && /^[a-zA-Z\s]+$/.test(city);
        const isStateFilled = state && state.trim() !== '' && /^[a-zA-Z\s]+$/.test(state);

        let isPincodeValidated = isPincodeFilled && isPincodeValid(pincode);
        if (pincode == '000000') {
            isPincodeValidated = false;
        }
        if (isPincodeValidated) {
            setPincodeError('');
        }
        const isCityValidated = isCityFilled && isCityValid(city);
        const isStateValidated = isStateFilled && isStateValid(state);

        if (
            isLine1Filled &&
            isLine2Filled &&
            isPincodeFilled &&
            isCityFilled &&
            isStateFilled &&
            isPincodeValidated &&
            isCityValidated &&
            isStateValidated
        ) {
            setIsFormFilled(true);
        } else {
            setIsFormFilled(false);
        }

    };
    const closeAddrDialog = () => {
        if (isAddressAdded) {
            setOpenAddressDialog(false);
        } else {
            setOpenAddressDialog(false);
            setSalariedPreviousAddress({
                'line1': '',
                'line2': '',
                'pincode': '',
                'city': '',
                'state': ''
            })
        }
    }

    const handleWorkingSince = (e) => {
        setPrevSalariedEmployer({
            ...prevSalariedEmployer,
            workfrom: e,
        })
    }
    const handleWorkingTill = (e) => {
        setPrevSalariedEmployer({
            ...prevSalariedEmployer,
            worktill: e,
        })
    }
    return (
        <>
            <div className="row mt-4">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <ArthaFormControl fullWidth>
                        <InputLabel id="companyName-label">
                            Previous Employer Name
                        </InputLabel>
                        <Select
                            labelId="companyName-label"
                            id="companyName"
                            name="companyName"
                            value={prevSalariedEmployer.companyName}
                            onChange={(e) =>
                                setPrevSalariedEmployer({
                                    ...prevSalariedEmployer,
                                    companyName: e.target.value,
                                })
                            }
                        >
                            {employerDropdown?.map((item) => {
                                return (<MenuItem key={item} value={item}>{item}</MenuItem>)
                            })}
                        </Select>
                    </ArthaFormControl>
                </div>

                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <ArthaFormControl fullWidth>
                        <InputLabel id="employerType-label">
                            Previous Employer Type
                        </InputLabel>
                        <Select
                            labelId="employerType-label"
                            id="employerType"
                            name="employerType"
                            value={prevSalariedEmployer.employerType}
                            onChange={(e) =>
                                setPrevSalariedEmployer({
                                    ...prevSalariedEmployer,
                                    employerType: e.target.value,
                                })
                            }
                        >
                            {employerTypeDropdown?.map((item) => {
                                return (<MenuItem key={item} value={item}>{item}</MenuItem>)
                            })}
                        </Select>
                    </ArthaFormControl>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row">
                        <div className="col-8 col-md-7">
                            <p className="msg">Firm Address</p>

                            {
                                isAddressAdded ? <>
                                    <FaExclamationCircle className="badge-icon" style={{ marginTop: "-7px", color: 'green' }} />
                                    <span
                                        className="mt-2 upload-lable"
                                        style={{ position: "relative", top: "-10px" }}
                                    >
                                        {" "}
                                        Address added!
                                    </span>
                                </> : <>
                                    <FaExclamationCircle className="badge-icon" />
                                    <span
                                        className="mt-2 upload-lable"
                                        style={{ position: "relative", top: "-10px" }}
                                    >
                                        {" "}
                                        Not Added
                                    </span>
                                </>

                            }
                        </div>
                        <div className="col-4 col-md-5">
                            <input
                                type="file"
                                style={{ display: "none" }}
                                onChange={handleFileChangeID}
                            />
                            <Button
                                variant="contained"
                                className="upload_btn btn_white shadow-none mt-3"
                                onClick={openAddrDialog}
                            >
                                {!isAddressAdded ? ' Add Address' : ' Edit'}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                    <ArthaTextField
                        name="employerWebsite"
                        value={prevSalariedEmployer.employerWebsite}
                        label="Employer website (optional)"
                        fullWidth
                        inputProps={{ maxLength: 80 }}
                        onChange={(e) =>
                            setPrevSalariedEmployer({
                                ...prevSalariedEmployer,
                                employerWebsite: e.target.value,
                            })
                        }
                    />
                </div>
                {/* Dialog started */}
                <Dialog
                    open={openAddressDialog}

                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div className="modal row">
                        <div className="modal-content col-md-6">
                            {!isAddressAdded ? <h6> Add New Address</h6> : <h6>Edit Address</h6>}
                            <div className="row">
                                <div className="col-md-6 mt-3">
                                    <ArthaTextField
                                        name="line1"
                                        fullWidth
                                        value={salariedPreviousAddress?.line1}
                                        onChange={(e) => { handleAddressChange(e) }}
                                        label="Address Line 1"

                                    />
                                </div>
                                <div className="col-md-6 mt-3">
                                    <ArthaTextField
                                        name="line2"
                                        onChange={(e) => { handleAddressChange(e) }}
                                        value={salariedPreviousAddress?.line2}
                                        fullWidth
                                        label="Address Line 2"
                                    />
                                </div>
                                <div className="col-md-6 mt-5 mt-md-3">
                                    <ArthaTextField
                                        name="pincode"
                                        onChange={(e) => {
                                            const inputValue = e.target.value.replace(/\D/g, '').slice(0, 6);
                                            handleAddressChange({ target: { name: 'pincode', value: inputValue } });
                                        }}
                                        value={salariedPreviousAddress?.pincode}
                                        label="Pincode"
                                        fullWidth
                                    />
                                    <span style={{ color: 'red', fontSize: '12px' }}>{pincodeError}</span>

                                </div>
                                <div className="col-md-6 mt-3 mt-md-3">
                                    <ArthaTextField
                                        name="city"
                                        label="City"
                                        onChange={(e) => {
                                            const inputValue = e.target.value.replace(/[^a-zA-Z]/g, '');
                                            handleAddressChange({ target: { name: 'city', value: inputValue } });
                                        }}
                                        value={salariedPreviousAddress?.city}
                                        fullWidth

                                    />
                                </div>
                                <div className="col-md-6 mt-3 mt-md-3">
                                    <ArthaTextField
                                        name="state"
                                        label="State"
                                        onChange={(e) => {
                                            const inputValue = e.target.value.replace(/[^a-zA-Z]/g, '');
                                            handleAddressChange({ target: { name: 'state', value: inputValue } });
                                        }}
                                        value={salariedPreviousAddress?.state}
                                        fullWidth
                                    />
                                </div>
                            </div>

                            <div className="row mt-4 mx-auto">
                                <div className="col-6 d-flex justify-content-end mt-4">
                                    <ArthaClearButton variant="contained" onClick={closeAddrDialog}>
                                        Cancel
                                    </ArthaClearButton>
                                </div>
                                <div className="col-6 d-flex justify-content-start mt-4">
                                    <ArthaContinueButton
                                        variant="contained"
                                        disabled={!isFormFilled}
                                        onClick={() => { setOpenAddressDialog(false); setAddressAdded(true) }}
                                    >
                                        Save
                                    </ArthaContinueButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>

            <div className="row mt-4">
                <div className="col-sm-12 col-md-6 col-lg-6">
                    <ArthaTextField
                        name="designation"
                        value={prevSalariedEmployer.designation}
                        label="Designation"
                        fullWidth
                        inputProps={{ maxLength: 80 }}
                        onChange={(e) =>
                            setPrevSalariedEmployer({
                                ...prevSalariedEmployer,
                                designation: e.target.value,
                            })
                        }
                    />
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row">
                        <div className="col-8 col-md-7">
                            <p className="msg">ID card (optional)</p>

                            {
                                isAddressAdded ? <>
                                    <FaExclamationCircle className="badge-icon" style={{ marginTop: "-7px", color: 'green' }} />
                                    <span
                                        className="mt-2 upload-lable"
                                        style={{ position: "relative", top: "-10px" }}
                                    >
                                        {" "}
                                        Address added!
                                    </span>
                                </> : <>
                                    <FaExclamationCircle className="badge-icon" />
                                    <span
                                        className="mt-2 upload-lable"
                                        style={{ position: "relative", top: "-10px" }}
                                    >
                                        {" "}
                                        Not Added
                                    </span>
                                </>

                            }
                        </div>
                        <div className="col-4 col-md-5">
                            <input
                                type="file"
                                style={{ display: "none" }}
                                onChange={handleFileChangeID}
                            />
                            <Button
                                variant="contained"
                                className="upload_btn btn_white shadow-none mt-3"
                                onClick={openAddrDialog}
                            >
                                {!isAddressAdded ? ' Upload' : ' Edit'}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-sm-12 col-md-6 col-lg-6">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Working since"
                            value={prevSalariedEmployer.workfrom}
                            onChange={(e) =>
                                handleWorkingSince(e)
                            }
                            renderInput={(params) => <TextField {...params} />}
                            className="yearPicker"
                            onKeyDown={(e) => e.preventDefault()} // Prevent manual typing
                        />
                    </LocalizationProvider>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Working till"
                            value={prevSalariedEmployer.worktill}
                            onChange={(e) =>
                                handleWorkingTill(e)
                            }
                            renderInput={(params) => <TextField {...params} />}
                            className="yearPicker"
                        />
                    </LocalizationProvider>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <ArthaFormControl fullWidth>
                        <InputLabel id="companyName-label">
                            Salary Bank Account
                        </InputLabel>
                        <Select
                            labelId="companyName-label"
                            id="companyName"
                            name="companyName"
                            value={prevSalariedEmployer.bankName}
                            onChange={(e) =>
                                setPrevSalariedEmployer({
                                    ...prevSalariedEmployer,
                                    bankName: e.target.value,
                                })
                            }
                        >
                            {bankNameDropdown?.map((item) => {
                                return (<MenuItem key={item} value={item}>{item}</MenuItem>)
                            })}
                        </Select>
                    </ArthaFormControl>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                    <ArthaTextField
                        name="accNo"
                        value={prevSalariedEmployer.accNo}
                        label="Salary Account Number"
                        fullWidth
                        inputProps={{ maxLength: 80 }}
                        onChange={(e) =>
                            setPrevSalariedEmployer({
                                ...prevSalariedEmployer,
                                accNo: e.target.value,
                            })
                        }
                    />
                </div>
            </div>
            <div className="row mt-3 mt-md-4">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row">
                        <div className="col-8 col-md-7">
                            <p className="msg">Supporting Documents</p>

                            {
                                registrationDocument && registrationDocument.documentType ? <>
                                    <FaExclamationCircle className="badge-icon" style={{ marginTop: "-7px", color: 'green' }} />
                                    <span
                                        className="mt-2 upload-lable"
                                        style={{ position: "relative", top: "-10px" }}
                                    >
                                        {" "}
                                        {registrationDocument.documentType}
                                    </span>
                                </> : <>
                                    <FaExclamationCircle className="badge-icon" />
                                    <span
                                        className="mt-2 upload-lable"
                                        style={{ position: "relative", top: "-10px" }}
                                    >
                                        {" "}
                                        Not Uploaded
                                    </span>
                                </>

                            }
                        </div>
                        <div className="col-4 col-md-5">
                            <input
                                type="file"
                                style={{ display: "none" }}
                                onChange={handleFileChangeID}
                            />
                            <Button
                                variant="contained"
                                className="upload_btn btn_white shadow-none mt-3"
                            >
                                Upload
                            </Button>
                            {/* <UploadSingleDocument
                                    open={isPopupOpen}
                                    onRemove={removeExistingDoc}
                                    handleClose={handlePopupClose}
                                    onSave={onSave}
                                    documentTypeDropdowns={registrationDocumentTypes}
                                    existingDocument={registrationDocument}
                                /> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PreviousSalariedEmpDetails;
