import React, { useEffect, useState, useRef } from "react";
import "./personalLoan.css";
import ArthaContinueButton from "../../fields/ArthaContinueButton.js";
import ArthaClearButton from "../../fields/ArthaClearButton";
import ArthaTextField from "../../fields/ArthaTextField";
import PinCodeValidation from "./pinCodeValidation";
import EligibilityCheckForm from "./EligibilityCheckForm.jsx";
import { useNavigate } from "react-router-dom";
import { handleSignOut } from "./SessionExpiredApi";
import LoadingSpinner from "../../fields/spinner.jsx";
import { useDispatch } from "react-redux";
import {
  businessLoanUpdatePersonalDetailsAPI,
  businessLoanUdyamVerificationAPI,
  businessLoanUserDetailsAPI,
  businessLoanCreateLoanApplicationAPI,
} from "../../actioncreators/actioncreators";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  FormControlLabel,
  Dialog,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Radio,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import { FetchBuisnessAPID } from "../../actions/loanAction.js";

const PersonalDetailsForm = ({ onSubmit, onCancel }) => {
  const hasFetched = useRef(false); // Tracks if the API call has already been made
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    businessPan: '',
    pan: "",
    mobile: "",
    fullName: "",
    dateOfBirth: "",
    emailId: "",
    currentBusinessPincode: "",
    udyamAadhaar: "",
    businessPan: "",
    businessName: "",
    commencementDate: "",
    businessId: "",
  });

  const [isOpen, setIsOpen] = useState(false);
  const [isValidPanCard, setIsValidPanCard] = useState(true);
  const [isValidBusinessPan, setIsValidBusinessPan] = useState(true);
  const [isValidDOB, setIsValidDOB] = useState(true);
  const [isValidCommencementDate, setisValidCommencementDate] = useState(true);
  const navigate = useNavigate();
  const [ap_id, setApid] = useState("");
  const [loan_app_id, setLoanAppId] = useState("");
  const [loading, setLoading] = useState(true);
  const [showEligibilityCheckForm, setShowEligibilityCheckForm] =
    useState(false);
  const [isError, setIsError] = useState(false);
  const [udyamError, setUdyamError] = useState(false);
  const [pinCodeError, setPinCodeError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [businessId, setBusinessId] = useState(false);
  const [isUdyamValid, setIsUdyamValid] = useState(false); // State variable to track Udyam regex validity
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed
  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error.response.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }

    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };
  useEffect(() => {
   
    if (!hasFetched.current) {
      hasFetched.current = true; // Set the flag to true to prevent subsequent calls
    const fetchData1 = async () => {
      try {
        const response = await businessLoanCreateLoanApplicationAPI();
        if (response.businessStatusCode === 2) {
          setApid(response.ap_id);
          dispatch(FetchBuisnessAPID(response.loan_app_id))
          setLoanAppId(response.loan_app_id);
        }
        const loanApplicationStatus = response.status;
        const fetchData = async () => {
          try {
            const response = await businessLoanUserDetailsAPI();
            const {
              mobile,
              pan,
              fullName,
              email,
              dob,
              currentBusinessPincode,
              businessId,
              commencementDate,
              businessPan,
              businessName,
              udyamAadhaar,
            } = response.data;

            setFormData((prevData) => ({
              ...prevData,
              pan: pan,
              mobile: mobile,
              fullName: fullName,
              dateOfBirth: formatDate(dob),
              emailId: email,
              currentBusinessPincode: currentBusinessPincode,
              businessId: businessId,
              commencementDate: formatDate(commencementDate),
              businessPan: businessPan,
              businessName: businessName,
              udyamAadhaar: udyamAadhaar,
            }));
            setBusinessId(businessId);
            // If udyamAadhaar exists and there's business data, consider it verified
            if (udyamAadhaar && businessName && commencementDate) {
              setIsUdyamVerified(true);
              setIsUdyamInvalid(false);
              setUdyamError(""); // Clear any existing errors
            } else {
              setIsUdyamVerified(false);
            }

            // Validate Udyam format if it exists
            if (udyamAadhaar) {
              const isUdyamValid = /^UDYAM-[A-Za-z]{2}-\d{2}-\d{7}$/.test(udyamAadhaar.trim());
              if (!isUdyamValid) {
                setUdyamError("Invalid Udyam Number. Please enter in the specified format.");
                setIsUdyamVerified(false);
              }
            }

          } catch (error) {
            handleApiError(error);
            console.error("Error fetching user data:", error);
          }
        };
        await fetchData();
        navigate("/home/businessloan", {
          state: {
            loanApplicationStatus,
          },
        });
      } catch (error) {
        handleApiError(error);
        console.error("Error fetching user data:", error);
      }
    };

    fetchData1();

  }
  }, [ap_id, loan_app_id]);

  const formatDate = (inputDate) => {
    console.log("input date : ", inputDate);
    if (inputDate) {
      const [day, month, year] = inputDate.split("/");
      if (!/^\d{4}$/.test(year)) {
        console.error("Invalid year format");
        return "";
      }

      return `${year}-${month}-${day}`;
    } else {
      return "";
    }
  };

  useEffect(() => {
    validateForm();
  }, [formData]);

  const [isFormValid, setIsFormValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const businesspanRegex = /^[A-Z]{3}[F|T|H|P|C]{1}[A-Z]{1}\d{4}[A-Z]{1}$/gm;


  const [isUdyamVerified, setIsUdyamVerified] = useState(false);
  const [isVerifyingUdyam, setIsVerifyingUdyam] = useState(false);
  const [isUdyamInvalid, setIsUdyamInvalid] = useState(false);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setIsUserInteracted(true);
    // Apply specific validation based on the field name
    let updatedValue = value;
    if (name === "udyamAadhaar") {
      // setIsUdyamVerified(false);
      // setIsUdyamInvalid(false);
      // setIsVerifyingUdyam(true);
      const updatedValue = value.trim().toUpperCase(); // Trim leading/trailing spaces and convert to uppercase
      const isUdyamValid = /^UDYAM-[A-Za-z]{2}-\d{2}-\d{7}$/.test(updatedValue);
      if (!isUdyamValid && updatedValue.length > 0) {
        setUdyamError(
          "Invalid Udyam Number. Please enter in the specified format."
        );
        setIsVerifyingUdyam(false);
        setIsUdyamInvalid(false);
        setIsUdyamVerified(false);
      } else {
        setUdyamError("");
      }

      if (updatedValue.length === 0) {
        // Clear the error if udyamAadhaar is empty
        setUdyamError("");
        setIsUdyamInvalid(false);
        setIsVerifyingUdyam(false);
        setIsUdyamVerified(false);
      }

      if (!updatedValue) {
        // Set businessName to null if udyamAadhaar is empty
        setFormData((prevData) => ({
          ...prevData,
          udyamAadhaar: updatedValue,
          businessName: "",
          commencementDate: "",
        }));
        return;
      }
    }
    if (name === "pan") {
      // Validate Pan card format: First 5 letters, 4 digits, 1 letter
      updatedValue = value.toUpperCase();
      const enteredPanCard = updatedValue;
      const panCardRegex = /^[A-Z]{5}\d{4}[A-Z]{1}$/;
      setIsValidPanCard(panCardRegex.test(enteredPanCard));
    } else if (name === "fullName") {
      // You might want to discuss this validation with stakeholders
      updatedValue = value.replace(/[\d!@#$%^&*()_+={}\[\]:;<>,.?~\\/-]/g, "");
    } else if (name === "mobile") {
      updatedValue = value.replace(/\D/g, "");
    } else if (name === "currentBusinessPincode") {
      updatedValue = value.replace(/\D/g, "").slice(0, 6);
      const isPinCodeValid = /^\d{6}$/.test(updatedValue);
      if (!isPinCodeValid) {
        setPinCodeError("Pincode must be 6 digits");
      } else {
        setPinCodeError("");
      }
    } else if (name === "emailId") {
      const isEmailValid =
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value);

      if (!isEmailValid) {
        setEmailError("Invalid Email. Please enter a valid email address.");
      } else {
        setEmailError("");
      }

      setIsEmailValid(isEmailValid);
    } else if (name === "dateOfBirth") {
      const [year, month, day] = value.split("-");
      const isValidDate = /^\d{4}-\d{2}-\d{2}$/.test(value);

      if (!isValidDate) {
        console.error("Invalid date format");
        // Handle error gracefully, maybe set an error state
        return;
      }

      updatedValue = `${year}-${month}-${day}`;
    } else if (name === 'businessPan') {
      // Convert to uppercase first, then remove special characters
      updatedValue = value.toUpperCase().replace(/[^A-Z0-9]/g, "");
      // Limit the input to 10 characters
      if (updatedValue.length > 10) {
        updatedValue = updatedValue.slice(0, 10);
      }
      setIsValidBusinessPan(value === '' || businesspanRegex.test(updatedValue));
    } else if (name === 'commencementDate') {
      const currentDate = new Date();
      const hundredYearsAgo = currentDate.getFullYear() - 100;

      // Convert the date value from YYYY-MM-DD to a Date object
      const enteredDate = new Date(value);
      const enteredYear = enteredDate.getFullYear();

      // Check if the year is within the last 100 years
      const isValidYear = enteredYear >= hundredYearsAgo && enteredYear <= currentDate.getFullYear();

      // Check if the date is valid
      const isValidDate = !isNaN(enteredDate.getTime()) && enteredDate.toISOString().split('T')[0] === value;

      // Check if the entered date is in the future
      const isFutureDate = enteredDate > currentDate;

      // Combine all validation checks
      if (!isValidDate || !isValidYear || isFutureDate) {
        setisValidCommencementDate(false);
      } else {
        setisValidCommencementDate(true);
      }
    }


    setFormData((prevData) => ({
      ...prevData,
      [name]: updatedValue,
      //...additionalUpdates,
    }));
    setIsUserInteracted(true);
    // validateForm(); // Make sure validateForm() is defined elsewhere
  };

  const validateForm = () => {
    const {
      pan,
      mobile,
      fullName,
      dateOfBirth,
      emailId,
      currentBusinessPincode,
      udyamAadhaar,
      businessName,
      commencementDate,
      businessPan,
    } = formData;
    const isUdyamValid = /^UDYAM-[A-Za-z]{2}-\d{2}-\d{7}$/.test(udyamAadhaar);
    const isPanCardValid = /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/.test(pan);
    const isMobileValid = /^\d{10}$/.test(mobile);
    const isFullNameValid = fullName && fullName.trim() !== "";
    let isDateOfBirthValid = false;
    const isEmailValid = /^\S+@\S+\.\S+$/.test(emailId);
    const isPinCodeValid = /^\d{6}$/.test(currentBusinessPincode);
    const currentDate = new Date();
    const selectedDate = new Date(dateOfBirth);
    const minDate = new Date(currentDate);
    minDate.setFullYear(minDate.getFullYear() - 100);
    const maxDate = new Date(currentDate);
    maxDate.setFullYear(maxDate.getFullYear() - 18);

    if (selectedDate < minDate || selectedDate > maxDate) {
      isDateOfBirthValid = false;
      setIsValidDOB(false);
    } else {
      isDateOfBirthValid = true;
      setIsValidDOB(true);
    }

    setIsFormValid(
      isUdyamValid &&
      isPanCardValid &&
      isMobileValid &&
      isFullNameValid &&
      isDateOfBirthValid &&
      isEmailValid &&
      isPinCodeValid &&
      businessName &&
      commencementDate &&
      isValidBusinessPan &&// Check for Business PAN validity
      isValidCommencementDate
    );
  };
  const handleClear = () => {
    setFormData((prevData) => ({
      ...prevData,
      businessName: "", // Reset businessName when clearing the form
    }));
    navigate("/home");
  };

  useEffect(() => {
    const pinCodeInput = document.getElementById("pin-code-inpt");
    if (pinCodeInput) {
      pinCodeInput.focus();
    }
  }, []);

  const buttonStyle = {
    color: "white",
    border: "none",
    backgroundColor:
      isFormValid && isEmailValid
        ? "rgba(255, 115, 0, 1)"
        : "rgb(255 98 0 / 39%)",
    opacity: isButtonClicked ? 0.39 : 1,
    pointerEvents: isButtonClicked ? "none" : "auto",
  };

  const clearButtonStyle = {
    color: "black",
    backgroundColor: isFormValid
      ? "rgba(255, 255, 255, 1)"
      : "rgb(255 255 255 / 39%)",
  };

  const handleContinue = async (values, setSubmitting) => {
    setIsButtonClicked(true);
    setIsLoading(true);
    const formattedDateOfBirth = reverseFormatDate(formData.dateOfBirth);
    const fromatedcommecementDate = reverseFormatDate(
      formData.commencementDate
    );
    const data = {
      applicantDetails: {
        email: formData.emailId,
        fullName: formData.fullName,
        dob: formattedDateOfBirth,
      },
      businessDetails: {
        udyamAadhaar: formData.udyamAadhaar,
        businessPan: formData.businessPan,
        businessName: formData.businessName,
        commencementDate: fromatedcommecementDate,
        currentBusinessPincode: formData.currentBusinessPincode,
      },
    };
    try {
      const response = await businessLoanUpdatePersonalDetailsAPI(data);
      console.log(
        "response.businessStatusCode",
        response.data.businessStatusCode
      );
      if (response.data.businessStatusCode === 2) {
        setShowEligibilityCheckForm(true);
        onSubmit(formData);
        toast.success("Applicant Details Added Successfully");
      } else if (
        response.data.businessStatusCode === 1 &&
        response.data.businessStatusSubCode === 1.1
      ) {
        toast.info(response.data.message);
      } else if (
        response.data.businessStatusCode === 1 &&
        response.data.businessStatusSubCode === 1.3
      ) {
        toast.warning(response.data.message);
        navigate("/home");
      } else {
        setShowEligibilityCheckForm(false);
        setIsOpen(true);
      }
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching loan data:", error);
    } finally {
      setIsLoading(false);
      setIsButtonClicked(false);
    }
  };

  const reverseFormatDate = (inputDate) => {
    const [year, month, day] = inputDate.split("-");
    return `${day}/${month}/${year}`;
  };

  const closeModal = () => {
    setIsOpen(false);
    let inpt = document.getElementById("pin-code-inpt");
    inpt.focus();
  };

  const currentDate = new Date().toISOString().split("T")[0];
  const [isUserInteracted, setIsUserInteracted] = useState(false);


  const handleBlur = async () => {
    if (isUserInteracted && formData.udyamAadhaar && !udyamError) {
      setIsVerifyingUdyam(true);
      const requestBody = {
        udyamNumber: formData.udyamAadhaar,
        businessId: formData.businessId,
      };

      try {
        const response = await businessLoanUdyamVerificationAPI(requestBody);

        if (response?.data?.httpResponseCode === 200) {
          if (response?.data?.businessStatusCode === 2) {
            setIsUdyamVerified(true);
            setIsUdyamInvalid(false);
            setIsVerifyingUdyam(false);
            const { businessName, commencementDate, businessPan } = response.data;
            setFormData((prevData) => ({
              ...prevData,
              commencementDate: formatDate(commencementDate),
              businessPan: businessPan,
              businessName: businessName,
            }));
          } else if (response?.data?.businessStatusCode === 1) {
            setIsUdyamInvalid(true);
            setIsUdyamVerified(false);
            setIsVerifyingUdyam(false);
          }
        } else {
          setIsUdyamVerified(false);
          setIsUdyamInvalid(true);
          setIsVerifyingUdyam(false);
        }
      } catch (error) {
        handleApiError(error);
        console.error("Error verifying Udyam Number:", error);
        setIsUdyamVerified(false);
        setIsUdyamInvalid(true);
      } finally {
        setIsVerifyingUdyam(false);
        setIsButtonClicked(false);
        setIsLoading(false);
      }
    }
  }

  return (
    <>
      {!showEligibilityCheckForm ? (
        <>
          <h6 className="text">Applicant Details</h6>

          <form className="mt-4">
            <div className="row mt-4">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <ArthaTextField
                  id="outlined-basic"
                  name="pan"
                  value={formData.pan}
                  onChange={handleChange}
                  label="Pan Card No."
                  fullWidth
                  variant="outlined"
                  disabled
                  className="form-control custom-disabled-input"
                />

                <div className="subtitle">
                  <div
                    className={`pan-card-validation ${isValidPanCard ? "" : "invalid-input"
                      }`}
                  >
                    {isValidPanCard
                      ? ""
                      : "Invalid Pan Card. Please enter in the specified format."}
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                <ArthaTextField
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  label="Mobile No."
                  fullWidth
                  className="form-control custom-disabled-input"
                  disabled
                />
              </div>
            </div>

            <div className="row mt-3 mt-md-4">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <ArthaTextField
                  name="fullName"
                  value={formData.fullName}
                  disabled
                  onChange={handleChange}
                  label="Full Name"
                  fullWidth
                  className="form-control custom-disabled-input"
                  inputProps={{ maxLength: 80 }}
                />
                <p className="msg pt-3">As per PAN</p>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6">
                <ArthaTextField
                  type="date"
                  name="dateOfBirth"
                  label="Date of birth"
                  className="form-control custom-disabled-input"
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formData.dateOfBirth}
                  onChange={handleChange}
                  inputProps={{
                    max: currentDate, // Set max date to current date
                  }}
                  fullWidth
                />
                {!isValidDOB && (
                  <p className="msg" style={{ color: "red" }}>
                    Invalid date
                  </p>
                )}
              </div>
            </div>

            <div className="row mt-3 mt-md-2">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <ArthaTextField
                  type="email"
                  name="emailId"
                  value={formData.emailId}
                  onChange={handleChange}
                  label="Email"
                  fullWidth
                  error={Boolean(emailError)}
                  helperText={emailError}
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0"></div>
            </div>

            <h6 className="text mt-4">Basic Business Details</h6>

            <div className="row mt-4">
              <div className="row mt-3 mt-md-2">
                <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0"></div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6">
                <ArthaTextField
                  name="udyamAadhaar"
                  value={formData.udyamAadhaar}
                  label="Udyam Aadhaar"
                  placeholder="UDYAM-XX-XX-XXX720"
                  fullWidth
                  inputProps={{ maxLength: 19 }}
                  className={isUdyamVerified ? "verifiedBorder" : ""}
                  InputProps={{
                    endAdornment: isVerifyingUdyam ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <CircularProgress size={20} />
                        <Typography
                          variant="body2"
                          style={{ color: "grey", marginLeft: "4px" }}
                        >
                          Verifying
                        </Typography>
                      </div>
                    ) : isUdyamVerified ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <CheckCircleIcon style={{ color: "green" }} />
                        <Typography
                          variant="body2"
                          style={{ color: "green", marginLeft: "4px" }}
                        >
                          Verified
                        </Typography>
                      </div>
                    ): formData.udyamAadhaar && isUdyamInvalid ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <CancelIcon style={{ color: "grey" }} />
                        <Typography
                          variant="body2"
                          style={{ color: "grey", marginLeft: "4px" }}
                        >
                          Unverified
                        </Typography>
                      </div>
                    ) : null,
                  }}
                  onChange={handleChange}
                  error={Boolean(udyamError) || isUdyamInvalid}
                  helperText={
                    Boolean(udyamError)
                      ? udyamError
                      : isUdyamInvalid
                        ? "No record found for this Udyam number"
                        : ""
                  }
                  onBlur={handleBlur}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ fontSize: "12px" }}
                />
              </div>

              <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-2">
                <ArthaTextField
                  id="outlined-basic"
                  name="businessPan"
                  value={formData.businessPan}
                  onChange={handleChange}
                  label="Business PAN (Optional for Proprietary)"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  className="form-control"
                />
                <div
                  className={`pan-card-validation ${isValidBusinessPan ? "" : "invalid-input"
                    }`}
                >
                  {!isValidBusinessPan && (
                    <span style={{ color: "red", marginTop: "8px", display: "inline-block" }}>
                      Invalid Business Pan format. Please enter in the specified format.
                    </span>
                  )}
                </div>

              </div>
            </div>

            <div className="row mt-3 mt-md-4">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <ArthaTextField
                  name="businessName"
                  value={formData.businessName}
                  onChange={handleChange}
                  label="Business Name"
                  fullWidth
                  className="form-control custom-disabled-input"
                  disabled
                  inputProps={{ maxLength: 80 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {/* <p className="msg">As per PAN</p> */}
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <ArthaTextField
                  type="date"
                  name="commencementDate"
                  label="Date of Commencement"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formData.commencementDate}
                  onChange={handleChange}
                  inputProps={{
                    max: currentDate,
                  }}
                  fullWidth
                />
                {!isValidCommencementDate && (
                  <p className="msg" style={{ color: "red" }}>
                    Invalid date
                  </p>
                )}
              </div>
            </div>

            <div className="row mt-3 mt-md-2">
              <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-3">
                <ArthaTextField
                  name="currentBusinessPincode"
                  type="text"
                  maxLength="6"
                  value={formData.currentBusinessPincode}
                  onChange={handleChange}
                  label="Current Business Pincode"
                  id="pin-code-inpt"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={Boolean(pinCodeError)}
                  helperText={pinCodeError}
                  autoFocus
                />
              </div>
            </div>

            <div className="row mt-4 mx-auto">
              <div className="col-6 d-flex justify-content-end mt-4">
                <ArthaClearButton
                  variant="contained"
                  onClick={handleClear}
                // disabled={!isFormValid}
                // style={clearButtonStyle}
                >
                  Cancel
                </ArthaClearButton>
              </div>

              <div className="col-6 d-flex justify-content-start mt-4">
                <ArthaContinueButton
                  variant="contained"
                  onClick={handleContinue}
                  disabled={!isFormValid}
                  style={buttonStyle}
                >
                  {isLoading ? (
                    <LoadingSpinner style={{ pointerEvents: "none" }} />
                  ) : (
                    "Continue"
                  )}
                </ArthaContinueButton>
              </div>
            </div>
            <PinCodeValidation isOpen={isOpen} closeModal={closeModal} />
          </form>
        </>
      ) : (
        <EligibilityCheckForm
          onContinue={onSubmit}
          onCancel={() => setShowEligibilityCheckForm(false)}
        />
      )}
    </>
  );
};

export default PersonalDetailsForm;
