import React, { useEffect, useState, useRef } from "react";
import "./personalLoan.css";
import ArthaContinueButton from "../../fields/ArthaContinueButton.js";
import ArthaClearButton from "../../fields/ArthaClearButton.js";
import ArthaTextField from "../../fields/ArthaTextField1.js";
import EligibilityCheckForm from "./EligibilityCheckForm.jsx";
import { useNavigate } from "react-router-dom";
import { handleSignOut } from "./SessionExpiredApi.jsx";
import LoadingSpinner from "../../fields/spinner.jsx";
import ArthaFormControl from "../../fields/ArthaFormControl.js";
import ArthaInputLabel from "../../fields/ArthaInputLabel.js";
import {
    propertyLoanFetchDataAPI,
    propertyLoanStoreDetailsAPI,
    propertyLoanUserDetailsAPI,
    propertyLoanCreateLoanApplicationAPI,
    propertyLoanStorePropertyDetailsAPI,
    propertyLoanfetchDistStateAPI,
    propertyLoanFetchownerDetailsAPI,
    propertyLoanStorePropertyOwnersDetailsAPI,
    propertyLoanPropertySummaryAPI,
    propertyLoanPropertyBackAPI,
} from "../../actioncreators/actioncreators.js";
import { toast } from "react-toastify";
import { Select, MenuItem, Button, Dialog } from "@mui/material";
import PinCodeValidation from "./pinCodeValidation.jsx";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import UploadDeceasedDoc from "./UploadDeceasedDoc.jsx";
import { use } from "react";
import UploadPropertyDoc from "./UploadPropertyDoc.jsx";

const AddOwner = ({ onSubmit, onCancel, changeStatus, changeApplicantStatus }) => {
    const [selectedOwners, setSelectedOwners] = useState([]);

    const hasFetched = useRef(false);
    const [percentages, setPercentages] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const [ap_id, setApid] = useState("");
    const [response, setresponse] = useState("");
    const [loan_app_id, setLoanAppId] = useState("");
    const [propertyTypeDropdown, setPropertyTypeDropdown] = React.useState([
        "Flat/Apartment",
        "Bungalow",
        "Independent House",
        "Office Space",
        "Shop",
        "Chawl",
        "Commercial Complex",
        "Industrial Property (e.g., factory, warehouse)",
        "Residential Plot/NA Plot",
        "Row House",
        "Studio Apartment",
        "Penthouse",
        "Mixed-Use Property (Residential + Commercial)"
    ]);
    const [propertyJurisdictionDropdown, setPropertyJurisdictionDropdown] = React.useState([]);
    const [propertyAgeDropdown, setPropertyAgeDropdown] = React.useState([]);
    const [propertyOwnerDropdown, setPropertyOwnerDropdown] = React.useState([]);
    const [numOwners, setNumOwners] = useState(0);
    const [propertyOwnerNumberDropdown, setPropertyOwnerNumberDropdown] =
        React.useState([]);
    const [showEligibilityCheckForm, setShowEligibilityCheckForm] =
        useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [errorDisplayed, setErrorDisplayed] = useState(false);
    const errorDisplayedRef = useRef(false);
    const [pinCodeError, setPinCodeError] = useState("");
    const [pinCodeError1, setPinCodeError1] = useState("");
    const [pinCodeErrors, setPinCodeErrors] = useState([]);
    const [isAddressAdded, setAddressAdded] = React.useState(false);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [selectedDocs, setSelectedDocs] = useState({ document: [] });
    const [applicantNames, setApplicantNames] = useState([]);
    const [ownershipDetails, setOwnershipDetails] = useState([]);
    
    const goToSummaryPage = () => {
        changeApplicantStatus("PROPERTY_SUMMARY_DETAILS");
      };
    
      const [formData, setFormData] = useState({});
    
    const handleApiError = (error) => {
        if (errorDisplayedRef.current) return;

        setErrorDisplayed(true); // Set the state to true
        errorDisplayedRef.current = true; // Set the ref to true

        if (error.response && error.response.status === 401) {
            handleSignOut();
            toast.error(
                "Your session has expired. You will be redirected to the Login Page."
            );
            navigate("/");
        } else if (error.response && error.response.status === 429) {
            toast.error(
                "Too Many Requests: You have exceeded the rate limit. Please try again later."
            );
        } else {
            toast.error(
                "There appears to be a technical issue connecting to our servers. Could you please try again later."
            );
        }
        // Reset the error flag after a certain time
        setTimeout(() => {
            setErrorDisplayed(false);
            errorDisplayedRef.current = false;
        }, 2000); // 2 seconds
    };
    useEffect(() => {
        const fetchOwnerDetails = async () => {
            try {
                const response = await propertyLoanFetchownerDetailsAPI(); // Replace with actual API call
                console.log("API Response:", response);
                setresponse(response);
    
                if (response?.applicantNames?.length > 0) {
                    const numberOfOwners = Number(response.applicantNames[0].numberOfOwners || 0);
                    setNumOwners(numberOfOwners);
    
                    const ownershipDetails = response.propertyOwnershipDetails?.ownershipDetails || [];
                    const initialFormData = {};
    
                    // Ensure that ownershipDetails or applicantNames are handled even if partially empty
                    for (let i = 0; i < numberOfOwners; i++) {
                        const applicant = response.applicantNames[i] || {};
                        const detail = ownershipDetails[i] || {}; // Fallback for ownership details
    
                        initialFormData[`owner${i}`] = {
                            owner: applicant?.applicant_name || "", // Use fallback if name is missing
                            ownershipPercentage: detail?.ownership_percentage?.replace("%", "") || "",
                        };
                    }
    
                    setFormData(initialFormData);
                } else {
                    throw new Error("No applicant data found");
                }
            } catch (err) {
                console.error("Error fetching owner details:", err);
            }
        };
    
        fetchOwnerDetails();
    }, []);
    
    useEffect(() => {
        // Update formData when numOwners changes
        if (numOwners > 0) {
            setFormData((prevFormData) => {
                const updatedData = { ...prevFormData };
                for (let i = 0; i < numOwners; i++) {
                    if (!updatedData[`owner${i}`]) {
                        updatedData[`owner${i}`] = { owner: "", ownershipPercentage: "" };
                    }
                }
                return updatedData;
            });
        }
    }, [numOwners]);
    
    
    
    const handleOwnershipChange = (index, value) => {
        const updatedOwnershipDetails = [...ownershipDetails];
        updatedOwnershipDetails[index].ownership_percentage = value;
        setOwnershipDetails(updatedOwnershipDetails);
    };
    useEffect(() => {
        // Calculate total percentage
        // const totalPercentage = Object.values(formData).reduce((sum, owner) => {
        //     const percentage = parseFloat(owner?.ownershipPercentage || 0);
        //     return sum + (isNaN(percentage) ? 0 : percentage);
        // }, 0);
    
        // Check if all owners are selected
        const allOwnersSelected = Object.values(formData).every(
            (owner) => owner?.owner && owner.owner.trim() !== ""
        );
    
        // Check if form is valid
        const formIsValid =  allOwnersSelected;
    
        setIsFormValid(formIsValid);
    
        // Debug logs for troubleshooting
        // console.log("Total Percentage:", totalPercentage);
        console.log("All Owners Selected:", allOwnersSelected);
        console.log("Is Form Valid:", formIsValid);
    }, [formData]);
    
      
      // Run whenever the percentages change
     // Run whenever the percentages change
     const calculateTotalPercentage = (updatedFormData) => {
        // Extract ownership percentages and sum them up
        const totalPercentage = Object.values(updatedFormData).reduce((sum, owner) => {
            return sum + Number(owner.ownershipPercentage || 0);
        }, 0);
    
        console.log("Total Percentage:", totalPercentage); // Debug log
    
        // Update isFormValid state based on total percentage
        setIsFormValid(totalPercentage === 100);
    };
    
    const handleOwnerChange = (e, index) => {
        const { value } = e.target;
        const sanitizedValue = value.replace(/[^0-9]/g, "");
        setFormData((prevData) => ({
            ...prevData,
            [`owner${index}`]: {
                ...prevData[`owner${index}`],
                ownershipPercentage: sanitizedValue,
            },
        }));
    };
    
    
    
    
    
    
      const handlePercentageChange = (applicantId, value) => {
        setPercentages((prev) => ({
          ...prev,
          [applicantId]: value,
        }));
      };
      
    
    const fetchAddressDetails = async (pincode) => {
        try {
            let data = {
                pinCode: pincode,
            };
            const response = await propertyLoanfetchDistStateAPI(data);
            const { district, stateName } = response.data;
            setCurrentAddress((prevState) => ({
                ...prevState,
                city: district || "",
                state: stateName || "",
            }));
        } catch (error) {
            console.error("Error fetching address details:", error);
            setCurrentAddress((prevState) => ({
                ...prevState,
                city: "",
                state: "",
            }));
        }
    };

    useEffect(() => {
        const fetchDropDownData = async () => {
            const apiPayloads = [
                { source: "json_value", type: "LAP_LOAN", key: "PROPERTY_TYPE", dropdownType: "PROPERTY_TYPE" },
                { source: "json_value", type: "LAP_LOAN", key: "PROPERTY_OWNEDBY", dropdownType: "PROPERTY_OWNEDBY" },
                { source: "json_value", type: "LAP_LOAN", key: "NUMBER_OF_OWNERS", dropdownType: "NUMBER_OF_OWNERS" },
                { source: "json_value", type: "LAP_LOAN", key: "PROPERTY_AGE", dropdownType: "PROPERTY_AGE" },
                { source: "json_value", type: "LOAN_AGAINST_PROPERTY", key: "PROPERTY_JURISDICTION", dropdownType: "PROPERTY_JURISDICTION" },
            ];

            // Fetch each dropdown value
            for (const payload of apiPayloads) {
                await fetchDropDownValues(payload, payload.dropdownType);
            }
        };
        fetchDropDownData();
    }, []);

    // useEffect(() => {
    //     const fetchData = async () => {
    //       try {
    //         // const response = await propertyLoanFetchUserDetails();
    //         console.log("response", response);

    //         const {
    //           mobile,
    //           pan,
    //           fullName,
    //           email,
    //           dob,
    //           pinCode,
    //           businessId,
    //           dateOfDeath,
    //           commencementDate,
    //           businessPan,
    //           businessName,
    //           udyamAadhaar,
    //           documents,
    //         } = response.data;

    //         setFormData((prevData) => ({
    //           ...prevData,
    //           panCardNo: pan,
    //           mobileNo: mobile,
    //           fullName: fullName,
    //           dateOfBirth: formatDate(dob),
    //           emailId: email,
    //           pinCode: pinCode,
    //           businessId: businessId,
    //           commencementDate: formatDate(commencementDate),
    //           dateOfDeath: formatDate(dateOfDeath),
    //           // selectedDocs:documents,
    //           documents: documents,
    //           businessPan: businessPan,
    //           businessName: businessName,
    //           udyamAadhaar: udyamAadhaar,
    //         }));
    //         // setBusinessId(businessId);
    //         setSelectedDocs({ document: response?.data?.documents });
    //       } catch (error) {
    //         handleApiError(error);
    //         console.error("Error fetching user data:", error);
    //       }
    //     };
    //     fetchData();
    //   }, [ap_id, loan_app_id]);

    // useEffect(() => {
    //     if (!hasFetched.current) {
    //         hasFetched.current = true; // Set flag to true to prevent subsequent calls

    //         const fetchData1 = async () => {
    //             try {
    //                 // First API call: Create Loan Application
    //                 const response = await propertyLoanCreateLoanApplicationAPI();
    //                 if (response?.businessStatusCode === 2) {
    //                     setApid(response?.ap_id || "");
    //                     setLoanAppId(response?.loan_app_id || "");
    //                 }
    //                 const lapLoanApplicationStatus = response?.status || "";

    //                 // Second API call: Fetch user details
    //                 const fetchData = async () => {
    //                     try {
    //                         const response1 = await propertyLoanUserDetailsAPI();
    //                         console.log("response1", response1);

    //                         setFormData(() => ({
    //                             fullName: response1?.fullName || "", // Fallback to empty string if undefined
    //                             currentAddressPincode: response1?.pinCode || "", // Fallback to empty string if undefined
    //                         }));

    //                         // Check if the property array is valid and set form data accordingly
    //                         if (Array.isArray(response1?.property) && response1?.property.length > 0) {
    //                             setPropertyForms(response1.property);
    //                         } else {
    //                             // Default form in case the property array is empty or undefined
    //                             setPropertyForms([{
    //                                 propertyID: "",
    //                                 fullName: "",
    //                                 currentAddressPincode: "",
    //                                 propertyType: "",
    //                                 propertyJurisdiction: "",
    //                                 propertyOwnership: "",
    //                                 builtupType: "",
    //                                 stageOfConstruction: "",
    //                                 expectedDateOfPossession: "",
    //                                 percentageOfWorkCompleted: "",
    //                                 ownershipPeriod: "",
    //                                 plotArea: "",
    //                                 builtUpArea: "",
    //                                 carpetArea: "",
    //                                 propertyPin: "",
    //                                 numberOfOwners: "",
    //                                 propertyAge: "",
    //                                 propertyValuation: "",
    //                             }]);
    //                         }
    //                     } catch (error) {
    //                         handleApiError(error);
    //                         console.error("Error fetching user data:", error);
    //                     }
    //                 };

    //                 // Fetch user details after loan application status
    //                 await fetchData();

    //                 // Fetch dropdown values after fetching user details
    //                 const fetchDropDownData = async () => {
    //                     const apiPayloads = [
    //                         { source: "json_value", type: "LAP_LOAN", key: "PROPERTY_TYPE", dropdownType: "PROPERTY_TYPE" },
    //                         { source: "json_value", type: "LAP_LOAN", key: "PROPERTY_OWNEDBY", dropdownType: "PROPERTY_OWNEDBY" },
    //                         { source: "json_value", type: "LAP_LOAN", key: "NUMBER_OF_OWNERS", dropdownType: "NUMBER_OF_OWNERS" },
    //                         { source: "json_value", type: "LAP_LOAN", key: "PROPERTY_AGE", dropdownType: "PROPERTY_AGE" },
    //                         { source: "json_value", type: "LOAN_AGAINST_PROPERTY", key: "PROPERTY_JURISDICTION", dropdownType: "PROPERTY_JURISDICTION" },
    //                     ];

    //                     // Fetch each dropdown value
    //                     for (const payload of apiPayloads) {
    //                         await fetchDropDownValues(payload, payload.dropdownType);
    //                     }
    //                 };

    //                 await fetchDropDownData();

    //                 // Navigate after fetching data
    //                 navigate("/home/loanagainstproperty", {
    //                     state: {
    //                         lapLoanApplicationStatus,
    //                     },
    //                 });
    //             } catch (error) {
    //                 handleApiError(error);
    //                 console.error("Error creating loan application:", error);
    //             }
    //         };

    //         fetchData1();
    //     }
    // }, [navigate, ap_id, loan_app_id]); // Added 'navigate' as a dependency

    const fetchDropDownValues = async (apiPayload, dropdownType) => {
        try {
            const response = await propertyLoanFetchDataAPI(apiPayload);
            const data = response?.data?.[0] || {}; // Safely accessing the response

            // Handling different dropdown values based on type
            if (dropdownType === "PROPERTY_JURISDICTION") {
                setPropertyJurisdictionDropdown(data.List || []); // Fallback to empty array if undefined
                // } else if (dropdownType === "PROPERTY_TYPE") {
                //     setPropertyTypeDropdown(data.PropertyType || []); // Fallback to empty array if undefined
            } else if (dropdownType === "PROPERTY_OWNEDBY") {
                setPropertyOwnerDropdown(data.PropertyOwnedBy || []); // Fallback to empty array if undefined
            } else if (dropdownType === "NUMBER_OF_OWNERS") {
                setPropertyOwnerNumberDropdown(data.NumberOfOwners || []); // Fallback to empty array if undefined
            } else if (dropdownType === "PROPERTY_AGE") {
                setPropertyAgeDropdown(data.PropertyAge || []); // Fallback to empty array if undefined
            }
        } catch (error) {
            handleApiError(error);
            console.error("Error fetching dropdown data:", error);
        }
    };



    // Fetching dropdown Values




    // const validateForm = () => {
    //   const {
    //     fullName,
    //     currentAddressPincode,
    //     propertyType,
    //     propertyJurisdiction,
    //     numberOfOwners,
    //     propertyAge,
    //     propertyOwnership,
    //     propertyPin,
    //     propertyValuation,
    //   } = formData;

    //   const isValid =
    //     fullName !== "" &&
    //     currentAddressPincode !== "" &&
    //     propertyType !== "" &&
    //     propertyJurisdiction !== "" &&
    //     numberOfOwners !== "" &&
    //     propertyAge !== "" &&
    //     propertyOwnership !== "" &&
    //     propertyPin !== "" &&
    //     propertyValuation !== "";

    //   setIsFormValid(isValid);
    // };


    const [currentAddress, setCurrentAddress] = React.useState({
        'line1': '',
        'line2': '',
        'pincode': '',
        'city': '',
        'state': ''
    });

    const [openAddressDialog, setOpenAddressDialog] = useState(false);
    const openAddrDialog = () => {
        setOpenAddressDialog(true);
        setPincodeError('')
    }
    const closeAddrDialog = () => {
        if (isAddressAdded) {
            setOpenAddressDialog(false);
        } else {
            setOpenAddressDialog(false);
            setCurrentAddress({
                'line1': '',
                'line2': '',
                'pincode': '',
                'city': '',
                'state': ''
            })
        }
    }

    const [pincodeError, setPincodeError] = React.useState('');
    const handleAddressChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name == 'pincode' && value == '000000') {
            setPincodeError('Please enter a valid 6-digit pincode')
        }
        setCurrentAddress(prevAddress => ({
            ...prevAddress,
            [name]: value
        }));
        checkFormFilled();
    }

    const isPincodeValid = (pincode) => /^\d{6}$/.test(pincode);
    const isCityValid = (city) => /^[a-zA-Z\s]+$/.test(city);
    const isStateValid = (state) => /^[a-zA-Z\s]+$/.test(state);
    const [isFormFilled, setIsFormFilled] = useState(false);
    const checkFormFilled = () => {
        if (!currentAddress) return;

        const { line1, line2, pincode, city, state } = currentAddress;

        const isLine1Filled = line1 && line1.trim() !== '' && /^[a-zA-Z0-9,\s]+$/.test(line1);
        const isLine2Filled = line2 && line2.trim() !== '' && /^[a-zA-Z0-9,\s]+$/.test(line2);
        const isPincodeFilled = pincode && pincode.trim() !== '' && /^\d{6}$/.test(pincode); // Assuming pincode is 6 digits
        const isCityFilled = city && city.trim() !== '' && /^[a-zA-Z\s]+$/.test(city);
        const isStateFilled = state && state.trim() !== '' && /^[a-zA-Z\s]+$/.test(state);

        let isPincodeValidated = isPincodeFilled && isPincodeValid(pincode);
        if (pincode == '000000') {
            isPincodeValidated = false;
        }
        if (isPincodeValidated) {
            setPincodeError('');
        }
        const isCityValidated = isCityFilled && isCityValid(city);
        const isStateValidated = isStateFilled && isStateValid(state);

        if (
            isLine1Filled &&
            isLine2Filled &&
            isPincodeFilled &&
            isCityFilled &&
            isStateFilled &&
            isPincodeValidated &&
            isCityValidated &&
            isStateValidated
        ) {
            setIsFormFilled(true);
        } else {
            setIsFormFilled(false);
        }

    };

    const handleFileChangeID = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setFormData({
                    ...formData,
                    registrationCertificateDoc: {
                        ...formData.registrationCertificateDoc,
                        documentData: e.target.result.split(",")[1], // Assuming base64 data
                        documentName: file.name,
                        documentType: file.type,
                    },
                });
            };
            reader.readAsDataURL(file);
        }
    };

    const removeExistingDoc = (documentId) => {
        const [documentType] = documentId.split('-');
        setSelectedDocs(prev => ({
            ...prev,
            document: prev.document.filter(doc => doc.documentType !== documentType)
        }));
    };

    const handleUploadDocument = () => {
        setPopupOpen(true);
    };

    const handlePopupClose = (selectedFileNames) => {
        // If selectedFileNames is empty array, it means all documents were removed
        if (selectedFileNames.length === 0) {
            setSelectedDocs(prev => ({
                ...prev,
                // document: []
            }));
        } else {
            // Update with new document list
            setSelectedDocs(prev => ({
                ...prev,
                document: selectedFileNames
            }));
        }
        setPopupOpen(false);
    };

    useEffect(() => {
        checkFormFilled();
    }, [formData, currentAddress]);

    const handleClear = async () => {
        try {
          const response = await propertyLoanPropertyBackAPI();
          if (response?.data?.businessStatusCode === 2) {
            // changeApplicantStatus(response?.data?.nextPage);
            changeStatus("PROPERTY_SUMMARY_DETAILS")
            changeApplicantStatus(response?.data?.previous_page);
          } else {
            toast.info(response.data.message);
          }
        } catch (error) {
          handleApiError(error);
          console.error("Error fetching loan data:", error);
        }
      };

    const handleAddApplicant = () => {
        // changeApplicantStatus("");
        changeStatus("APPLICANT_DETAILS");
      };

    useEffect(() => {
        const pinCodeInput = document.getElementById("pin-code-inpt");
        if (pinCodeInput) {
            pinCodeInput.focus();
        }
    }, []);



    const closeModal = () => {
        setIsOpen(false);
        let inpt = document.getElementById("pin-code-inpt");
        inpt.focus();
    };

    const [propertyForms, setPropertyForms] = useState([
        {
            propertyID: "",
            fullName: "",
            currentAddressPincode: "",
            propertyType: "",
            propertyJurisdiction: "",
            propertyOwnership: "",
            builtupType: "",
            stageOfConstruction: "",
            expectedDateOfPossession: "",
            percentageOfWorkCompleted: "",
            ownershipPeriod: "",
            plotArea: "",
            builtUpArea: "",
            carpetArea: "",
            propertyPin: "",
            numberOfOwners: "",
            propertyAge: "",
            propertyValuation: "",
        },
    ]);
    // const handleChange = (e, index) => {
    //     const { name, value } = e.target;
    //     const numericValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters

    //     if (Number(numericValue) > 100 || (numOwners > 1 && numericValue.length > 2)) {
    //       // Prevent 100 or three-digit numbers for more than one owner
    //       return;
    //     }
      
    //     setFormData((prevFormData) => ({
    //         ...prevFormData,
    //         [`owner${index}`]: {
    //             ...prevFormData[`owner${index}`],
    //             [name]: value,
    //         },
    //     }));
    // };
    const handleChange = (e, index) => {
        const { name, value } = e.target;
    
        // Allow only digits and a single dot
        let numericValue = value.replace(/[^0-9.]/g, "");

        // Prevent multiple dots in the value
        if ((numericValue.match(/\./g) || []).length > 1) {
            return;
        }
    
        // Prevent the input '100.' (trailing dot after 100)
        if (numericValue === '100.') {
            return;
        }
    
        // For single owner (numOwners === 1), restrict the input to three digits
        if (numOwners === 1 && numericValue.length > 3 && !numericValue.includes(".")) {
            return;
        }
    
        // For multiple owners (numOwners > 1), disallow '100' as a valid input
        if (numOwners > 1 && numericValue === "100") {
            return;
        }
    
        // Restrict percentage to a valid number range 0-100 with at most two decimals
        if (
            !/^(100(\.0{0,2})?|[0-9]{1,2}(\.[0-9]{0,2})?)$/.test(numericValue) &&
            numericValue !== ""
        ) {
            return;
        }
    
    
    
        // Update state
        setFormData((prevFormData) => ({
            ...prevFormData,
            [`owner${index}`]: {
                ...prevFormData[`owner${index}`],
                [name]: numericValue, // Update with sanitized value
            },
        }));
    };
    
    const getFilteredOptions = (index) => {
        const selectedOwners = Object.values(formData)
            .map((data) => data.owner)
            .filter((owner) => owner !== ""); // Exclude empty selections
    
        return response.applicantNames.filter(
            (item) => !selectedOwners.includes(item.applicant_name) || formData[`owner${index}`]?.owner === item.applicant_name
        );
    };    

    
    
    
    
    
    
    console.log('Selected Owners:', selectedOwners);
    console.log('Form Data:', formData);
    

    const handleDateChange = (date, name) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: date,
        }));
    };

    const handleChange1 = (e) => {
        const { name, value } = e.target;
        let updatedValue = value;
        if (name === "currentAddressPincode") {
            updatedValue = updatedValue.replace(/\D/g, "").slice(0, 6);
            const isPinCodeValid1 = /^\d{6}$/.test(updatedValue);
            if (!isPinCodeValid1) {
                setPinCodeError1("Pincode must be 6 digits");
            } else {
                setPinCodeError1("");
            }
        }

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    // useEffect(() => {
    //     validateForm();
    // }, [formData, propertyForms]);

    // const validateForm = () => {
    //     const { fullName, currentAddressPincode } = formData;

    //     // Basic validation for the primary form
    //     const isMainFormValid = fullName !== "" && currentAddressPincode !== "";

    //     // Validate each property form
    //     const areAllPropertiesValid = propertyForms?.every((form) => {
    //         const {
    //             propertyType,
    //             propertyJurisdiction,
    //             numberOfOwners,
    //             propertyAge,
    //             propertyOwnership,
    //             builtupType,
    //             plotArea,
    //             propertyPin,
    //             propertyValuation,
    //         } = form;

    //         // Check if propertyPin contains exactly 6 digits
    //         const isPinValid = /^\d{6}$/.test(propertyPin) && propertyPin !== "000000" && !propertyPin.startsWith("0");

    //         return (
    //             propertyType !== "" &&
    //             propertyJurisdiction !== "" &&
    //             // numberOfOwners !== "" &&
    //             propertyAge !== "" &&
    //             propertyOwnership !== "" &&
    //             builtupType !== "" &&

    //             propertyPin !== "" &&
    //             isPinValid && // Ensure pincode is valid
    //             propertyValuation !== ""
    //         );
    //     });

    //     // Set the form validity based on both the main form and all properties
    //     setIsFormValid(isMainFormValid && areAllPropertiesValid);
    // };

    const handleRemoveProperty = (index) => {
        const updatedForms = propertyForms.filter((_, i) => i !== index);
        setPropertyForms(updatedForms);
    };

    // const handleContinue = async () => {
    //     setIsButtonClicked(true);
    //     setIsLoading(true);
    
    //     // Construct the ownership details array from the formData state
    //     const ownershipDetails = Object.entries(formData).map(([ownerKey, ownerData]) => {
    //         const applicantName = ownerData.owner;  // The selected owner's name
    //         const ownershipPercentage = `${ownerData.ownershipPercentage}%`;  // Add percentage sign
    
    //         // Find the applicant ID based on the selected name (assume applicantNames is available)
    //         const applicantId = response?.data?.applicantNames?.find(item => item.applicant_name === applicantName)?.applicant_id;
    
    //         // Return the object structure for the payload
    //         return {
    //             // applicant_id: applicantId,  // applicant ID
    //             owner_name: applicantName,  // Add owner name to the payload
    //             ownership_percentage: ownershipPercentage,  // Ownership percentage
    //         };
    //     });
    
    //     // Construct the payload data structure
    //     const data = {
    //         propertyOwnershipDetails: {
    //             ownershipDetails,  // Add the ownership details array to the payload
    //         },
    //     };
    
    //     console.log(data);  // Log the data to see the structure
    
    //     try {
    //         // Call the API with the payload data
    //         const response = await propertyLoanStorePropertyOwnersDetailsAPI(data);
    //         if (response.businessStatusCode === 2) {
    //             console.log("nextpg", response.nextPage);
    //             // changeApplicantStatus("PROPERTY_DETAILS")

    //             // changeStatus(response.nextPage);
    //                    changeStatus("PROPERTY_DETAILS")
    //     changeApplicantStatus(response.nextPage);
    //                 toast.success("Applicant Details Added Successfully");
    //             }
    //         // Handle the API response (You can uncomment these logic blocks as per the response structure)
    //         // if (response.businessStatusCode === 2) {
    //         //     changeStatus("ELIGIBILITY_DETAILS");
    //         //     toast.success("Applicant Details Added Successfully");
    //         // } else if (
    //         //     response.businessStatusCode === 1 &&
    //         //     response.businessStatusSubCode === 1.1
    //         // ) {
    //         //     toast.info(response.message);
    //         // } else if (
    //         //     response.businessStatusCode === 1 &&
    //         //     response.businessStatusSubCode === 1.3
    //         // ) {
    //         //     toast.warning(response.message);
    //         //     navigate("/home");
    //         // } else {
    //         //     setShowEligibilityCheckForm(false);
    //         //     setIsOpen(true);
    //         // }
    
    //     } catch (error) {
    //         handleApiError(error);
    //         console.error("Error fetching loan data:", error);
    //     } finally {
    //         setIsLoading(false);
    //         setIsButtonClicked(false);
    //     }
    // };
    const handleContinue = async () => {
        setIsButtonClicked(true);
        setIsLoading(true);
        
        
    
        
        console.log("Form Data before validation:", formData);

        // Validate that all owners and percentages are filled
        const isAllFieldsFilled = Object.values(formData).every(
            (data) => data?.owner?.trim() && data?.ownershipPercentage?.trim()
        );
        if (!isAllFieldsFilled) {
            toast.error("Please Select All Owners  before proceeding.");
            setIsLoading(false);
            setIsButtonClicked(false);
            return;
        }
        // Calculate the total ownership percentage
        const totalPercentage = Object.entries(formData).reduce((total, [key, value]) => {
            const percentage = parseFloat(value.ownershipPercentage) || 0;
            return total + percentage;
        }, 0);
    
        // Check if the total percentage equals 100
        if (totalPercentage !== 100) {
            toast.error("Total Ownership Percentage For All Owners Must Be Equal To 100%");
            setIsLoading(false);
            setIsButtonClicked(false);
            return;
        }
    
        // Construct the ownership details array from the formData state
        const ownershipDetails = Object.entries(formData).map(([ownerKey, ownerData]) => {
            const applicantName = ownerData.owner; // The selected owner's name
            const ownershipPercentage = `${ownerData.ownershipPercentage}%`; // Add percentage sign
    
            // Find the applicant ID based on the selected name (assume applicantNames is available)
            const applicantId = response?.data?.applicantNames?.find(
                (item) => item.applicant_name === applicantName
            )?.applicant_id;
    
            // Return the object structure for the payload
            return {
                applicant_id: applicantId, // applicant ID
                owner_name: applicantName, // Add owner name to the payload
                ownership_percentage: ownershipPercentage, // Ownership percentage
            };
        });
    
        // Construct the payload data structure
        const data = {
            propertyOwnershipDetails: {
                ownershipDetails, // Add the ownership details array to the payload
            },
        };
    
        console.log(data); // Log the data to see the structure
    
        try {
            // Call the API with the payload data
            const response = await propertyLoanStorePropertyOwnersDetailsAPI(data);
            if (response.businessStatusCode === 2) {
                changeStatus("PROPERTY_SUMMARY_DETAILS");
                changeApplicantStatus(response.nextPage);
                toast.success("Applicant Details Added Successfully");
            }
        } catch (error) {
            handleApiError(error);
            console.error("Error fetching loan data:", error);
        } finally {
            setIsLoading(false);
            setIsButtonClicked(false);
        }
    };
    
    
      
    return (
        <>
            {!showEligibilityCheckForm ? (
                <>
                    {/* <h6 className="text mt-4">Initial Applicant DetailsHere</h6>

          <form
            className="mt-4 border border-dark p-3"
            style={{ borderRadius: "10px" }}
          >
            <div className="row mt-2 ">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <ArthaTextField
                  id="outlined-basic"
                  name="fullName"
                  value={formData.fullName || ""}
                  onChange={(e) => handleChange1(e)}
                  label="Full Name"
                  fullWidth
                  variant="outlined"
                  disabled
                  className="form-control fs-5 custom-disabled-input"
                />
                <span className="msg">As per PAN</span>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <ArthaTextField
                  name="currentAddressPincode"
                  type="text"
                  maxLength="6"
                  value={formData.currentAddressPincode || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value) && value.length <= 6) {
                      handleChange1(e);
                    }
                  }}
                  label="Pincode of your current address"
                  id="pin-code-inpt"
                  fullWidth
                  inputMode="numeric"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={Boolean(pinCodeError1)}
                  helperText={pinCodeError1}
                  autoFocus
                />
              </div>
            </div>
          </form> */}

                  
                    {/* <div className="row mt-4" style={{ "border": "1px solid #a39898", padding: "10px", borderRadius: "10px", paddingBottom: "40px" }}>
                        <div className="row mt-4">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                                <ArthaFormControl fullWidth>
                                    <ArthaInputLabel id="selectOwner-label">
                                        Select Owner
                                    </ArthaInputLabel>
                                    <Select
                                        labelId="selectOwner-label"
                                        id="owner"
                                        name="owner"
                                        value={formData.owner || ""}
                                        onChange={handleChange}
                                    >
                                        {propertyJurisdictionDropdown?.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </ArthaFormControl>
                            </div>

                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                                <ArthaTextField
                                    name="ownershipPercentage"
                                    type="text"
                                    value={formData.ownershipPercentage || ""}
                                    onChange={handleChange}
                                    label="Ownership Percentage"
                                    id="ownershipPercentage"
                                    fullWidth
                                    inputMode="numeric"
                                />
                            </div>

                        </div>

                        <div className="row mt-4">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                                <ArthaFormControl fullWidth>
                                    <ArthaInputLabel id="selectOwner-label">
                                        Select Owner
                                    </ArthaInputLabel>
                                    <Select
                                        labelId="selectOwner-label"
                                        id="owner"
                                        name="owner"
                                        value={formData.owner || ""}
                                        onChange={handleChange}
                                    >
                                        {propertyJurisdictionDropdown?.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </ArthaFormControl>
                            </div>

                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                                <ArthaTextField
                                    name="ownershipPercentage"
                                    type="text"
                                    value={formData.ownershipPercentage || ""}
                                    onChange={handleChange}
                                    label="Ownership Percentage"
                                    id="ownershipPercentage"
                                    fullWidth
                                    inputMode="numeric"
                                />
                            </div>

                        </div>

                        <div className="row mt-4">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                                <ArthaFormControl fullWidth>
                                    <ArthaInputLabel id="selectOwner-label">
                                        Select Owner
                                    </ArthaInputLabel>
                                    <Select
                                        labelId="selectOwner-label"
                                        id="owner"
                                        name="owner"
                                        value={formData.owner || ""}
                                        onChange={handleChange}
                                    >
                                        {propertyJurisdictionDropdown?.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </ArthaFormControl>
                            </div>

                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                                <ArthaTextField
                                    name="ownershipPercentage"
                                    type="text"
                                    value={formData.ownershipPercentage || ""}
                                    onChange={handleChange}
                                    label="Ownership Percentage"
                                    id="ownershipPercentage"
                                    fullWidth
                                    inputMode="numeric"
                                />
                            </div>

                        </div>

                    </div> */}
                    <div className="d-flex">
  <h6 className="text mt-4">Property Ownership</h6>
  <div
          onClick={goToSummaryPage}
          className="text fw-bold"
          style={{
            color: "orange",
            marginLeft: "auto",
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          Go To Property Summary
        </div>
        </div>
  <div className="row mt-4" style={{ border: "1px solid #a39898", padding: "10px", borderRadius: "10px", paddingBottom: "40px" }}>
    {/* Loop through the number of owners */}

   

    {numOwners > 0 && Object.keys(formData).length > 0 ? (
            [...Array(numOwners)].map((_, index) => (
                <div className="row mt-4" key={index}>
                    {/* Owner Dropdown */}
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                        <ArthaFormControl fullWidth>
                            <ArthaInputLabel id={`selectOwner-label-${index}`}>
                                Select Owner
                            </ArthaInputLabel>
                            {response?.applicantNames?.length > 0 ? (
                                <Select
                                    labelId={`selectOwner-label-${index}`}
                                    id={`owner-${index}`}
                                    name="owner"
                                    value={formData[`owner${index}`]?.owner || ""}
                                    onChange={(e) => handleChange(e, index)}
                                >
                                    {getFilteredOptions(index).map((item) => (
                                        <MenuItem
                                            key={item.applicant_id}
                                            value={item.applicant_name}
                                        >
                                            {item.applicant_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            ) : (
                                <div>Loading...</div>
                            )}
                        </ArthaFormControl>
                    </div>

                    {/* Ownership Percentage */}
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                        <ArthaTextField
                            name="ownershipPercentage"
                            type="text"
                            value={`${formData[`owner${index}`]?.ownershipPercentage || ""}`}
                            onChange={(e) => handleChange(e, index)}
                            label="Ownership Percentage"
                            id={`ownershipPercentage-${index}`}
                            fullWidth
                            inputMode="numeric"
                            InputProps={{
                                endAdornment: <span>%</span>,
                            }}
                        />
                    </div>
                </div>
            ))
        ) : (
            <div>Loading...</div>
        )}

</div>








                    {/* <div className="col-12 mt-4">
                        <ArthaClearButton variant="contained" onClick={handleAddProperty}>
                            Add Property
                        </ArthaClearButton>
                    </div> */}



                    <div className="row mt-5">
                        <div className="col-12 d-flex justify-content-center gap-5">

                            <ArthaClearButton variant="contained" onClick={handleClear} style={{marginRight:"80px"}}>
                                Back
                            </ArthaClearButton>

                            <ArthaClearButton variant="contained" onClick={handleAddApplicant} style={{marginRight:"80px"}}>
                                Add Applicant
                            </ArthaClearButton>

                            <ArthaContinueButton
    variant="contained"
    onClick={handleContinue}
    // disabled={!isFormValid }
    style={{
        backgroundColor: isFormValid ? "rgba(255, 115, 0, 1)" : "rgb(255 98 0 / 39%)",
        opacity: isButtonClicked ? 0.39 : 1,
        pointerEvents: isButtonClicked ? "none" : "auto",
    }}
>
    {isLoading ? (
        <LoadingSpinner style={{ pointerEvents: "none" }} />
    ) : (
        "Continue"
    )}
</ArthaContinueButton>

                        </div>
                    </div>
                </>
            ) : (
                <EligibilityCheckForm
                    onCancel={() => setShowEligibilityCheckForm(false)}
                />
            )
            }
        </>
    );
};

export default AddOwner;
