

import React, { useState, useRef, useEffect } from "react";
import { MdOutlineFileUpload } from "react-icons/md";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import ArthaClearButton from "../../fields/ArthaClearButton";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import Cancel from "./images/Cancel_1.png";
import ArthaFormControl from "../../fields/ArthaFormControl";
import { Button, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FaExclamationCircle } from "react-icons/fa";
import AddBank from "./addBank";
import ArthaTextField from "../../fields/ArthaTextField";
import axios from "axios";
import dayjs from 'dayjs';
import { verifyGSTN, verifyUdyamNumber } from "../../actioncreators/actioncreators";
import { toast } from 'react-toastify';
import { handleSignOut } from "./SessionExpiredApi";
import { useNavigate } from 'react-router-dom';
import UploadSupportiveDocs from "./uploadSupportiveDocs";
import LoadingSpinner from "../../fields/spinner";

const PreviousEmployer = ({ worksince, firmVintageOwnerDropdown, previousEmployeer, setPreviousEmployeer, firmVintageDropdown, previousAddress, setPreviousAddress, onPrevBankDetailsSave, prevBankDetails, uidprops, setSelectedPrevDocs }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [gstnVerified, setGstnVerified] = React.useState(false);
    const [udyamAdharNumberVerified, setUdyamAdharNumberVerified] = React.useState(false);
    const [shopActNoVerified, setShopActNoVerified] = React.useState(false);
    const [isEmailAdded, setEmailAdded] = React.useState(false);
    const [registrationDocument, setRegistrationDocument] = React.useState({});
    const [isOpenEmailDialog, setOpenEmailDialog] = useState(false);
    const [emailTouched, setEmailTouched] = useState(false);

    const navigate = useNavigate();
    const handleVerification = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        console.log(name, value);
        if (name == 'gstNumber') {
            if (value == '') {
                setGstnVerified(false);
            } else {

                let response = verifyGSTN({
                    "gst_number": value,
                    "business_id": "77557755"
                })
                if (response.httpResponseCode == "200" && response.message == "GST Verification Successful") {
                    setGstnVerified(true);
                } else {
                    setGstnVerified(false);
                }
            }
        } else if (name == 'udyamAadhaar') {
            if (value == '') {
                setUdyamAdharNumberVerified(false);
            } else {
                let response = verifyUdyamNumber({
                    "udyam_number": value,
                    "business_id": "77557755"
                })
                if (response.httpResponseCode == "200" && response.message == "Udyam Verification Successful") {
                    setUdyamAdharNumberVerified(true);
                } else {
                    setUdyamAdharNumberVerified(false);
                }

            }
        } else if (name == 'shopActNumber') {
            if (value == '') {
                setShopActNoVerified(false);
            } else {
                //CALL API
                setShopActNoVerified(true);
            }
        }
        //setVerified( true );
    };
    const handleWorkingSince = (e) => {
        setPreviousEmployeer({
            ...previousEmployeer,
            workfrom: e,
        })
    }
    const handleWorkingTill = (e) => {
        setPreviousEmployeer({
            ...previousEmployeer,
            worktill: e,
        })
    }

    //address dialog code started


    const [openAddressDialog, setOpenAddressDialog] = useState(false);
    const [isAddressAdded, setAddressAdded] = React.useState(false);
    const [pincodeError, setPincodeError] = React.useState('');

    useEffect(() => {
        if (previousAddress) {
            setAddressAdded(true);
        }

    }, [previousAddress])

    useEffect(() => {
        if (previousEmployeer) {
            if (previousEmployeer.officialEmail != "") {
                setEmailAdded(true);
            }
        }
    }, [previousEmployeer])

    const openAddrDialog = () => {
        setOpenAddressDialog(true);
        setPincodeError('');
    }
    const isPincodeValid = (pincode) => /^\d{6}$/.test(pincode);
    const isCityValid = (city) => /^[a-zA-Z\s]+$/.test(city);
    const isStateValid = (state) => /^[a-zA-Z\s]+$/.test(state);
    const [isFormFilled, setIsFormFilled] = useState(false);
    const handleAddressChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name == 'pincode' && value == '000000') {
            setPincodeError('Please enter a valid 6-digit pincode')
        }
        setPreviousAddress(prevAddress => ({
            ...prevAddress,
            [name]: value
        }));
        checkFormFilled();
    }

    const checkFormFilled = () => {
        if (!previousAddress) return;

        const { line1, line2, pincode, city, state } = previousAddress;

        const isLine1Filled = line1 && line1.trim() !== '' && /^[a-zA-Z0-9\s]+$/.test(line1);
        const isLine2Filled = line2 && line2.trim() !== '' && /^[a-zA-Z0-9\s]+$/.test(line2);
        const isPincodeFilled = pincode && pincode.trim() !== '' && /^\d{6}$/.test(pincode); // Assuming pincode is 6 digits
        const isCityFilled = city && city.trim() !== '' && /^[a-zA-Z\s]+$/.test(city);
        const isStateFilled = state && state.trim() !== '' && /^[a-zA-Z\s]+$/.test(state);

        let isPincodeValidated = isPincodeFilled && isPincodeValid(pincode);
        if (pincode == '000000') {
            isPincodeValidated = false;
        }
        if (isPincodeValidated) {
            setPincodeError('');
        }
        const isCityValidated = isCityFilled && isCityValid(city);
        const isStateValidated = isStateFilled && isStateValid(state);

        if (
            isLine1Filled &&
            isLine2Filled &&
            isPincodeFilled &&
            isCityFilled &&
            isStateFilled &&
            isPincodeValidated &&
            isCityValidated &&
            isStateValidated
        ) {
            setIsFormFilled(true);
        } else {
            setIsFormFilled(false);
        }

    };

    useEffect(() => {
        checkFormFilled();
    }, [previousAddress]);

    const closeAddrDialog = () => {
        if (isAddressAdded) {
            setOpenAddressDialog(false);
        } else {
            setOpenAddressDialog(false);
            setPreviousAddress({
                'line1': '',
                'line2': '',
                'pincode': '',
                'city': '',
                'state': ''
            })
        }
    }

    //address dialog ended

    //add bank dialog
    const [openBankDialog, setOpenBankDialog] = React.useState(false);
    const [bankDetails, setBankDetails] = React.useState()
    const cancelBankDialog = () => {
        setOpenBankDialog(false);
    }

    const onBankDataSave = (data) => {
        setBankDetails(data);
        setOpenBankDialog(false);
        onPrevBankDetailsSave(data);
    }

    //bank dialog end

    const handleFileChangeID = (e) => {
        const selectedFile = e.target.files[0];

    };

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmailValid = (email) => {
        return emailRegex.test(email);
    };
    const closeEmailDialog = () => {
        setOpenEmailDialog(false);
        if (!isEmailAdded) {
            setPreviousEmployeer({
                ...previousEmployeer,
                officialEmail: '',
            })
        }
    }
    const currentYear = dayjs().year();
    const currentMonth = dayjs().month();
    const disableFutureYears = (date) => {
        return date.year() > currentYear;
    };

    const disableFutureYearsAndMonth = (date) => {
        if (!date) return false; // Ensure date is valid
        const year = date.year();
        const month = date.month();
        return year > currentYear || (year === currentYear && month > currentMonth);
    };
    const [otp, setOtp] = useState(["", "", "", "", "", ""]);
    const [isInvalidOtp, setIsInvalidOtp] = useState(true);
    const [isOpenOTPDialog, setOpenOTPDialog] = useState(false);
    const inputRefs = useRef([]);
    const handleInputKeyDown = (index, e) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };
    const openOTPDialog = async () => {
        setIsButtonClicked(true);
        setIsLoading(true);
        setOtp(["", "", "", "", "", ""]);
        //TODO1 remove below line
        setOpenOTPDialog(true);
        const requestBody = { id: previousEmployeer.officialEmail }
        try {
            const response = await axios.post(
                "/personal-loan/email-verification",
                requestBody,
                { withCredentials: true }
            );

            if (response.data.httpResponseCode === 200) {
                setOpenOTPDialog(true);
            } else {
                console.log("Unexpected response status:", response.status);
            }
        } catch (error) {
            if (error.response.status === 401) {
                handleSignOut();
                toast.error("Your Session has expired.You will be redirected to Login Page.")
                navigate('/')
            } else if (error.response && error.response.status === 429) {
                toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
            } else {
                toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
            }
            console.error("Error during OTP verification:", error);
        }
        finally {
            // Reset loading state after the operation is complete
            setIsLoading(false);
            setIsButtonClicked(false);
        }
    }

    const handleVerifyClick = async (e) => {
        setIsButtonClicked(true);
        setIsLoading1(true); 
        e.preventDefault();

        setOpenOTPDialog(false);
        setOpenEmailDialog(false);
        const requestBody = {
            "otp": otp.join(""), // Join the otp array into a string
        };

        if (otp.join("") === "") { // Check if otp is empty
            setIsInvalidOtp(true);
            inputRefs.current[0].current.focus();
            return;
        }

        try {
            const response = await axios.post(
                "/personal-loan/email-otp-validation",
                requestBody,
                { withCredentials: true }
            );

            if (response.data.httpResponseCode === 200) {
                setOpenOTPDialog(false);
                setEmailAdded(true);
            } else {
                console.log("Unexpected response status:", response.status);
                setEmailAdded(false);
            }
        } catch (error) {
            if (error.response.status === 401) {
                handleSignOut();
                toast.error("Your Session has expired.You will be redirected to Login Page.")
                navigate('/')
            } else if (error.response && error.response.status === 429) {
                toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
            } else {
                toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
            }
            console.error("Error during OTP verification:", error);
        }
        finally {
            // Reset loading state after the operation is complete
            setIsLoading1(false);
            setIsButtonClicked(false);
          }
        //TODO1 remove below two lines
        setOpenOTPDialog(false);
        setEmailAdded(true);
        
    };

    const handleOtpChange = (index, value) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (index < otp.length - 1 && value !== '') {
            // Move focus to the next input if not on the last input and a digit is entered
            inputRefs.current[index + 1].focus();
        }
        const isAllFieldsFilled = newOtp.every((otpValue) => otpValue !== "");
        // Enable or disable the "Continue" button based on the OTP fields status
        setIsInvalidOtp(!isAllFieldsFilled);
    };
    //Page landing Timer
    const [timer, setTimer] = useState(90);

    useEffect(() => {
        const countdownInterval = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
        }, 1000);

        // Cleanup interval when component unmounts
        return () => clearInterval(countdownInterval);
    }, []);

    useEffect(() => {
        if (timer === 0) {
            // Handle the case when the timer reaches 0, e.g., redirect or trigger a resend
        }
    }, [timer]);
    const handleResend = async () => {
        setOtp(["", "", "", "", "", ""]);
        setIsInvalidOtp(false);
        setTimer(90); // Set the countdown to 30 seconds
        const data = {
            uid: uidprops,
        };

        // Define headers
        const headers = {
            "x-apid": '',
            "x-loanappid": '',
        };

        try {
            const response = await axios.post('/personal-loan/initiate-kyc-auto', data, { headers: headers });
        } catch (error) {
            if (error.response.status === 401) {
                alert("Your Session has expired.You will be redirected to Login Page.")
            }
            console.error('Error fetching loan data:', error);
        }

    };

    const handleUploadDocument = () => {
        setPopupOpen(true);
    };
    const handlePopupClose = (selectedFileNames) => {
        console.log(selectedFileNames);
        //setFileNames1( selectedFileNames );
        if (selectedFileNames.length > 0) {
            let doc = selectedFileNames;
            setSelectedDocs((prevSelectedEmployer) => {
                if (prevSelectedEmployer.document) {
                    return {
                        ...prevSelectedEmployer,
                        document: [...prevSelectedEmployer.document, ...doc],
                    };
                } else {
                    return { ...prevSelectedEmployer, document: doc };
                }
            });
            console.log("previous ", selectedDocs);
            setSelectedPrevDocs(selectedDocs)
        }
        //setIsSupportingDocUploaded(true);
        setPopupOpen(false);

    };

    const fileInputRefID = useRef(null);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [selectedDocs, setSelectedDocs] = React.useState({});
    const removeExistingDoc = (documentType) => {
        let updatedDocuments = [...selectedDocs.document];

        // Filter out the document with the given documentType
        updatedDocuments = updatedDocuments.filter(doc => doc.documentType !== documentType);

        // Update the state with the updated array
        setSelectedDocs(prevState => ({
            ...prevState,
            document: updatedDocuments
        }));

    }

    useEffect(() => {
        console.log("previous ", selectedDocs);
        setSelectedPrevDocs(selectedDocs);
    }, [selectedDocs]);
    return (
        <div className="row">
            <div className="row mt-3 mt-md-4">
                <div className="col-sm-12 col-md-6 col-lg-6">
                    <TextField
                        name="gstNumber"
                        value={previousEmployeer.gstNumber}
                        label="GST Number (Optional)"
                        fullWidth
                        inputProps={{ maxLength: 80 }}
                        className={gstnVerified ? 'verifiedBorder' : ''}
                        InputProps={{
                            endAdornment: gstnVerified ? (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <CheckCircleIcon style={{ color: 'green' }} />
                                    <Typography variant="body2" style={{ color: 'green', marginLeft: '4px' }}>Verified</Typography>
                                </div>
                            ) : previousEmployeer.gstNumber && !gstnVerified ? (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <CancelIcon style={{ color: 'grey' }} />
                                    <Typography variant="body2" style={{ color: 'grey', marginLeft: '4px' }}>Unverified</Typography>
                                </div>
                            ) : null,
                        }}
                        onChange={(e) =>
                            setPreviousEmployeer({
                                ...previousEmployeer,
                                gstNumber: e.target.value,
                            })
                        }
                        onBlur={(e) => { handleVerification(e) }}
                    />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                    <TextField
                        name="udyamAadhaar"
                        value={previousEmployeer.udyamAdharNumberVerified}
                        label="Udyam Aadhar (Optional)"
                        fullWidth
                        inputProps={{ maxLength: 80 }}
                        className={udyamAdharNumberVerified ? 'verifiedBorder' : ''}
                        InputProps={{
                            endAdornment: udyamAdharNumberVerified ? (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <CheckCircleIcon style={{ color: 'green' }} />
                                    <Typography variant="body2" style={{ color: 'green', marginLeft: '4px' }}>Verified</Typography>
                                </div>
                            ) : !udyamAdharNumberVerified && previousEmployeer.udyamAdharNumberVerified ? (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <CancelIcon style={{ color: 'grey' }} />
                                    <Typography variant="body2" style={{ color: 'grey', marginLeft: '4px' }}>Unverified</Typography>
                                </div>
                            ) : '',
                        }}
                        onChange={(e) =>
                            setPreviousEmployeer({
                                ...previousEmployeer,
                                udyamAadhaar: e.target.value,
                            })
                        }
                        onBlur={(e) => { handleVerification(e) }}
                    />
                </div>

            </div>

            <div className="row mt-3 mt-md-4">
                <div className="col-sm-12 col-md-6 col-lg-6">
                    <TextField
                        name="shopActNumber"
                        value={previousEmployeer.shopActNumber}
                        label="Shop Act Number (Optional)"
                        fullWidth
                        inputProps={{ maxLength: 80 }}
                        className={shopActNoVerified ? 'verifiedBorder' : ''}
                        onChange={(e) =>
                            setPreviousEmployeer({
                                ...previousEmployeer,
                                shopActNumber: e.target.value,
                            })
                        }
                        onBlur={(e) => { handleVerification(e) }}
                    />
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6">
                    <TextField
                        name="firmName"
                        value={previousEmployeer.firmName}
                        label="Name of Firm"
                        fullWidth
                        inputProps={{ maxLength: 80 }}
                        onChange={(e) =>
                            setPreviousEmployeer({
                                ...previousEmployeer,
                                firmName: e.target.value,
                            })
                        }
                    />
                </div>

            </div>

            <div className="row mt-3 mt-md-4">
                <div className="col-sm-12 col-md-6 col-lg-6">
                    <TextField
                        name="firmVintage"
                        value={previousEmployeer.firmVintage}
                        label="Firm Vintage"
                        fullWidth
                        inputProps={{ maxLength: 80 }}
                        onChange={(e) =>
                            setPreviousEmployeer({
                                ...previousEmployeer,
                                firmVintage: e.target.value,
                            })
                        }
                    />
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6">
                    <TextField
                        name="numberOfEmployeesInFirm"
                        value={previousEmployeer.numberOfEmployeesInFirm}
                        label="Number of Employees in Firm"
                        fullWidth
                        inputProps={{ maxLength: 80 }}
                        onChange={(e) =>
                            setPreviousEmployeer({
                                ...previousEmployeer,
                                numberOfEmployeesInFirm: e.target.value,
                            })
                        }
                    />
                </div>

            </div>

            <div className="row mt-3 mt-md-4">

                <div className="col-sm-12 col-md-6 col-lg-6">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Incorporation / Commencement Month"
                            value={previousEmployeer.incorporationMonth}
                            onChange={(e) =>
                                setPreviousEmployeer({
                                    ...previousEmployeer,
                                    incorporationMonth: e,
                                })
                            }
                            renderInput={(params) => <TextField {...params} />}
                            className="yearPicker"
                            format="MM-YYYY" // Set the format here
                            openTo="month" // Open the DatePicker with the month view
                            views={['month', 'year']}
                            shouldDisableDate={disableFutureYearsAndMonth}
                        />
                    </LocalizationProvider>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Firm closure Month"
                            value={previousEmployeer.incorporationMonth}
                            onChange={(e) =>
                                setPreviousEmployeer({
                                    ...previousEmployeer,
                                    incorporationMonth: e,
                                })
                            }
                            renderInput={(params) => <TextField {...params} />}
                            className="yearPicker"
                            format="MM-YYYY" // Set the format here
                            openTo="month" // Open the DatePicker with the month view
                            views={['month', 'year']}
                        />
                    </LocalizationProvider>
                </div>
            </div>

            <div className="row mt-3 mt-md-4">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <ArthaFormControl fullWidth>
                        <InputLabel id="firmPremisesOwner-label">
                            Firm Premises Owner
                        </InputLabel>
                        <Select
                            labelId="firmPremisesOwner-label"
                            id="firmPremisesOwner"
                            name="firmPremisesOwner"
                            value={previousEmployeer.firmPremisesOwner}
                            onChange={(e) =>
                                setPreviousEmployeer({
                                    ...previousEmployeer,
                                    firmPremisesOwner: e.target.value,
                                })
                            }
                        >
                            {firmVintageOwnerDropdown && firmVintageOwnerDropdown.map((item) => {
                                return (
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                )
                            })}
                        </Select>
                    </ArthaFormControl>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <ArthaFormControl fullWidth>
                        <InputLabel id="firmPremisesVintage-label">
                            Firm Premises Vintage
                        </InputLabel>
                        <Select
                            labelId="firmPremisesVintage-label"
                            id="firmPremisesVintage"
                            name="firmPremisesVintage"
                            value={previousEmployeer.firmPremisesVintage}
                            onChange={(e) =>
                                setPreviousEmployeer({
                                    ...previousEmployeer,
                                    firmPremisesVintage: e.target.value,
                                })
                            }
                        >
                            {firmVintageDropdown.map((item, index) => {
                                const [value, label] = item.split(' ');
                                return (
                                    <MenuItem key={index} value={value}>
                                        {item}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </ArthaFormControl>
                </div>
            </div>
            <div className="row mt-3 mt-md-4">
                <div className="col-sm-12 col-md-6 col-lg-6">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Working since"
                            value={previousEmployeer.workfrom}
                            onChange={(e) =>
                                handleWorkingSince(e)
                            }
                            renderInput={(params) => <TextField {...params} />}
                            className="yearPicker"
                            maxDate={worksince}
                            onKeyDown={(e) => e.preventDefault()} // Prevent manual typing
                        />
                    </LocalizationProvider>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Working till"
                            value={previousEmployeer.worktill}
                            onChange={(e) =>
                                handleWorkingTill(e)
                            }
                            renderInput={(params) => <TextField {...params} />}
                            className="yearPicker"
                            maxDate={worksince}
                            minDate={previousEmployeer.workfrom}
                        />
                    </LocalizationProvider>
                </div>
            </div>

            <div className="row mt-3 mt-md-4">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row">
                        <div className="col-8 col-md-7">
                            <p className="msg">Firm Address</p>

                            {
                                isAddressAdded ? <>
                                    <FaExclamationCircle className="badge-icon" style={{ marginTop: "-7px", color: 'green' }} />
                                    <span
                                        className="mt-2 upload-lable"
                                        style={{ position: "relative", top: "-10px" }}
                                    >
                                        {" "}
                                        Address added!
                                    </span>
                                </> : <>
                                    <FaExclamationCircle className="badge-icon" />
                                    <span
                                        className="mt-2 upload-lable"
                                        style={{ position: "relative", top: "-10px" }}
                                    >
                                        {" "}
                                        Not Added
                                    </span>
                                </>

                            }
                        </div>
                        <div className="col-4 col-md-5">
                            <input
                                type="file"
                                style={{ display: "none" }}
                                onChange={handleFileChangeID}
                            />
                            <Button
                                variant="contained"
                                className="upload_btn btn_white shadow-none mt-3"
                                onClick={openAddrDialog}
                            >
                                {!isAddressAdded ? ' Add Address' : ' Edit'}
                            </Button>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row">
                        <div className="col-8 col-md-7">
                            <p className="msg">Official mail address (Optional)</p>

                            {
                                isEmailAdded ? <>
                                    <FaExclamationCircle className="badge-icon" style={{ marginTop: "-7px", color: 'green' }} />
                                    <span
                                        className="mt-2 upload-lable"
                                        style={{ position: "relative", top: "-10px" }}
                                    >
                                        {" "}
                                        Email Added
                                    </span>
                                </> : <>
                                    <FaExclamationCircle className="badge-icon" />
                                    <span
                                        className="mt-2 upload-lable"
                                        style={{ position: "relative", top: "-10px" }}
                                    >
                                        {" "}
                                        Not Uploaded
                                    </span>
                                </>

                            }
                        </div>
                        <div className="col-4 col-md-5">
                            <input
                                type="file"
                                style={{ display: "none" }}
                                onChange={handleFileChangeID}
                            />
                            <Button
                                variant="contained"
                                className="upload_btn btn_white shadow-none mt-3"
                                onClick={() => { setOpenEmailDialog(true); }}
                            >
                                {isEmailAdded ? 'Edit' : 'Add Email'}
                            </Button>
                        </div>
                    </div>
                </div>

            </div>

            <div className="row mt-3 mt-md-4">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row">
                        <div className="col-8 col-md-7">
                            <p className="msg">Bank Account Details</p>

                            {
                                bankDetails ? <>
                                    <FaExclamationCircle className="badge-icon" style={{ marginTop: "-7px", color: 'green' }} />
                                    <span
                                        className="mt-2 upload-lable"
                                        style={{ position: "relative", top: "-10px" }}
                                    >
                                        {" "}
                                        Bank Added
                                    </span>
                                </> : <>
                                    <FaExclamationCircle className="badge-icon" />
                                    <span
                                        className="mt-2 upload-lable"
                                        style={{ position: "relative", top: "-10px" }}
                                    >
                                        {" "}
                                        Not Added
                                    </span>
                                </>

                            }
                        </div>
                        <div className="col-4 col-md-5">
                            <input
                                type="file"
                                style={{ display: "none" }}
                                onChange={handleFileChangeID}
                            />
                            <Button
                                variant="contained"
                                className="upload_btn btn_white shadow-none mt-3"
                                onClick={() => { setOpenBankDialog(true) }}
                            >
                                {(bankDetails || prevBankDetails) ? 'Edit' : 'Add Bank'}
                            </Button>
                            <AddBank openBankDialog={openBankDialog} onCancel={cancelBankDialog} onBankDataSave={onBankDataSave} prevData={prevBankDetails} />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row">
                        <div className="col-8 col-md-7">
                            <p className="msg">Supporting Documents</p>

                            {
                                registrationDocument && registrationDocument.documentType ? (
                                    <>
                                        <FaExclamationCircle className="badge-icon" style={{ marginTop: "-7px", color: 'green' }} />
                                        <span
                                            className="mt-2 upload-lable"
                                            style={{ position: "relative", top: "-10px" }}
                                        >
                                            {" "}
                                            {registrationDocument.documentType}
                                        </span>
                                    </>
                                ) : (
                                    (selectedDocs && selectedDocs.document && selectedDocs.document.length > 0) ? (
                                        selectedDocs.document.map((doc, index) => (
                                            <React.Fragment key={index}>
                                                <FaExclamationCircle className="badge-icon" style={{ marginTop: "-7px", color: 'green' }} />
                                                <span
                                                    className="mt-2 upload-lable"
                                                    style={{ position: "relative", top: "-10px" }}
                                                >
                                                    {" "}
                                                    {doc.documentType} <br />
                                                </span>
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        <>
                                            <FaExclamationCircle className="badge-icon" />
                                            <span
                                                className="mt-2 upload-lable"
                                                style={{ position: "relative", top: "-10px" }}
                                            >
                                                {" "}
                                                Not Uploaded
                                            </span>
                                        </>
                                    )
                                )
                            }

                        </div>
                        <div className="col-4 col-md-5">
                            <input
                                type="file"
                                ref={fileInputRefID}
                                style={{ display: "none" }}
                                onChange={handleFileChangeID}
                            />
                            <Button
                                variant="contained"
                                className="upload_btn btn_white shadow-none mt-3"
                                onClick={handleUploadDocument}
                            >
                                Upload
                            </Button>
                            <UploadSupportiveDocs
                                open={isPopupOpen}
                                existingDocuments={selectedDocs.document}
                                onRemove={removeExistingDoc}
                                handleClose={handlePopupClose}
                            />

                        </div>
                    </div>
                </div>
            </div>
            {/* address dialog started */}
            <Dialog
                open={openAddressDialog}

                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="modal row">
                    <div className="modal-content col-md-6">
                        {!isAddressAdded ? <h6> Add New Address</h6> : <h6>Edit Address</h6>}
                        <div className="row">
                            <div className="col-md-6 mt-3">
                                <ArthaTextField
                                    name="line1"
                                    fullWidth
                                    value={previousAddress && previousAddress.line1}
                                    onChange={(e) => { handleAddressChange(e) }}
                                    label="Address Line 1"

                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <ArthaTextField
                                    name="line2"
                                    onChange={(e) => { handleAddressChange(e) }}
                                    value={previousAddress && previousAddress.line2}
                                    fullWidth
                                    label="Address Line 2"
                                />
                            </div>
                            <div className="col-md-6 mt-5 mt-md-3">
                                <ArthaTextField
                                    name="pincode"
                                    onChange={(e) => {
                                        const inputValue = e.target.value.replace(/\D/g, '').slice(0, 6);
                                        handleAddressChange({ target: { name: 'pincode', value: inputValue } });
                                    }}
                                    value={previousAddress && previousAddress.pincode}
                                    label="Pincode"
                                    fullWidth
                                />
                                <span style={{ color: 'red', fontSize: '12px' }}>{pincodeError}</span>

                            </div>
                            <div className="col-md-6 mt-3 mt-md-3">
                                <ArthaTextField
                                    name="city"
                                    label="City"
                                    onChange={(e) => {
                                        const inputValue = e.target.value.replace(/[^a-zA-Z]/g, '');
                                        handleAddressChange({ target: { name: 'city', value: inputValue } });
                                    }}
                                    value={previousAddress && previousAddress.city}
                                    fullWidth

                                />
                            </div>
                            <div className="col-md-6 mt-3 mt-md-3">
                                <ArthaTextField
                                    name="state"
                                    label="State"
                                    onChange={(e) => {
                                        const inputValue = e.target.value.replace(/[^a-zA-Z]/g, '');
                                        handleAddressChange({ target: { name: 'state', value: inputValue } });
                                    }}
                                    value={previousAddress && previousAddress.state}
                                    fullWidth
                                />
                            </div>
                        </div>

                        <div className="row mt-4 mx-auto">
                            <div className="col-6 d-flex justify-content-end mt-4">
                                <ArthaClearButton variant="contained" onClick={closeAddrDialog}>
                                    Cancel
                                </ArthaClearButton>
                            </div>
                            <div className="col-6 d-flex justify-content-start mt-4">
                                <ArthaContinueButton
                                    variant="contained"
                                    disabled={!isFormFilled}
                                    onClick={() => { setOpenAddressDialog(false); setAddressAdded(true) }}
                                >
                                    Save
                                </ArthaContinueButton>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            {/* Email dialog started */}
            <>
                <Dialog
                    open={isOpenEmailDialog}

                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div className="modal row">
                        <div className="modal-content col-md-6">
                            {!isEmailAdded ? <h6> Add Official Email ID</h6> : <h6>Edit Email</h6>}
                            <div className="row">
                                <div className="col-md-12 mt-3">
                                    <span>Please enter official email id for your current employer. OTP validation will be needed for confirming the email id.</span>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <ArthaTextField
                                        name="line2"
                                        onChange={(e) => {
                                            setPreviousEmployeer({
                                                ...previousEmployeer,
                                                officialEmail: e.target.value,
                                            })
                                        }}
                                        onBlur={() => setEmailTouched(true)}
                                        value={previousEmployeer.officialEmail}
                                        fullWidth
                                        label="Email ID"
                                        helperText={
                                            emailTouched &&
                                            previousEmployeer?.officialEmail?.length > 0 &&
                                            !isEmailValid(previousEmployeer.officialEmail) &&
                                            "Invalid email format"
                                          }
                                          error={
                                            emailTouched &&
                                            previousEmployeer?.officialEmail?.length > 0 &&
                                            !isEmailValid(previousEmployeer.officialEmail)
                                          }
                                    />
                                </div>

                            </div>

                            <div className="row mt-4 mx-auto">
                                <div className="col-6 d-flex justify-content-end mt-4">
                                    <ArthaClearButton variant="contained" onClick={closeEmailDialog}>
                                        Cancel
                                    </ArthaClearButton>
                                </div>
                                <div className="col-6 d-flex justify-content-start mt-4">
                                    <ArthaContinueButton
                                        variant="contained"
                                        disabled={!emailRegex.test(previousEmployeer.officialEmail)} // Disable button if email format is invalid

                                        onClick={() => { openOTPDialog(); }}
                                        style={{
                                            opacity: isButtonClicked ? 0.39 : 1,
                                            pointerEvents: isButtonClicked ? "none" : "auto"
                                        }}
                                    >
                                        {isLoading ? (
                                            <LoadingSpinner style={{ pointerEvents: 'none' }} />
                                        ) : (
                                            'Continue'
                                        )}
                                    </ArthaContinueButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </>

            <>
                <Dialog
                    open={isOpenOTPDialog}

                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div className="otpBox">
                        <div className="AdharOTPForm">
                            <div className="AdharOTPHeading mt-3">
                                <h5>Otp Validation</h5>

                                <p className="msg mt-4" style={{ padding: "0", margin: "0" }}>
                                    Please Enter OTP Sent to
                                </p>
                                <span className="msg mt-4" style={{ fontSize: "10px", color: "blue", padding: "0", margin: "0" }}>
                                    {/* {formData.officialEmail} */}
                                </span>

                                <div className="Verify-OTP mt-5">
                                    <div className="Verify-OTP-section">
                                        <div className="otp-inputs">
                                            {otp.map((value, index) => (
                                                <input
                                                    key={index}
                                                    type="text"
                                                    className={`otp-input ${isInvalidOtp ? "error" : ""}`}
                                                    maxLength="1"
                                                    value={value}
                                                    onChange={(e) => handleOtpChange(index, e.target.value)}
                                                    onKeyDown={(e) => handleInputKeyDown(index, e)}
                                                    ref={(ref) => (inputRefs.current[index] = ref)} // Store the reference to each input field
                                                />
                                            ))}
                                        </div>

                                        <br />

                                        <h6 className="text-center">
                                            Didn't Receive any Code?{" "}
                                            <a
                                                href="#"
                                                style={{ color: "rgb(255, 136, 0)", marginLeft: "5px" }}

                                            >
                                                <h6 className="mt-3" onClick={handleResend}>Resend Code</h6>
                                            </a>
                                        </h6>

                                    </div>
                                    <div className="row mt-5 ">
                                        <div className="col-6 d-flex justify-content-end">
                                            <ArthaClearButton
                                                variant="contained"
                                                onClick={() => { setOpenOTPDialog(false); }}
                                            >
                                                Back
                                            </ArthaClearButton>
                                        </div>

                                        <div className="col-6 d-flex justify-content-start">
                                            <ArthaContinueButton
                                                onClick={handleVerifyClick}
                                                variant="contained"
                                                disabled={isInvalidOtp}
                                                style={{
                                                    opacity: isButtonClicked ? 0.39 : 1,
                                                    pointerEvents: isButtonClicked ? "none" : "auto"
                                                }}
                                            >
                                                {isLoading1 ? (
                                                    <LoadingSpinner style={{ pointerEvents: 'none' }} />
                                                ) : (
                                                    'Continue'
                                                )}
                                            </ArthaContinueButton>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Dialog>
            </>
        </div>
    );
};

export default PreviousEmployer;
