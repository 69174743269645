import React, { useEffect, useState, useRef } from "react";
import { InputLabel, MenuItem, Select } from "@mui/material";
import ArthaTextField from "../../fields/ArthaTextField";
import ArthaClearButton from "../../fields/ArthaClearButton";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import ArthaFormControl from "../../fields/ArthaFormControl";
import { useNavigate } from "react-router-dom";
import { handleSignOut } from "./SessionExpiredApi";
import {
  businessLoanFetchDataAPI,
  propertyLoanFetchReferncesAPI,
  propertyLoanStoreReferncesAPI, propertyLoanBackPageAPI, propertyLoanFetchDataAPI
} from "../../actioncreators/actioncreators";
import LoadingSpinner from "../../fields/spinner";
import ITrDetails from "./ITR";
import { toast } from "react-toastify";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArthaInputLabel from "../../fields/ArthaInputLabel";
export default function Refernces({
  onCancel, changeApplicantStatus
}) {
  const [referenceCount, setReferenceCount] = useState(4); // Initial reference count
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [references, setReferences] = useState(
    Array.from({ length: referenceCount }, (_, index) => index + 1)
  );
  const [showItrDetails, setshowItrDetails] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [response, setresponse] = useState([]);
  const [designationDropdown, setDesignationDropdown] = React.useState([]);
  // State to hold user input data for each reference
  const [referenceData, setReferenceData] = useState(
    Array.from({ length: referenceCount }, () => ({
      personName: "",
      contactNumber: "",
      address: "",
      email: "",
      name: "",
      referenceThreshold: "",
      referenceType: "",
      designation: "",
    }))
  );
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed
  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error.response.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }

    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };
  const [referenceThreshold, setReferenceThreshold] = useState({
    personal: { maxCount: 0, minCount: 0 },
    professional: { maxCount: 0, minCount: 0 },
  });



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await propertyLoanFetchReferncesAPI();
        setresponse(response);
        setReferenceThreshold(response);
        // const { referencesDetails } = response;
        console.log("response", response);
        if (response?.data?.referencesDetails) {
          setReferenceData(response?.data?.referencesDetails);
          // Initialize empty reference data based on max counts
          const totalCount =
            Number(response?.data?.referenceThreshold?.personal?.maxCount) +
            Number(response?.data?.referenceThreshold?.professional?.maxCount);
          console.log("totalCount", totalCount);
          // Calculate the difference to add empty references if needed
          const existingCount = response?.data?.referencesDetails?.length || 0;
          const emptyCount = totalCount - existingCount;
          const emptyReferences = Array.from({ length: emptyCount }, () => ({
            name: "",
            contactNumber: "",
            address: "",
            email: "",
            referenceType: "",
          }));
          setReferenceData([...response?.data?.referencesDetails, ...emptyReferences]);
        } else {
          // Initialize empty reference data based on max counts
          // console.log("response",response);
          // console.log("referenceThreshold",referenceThreshold);
          // console.log("referenceThreshold?.personal?.maxCount",referenceThreshold?.personal?.maxCount);
          const totalCount =
            Number(response?.data?.referenceThreshold?.personal?.maxCount) +
            Number(response?.data?.referenceThreshold?.professional?.maxCount);
          console.log("totalCount", totalCount);
          setReferenceData(Array.from({ length: totalCount }, () => ({
            name: "",
            contactNumber: "",
            address: "",
            email: "",
            referenceType: "",
          })));
        }
      } catch (error) {
        handleApiError(error);
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const professionalMaxCount =
      Number(referenceThreshold?.data?.referenceThreshold?.professional?.maxCount) || 0;
    const personalMaxCount =
      Number(referenceThreshold?.data?.referenceThreshold?.personal?.maxCount) || 0;
    const totalReferenceCount = professionalMaxCount + personalMaxCount;
    setReferenceCount(totalReferenceCount);
  }, [response]); // Depend on response to update when it changes

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      const payload = {
        type: "REFERENCE_TYPE",
        key: "REFERENCE_TYPE",
        source: "json_value",
      };
      try {
        const dropdownResponse = await businessLoanFetchDataAPI(payload); // Replace with your dropdown options API call function
        if (dropdownResponse.data && dropdownResponse.data[0].List) {
          setDropdownOptions(dropdownResponse.data[0].List);
        }
      } catch (error) {
        handleApiError(error);
        console.error("Error fetching dropdown options:", error);
      }
    };

    fetchDropdownOptions();
  }, []);

  useEffect(() => {
    fetchDesignationValues();
  }, []);

  const fetchDesignationValues = async () => {
    setDesignationDropdown([]);
    try {
      let payload = {
        source: "json_value",
        key: "REFERENCES_DESIGNATION",
        type: "LOAN_AGAINST_PROPERTY",
      };
      const response = await propertyLoanFetchDataAPI(payload);
      setDesignationDropdown(response?.data?.[0]?.List);
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching address vintage:", error);
    }
  };

  const [formValid, setFormValid] = useState(true);
  const handleKeyPress = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const handleChange = (index) => (event) => {
    const { value } = event.target;
    handleInputChange(index, 'designation', value);
  };

  // const handleInputChange = (index, fieldName, value) => {
  //   const updatedData = [...referenceData];
  //   if (!updatedData[index]) {
  //     updatedData[index] = {}; // Ensure the object exists
  //   }
  //   updatedData[index][fieldName] = value;
  //   setReferenceData(updatedData);

  //   // Initialize errors array if it doesn't exist
  //   const updatedErrors = [...errors];

  //   if (!updatedErrors[index]) {
  //     updatedErrors[index] = {};
  //   }

  //   // Validation functions
  //   const validateEmail = (email) => {
  //     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //     return emailRegex.test(email);
  //   };

  //   const validateContactNumber = (number) => {
  //     const contactNumberRegex = /^\d{10}$/;
  //     const isAllSameDigit = /^(\d)\1{9}$/; // Regex to check if all digits are the same

  //     if (!contactNumberRegex.test(number)) {
  //       return "Contact No should be 10 digits";
  //     } else if (isAllSameDigit.test(number)) {
  //       return "Contact No should not be all the same digit";
  //     }
  //     return "";
  //   };

  //   const validateName = (name) => {
  //     const specialCharRegex = /[^a-zA-Z ]/g;
  //     const digitRegex = /\d/;

  //     if (specialCharRegex.test(name)) {
  //       return "Name field should not contain special character and numbers.";
  //     }
  //     if (digitRegex.test(name)) {
  //       return "Name should not contain digits.";
  //     }
  //     if (name.length > 50) {
  //       return "Name should not exceed 50 characters.";
  //     }
  //     return "";
  //   };

  //   // Validation logic
  //   if (fieldName === "email" && value && !validateEmail(value)) {
  //     updatedErrors[index][fieldName] =
  //       "Invalid Email. Please enter a valid email address.";
  //   } else if (fieldName === "contactNumber") {
  //     const contactNumberError = validateContactNumber(value);
  //     if (contactNumberError) {
  //       updatedErrors[index][fieldName] = contactNumberError;
  //     } else {
  //       updatedErrors[index][fieldName] = "";
  //     }
  //   } else if (
  //     (fieldName === "name" || fieldName === "address") &&
  //     !value.trim()
  //   ) {
  //     updatedErrors[index][fieldName] = `${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)
  //       } is required`;
  //   } else if (fieldName === "name") {
  //     const nameError = validateName(value);
  //     if (nameError) {
  //       updatedErrors[index][fieldName] = nameError;
  //     } else {
  //       updatedErrors[index][fieldName] = "";
  //     }
  //   } else if (fieldName === "address") {
  //     const min = 5;
  //     const max = 350;
  //     if (value.length < min) {
  //       updatedErrors[index][
  //         fieldName
  //       ] = `Address must be at least ${min} characters`;
  //     } else if (value.length > max) {
  //       updatedErrors[index][
  //         fieldName
  //       ] = `Address must be at most ${max} characters`;
  //     } else {
  //       updatedErrors[index][fieldName] = "";
  //     }
  //   } else if (fieldName === "referenceType") {
  //     const professionalCount = referenceData.filter(
  //       (ref) => ref.referenceType === "professional"
  //     ).length;
  //     const personalCount = referenceData.filter(
  //       (ref) => ref.referenceType === "personal"
  //     ).length;

  //     const professionalMaxCount =
  //       Number(referenceThreshold?.data?.referenceThreshold?.professional?.maxCount) || 0;
  //     const professionalMinCount =
  //       Number(referenceThreshold?.data?.referenceThreshold?.professional?.minCount) || 0;
  //     const personalMaxCount =
  //       Number(referenceThreshold?.data?.referenceThreshold?.personal?.maxCount) || 0;
  //     const personalMinCount =
  //       Number(referenceThreshold?.data?.referenceThreshold?.personal?.minCount) || 0;

  //     let isValid = true;

  //     // Check for exceeding maximum count
  //     if (professionalCount > professionalMaxCount) {
  //       updatedErrors[index][fieldName] =
  //         "Exceeded maximum number of professional references.";
  //       isValid = false;
  //     } else if (personalCount > personalMaxCount) {
  //       updatedErrors[index][fieldName] =
  //         "Exceeded maximum number of personal references.";
  //       isValid = false;
  //     }
  //     // Check for not meeting minimum count
  //     else if (
  //       professionalCount < professionalMinCount &&
  //       referenceData[index].referenceType === "professional"
  //     ) {
  //       updatedErrors[index][fieldName] =
  //         `At least ${professionalMinCount} professional references are required.`;
  //       isValid = false;
  //     } else if (
  //       personalCount < personalMinCount &&
  //       referenceData[index].referenceType === "personal"
  //     ) {
  //       updatedErrors[index][fieldName] =
  //         `At least ${personalMinCount} personal references are required.`;
  //       isValid = false;
  //     } else {
  //       updatedErrors[index][fieldName] = ""; // Clear the error if valid
  //     }

  //     // If minimum references are met, mark as valid
  //     if (professionalCount >= professionalMinCount && personalCount >= personalMinCount) {
  //       isValid = true;
  //     } else {
  //       isValid = false;
  //     }

  //     // You can now update the form state, validation state, or other logic based on `isValid`
  //     setFormValid(isValid); // Example function to handle form validation status
  //   } else {
  //     updatedErrors[index][fieldName] = "";
  //   }

  //   setErrors(updatedErrors);

  //   // Check form validity
  //   setFormValid(isFormValid(updatedErrors));

  //   if (fieldName === "referenceType") {
  //     const professionalCount = updatedData.filter(ref => ref.referenceType === "professional").length;
  //     const personalCount = updatedData.filter(ref => ref.referenceType === "personal").length;

  //     const professionalMaxCount = Number(referenceThreshold?.data?.referenceThreshold?.professional?.maxCount) || 0;
  //     const personalMaxCount = Number(referenceThreshold?.data?.referenceThreshold?.personal?.maxCount) || 0;

  //     if (value === "professional" && professionalCount > professionalMaxCount) {
  //       updatedErrors[index][fieldName] = `Maximum ${professionalMaxCount} Professional references allowed.`;
  //     } else if (value === "personal" && personalCount > personalMaxCount) {
  //       updatedErrors[index][fieldName] = `Maximum ${personalMaxCount} Personal references allowed.`;
  //     } else {
  //       updatedErrors[index][fieldName] = "";
  //     }

  //     // Update form validity
  //     setFormValid(isFormValid());
  //   }

  // };


  const handleInputChange = (index, fieldName, value) => {
    const updatedData = [...referenceData];

    // Ensure the object at the specified index exists
    if (!updatedData[index]) {
      updatedData[index] = {};
    }
    updatedData[index][fieldName] = value;
    setReferenceData(updatedData);

    const updatedErrors = [...errors];

    // Ensure the error object at the specified index exists
    if (!updatedErrors[index]) {
      updatedErrors[index] = {};
    }

    // Helper function to check duplicates
    const checkDuplicates = (field, value) => {
      return updatedData.filter((data) => data[field] === value).length > 1;
    };

    // Validation functions
    const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const validateContactNumber = (number) => {
      const contactNumberRegex = /^\d{10}$/;
      const isAllSameDigit = /^(\d)\1{9}$/;
      if (!contactNumberRegex.test(number)) {
        return "Contact No should be 10 digits.";
      } else if (isAllSameDigit.test(number)) {
        return "Contact No should not be all the same digit.";
      }
      return "";
    };
    const validateName = (name) => {
      const specialCharRegex = /[^a-zA-Z ]/g;
      const digitRegex = /\d/;
      if (!name || name.trim() === "") {
        return "Name is required.";
      }
      if (specialCharRegex.test(name)) {
        return "Name should not contain special characters.";
      }
      if (digitRegex.test(name)) {
        return "Name should not contain digits.";
      }
      if (name.length > 50) {
        return "Name should not exceed 50 characters.";
      }
      return "";
    };
    const validateAddress = (address) => {
      if (!address || address.trim() === "") {
        return "Address is required.";
      }
      if (address.length > 200) {
        return "Address must be at most 200 characters.";
      }
      return "";
    };

    // Dynamic validation logic
    if (fieldName === "name") {
      const nameError = validateName(value);
      if (nameError) {
        updatedErrors[index][fieldName] = nameError;
      } else if (checkDuplicates(fieldName, value)) {
        updatedErrors[index][fieldName] = "Duplicate names are not allowed.";
      } else {
        updatedErrors[index][fieldName] = "";
      }
    } else if (fieldName === "contactNumber") {
      const contactError = validateContactNumber(value);
      if (contactError) {
        updatedErrors[index][fieldName] = contactError;
      } else if (checkDuplicates(fieldName, value)) {
        updatedErrors[index][fieldName] = "Duplicate contact numbers are not allowed.";
      } else {
        updatedErrors[index][fieldName] = "";
      }
    } else if (fieldName === "email") {
      // if (!value || !validateEmail(value)) {
      //   updatedErrors[index][fieldName] = "Invalid email address.";
      // } else if (checkDuplicates(fieldName, value)) {
      //   updatedErrors[index][fieldName] = "Duplicate email addresses are not allowed.";
      // } else {
      //   updatedErrors[index][fieldName] = "";
      // }
      if (value && !validateEmail(value)) {
        // Validate email only if it's not empty
        updatedErrors[index][fieldName] = "Invalid email address.";
      } else if (value && checkDuplicates(fieldName, value)) {
        // Check duplicates only if email has a value
        updatedErrors[index][fieldName] = "Duplicate email addresses are not allowed.";
      } else {
        // Clear error if email is valid or empty
        updatedErrors[index][fieldName] = "";
      }
    } else if (fieldName === "address") {
      const addressError = validateAddress(value);
      if (addressError) {
        updatedErrors[index][fieldName] = addressError;
      } else if (checkDuplicates(fieldName, value)) {
        updatedErrors[index][fieldName] = "Duplicate addresses are not allowed.";
      } else {
        updatedErrors[index][fieldName] = "";
      }
    } else if (fieldName === "referenceType") {
      const professionalCount = updatedData.filter(ref => ref.referenceType === "professional").length;
      const personalCount = updatedData.filter(ref => ref.referenceType === "personal").length;

      const professionalMaxCount = Number(referenceThreshold?.data?.referenceThreshold?.professional?.maxCount) || 0;
      const professionalMinCount = Number(referenceThreshold?.data?.referenceThreshold?.professional?.minCount) || 0;
      const personalMaxCount = Number(referenceThreshold?.data?.referenceThreshold?.personal?.maxCount) || 0;
      const personalMinCount = Number(referenceThreshold?.data?.referenceThreshold?.personal?.minCount) || 0;

      // Check for maximum count
      if (value === "professional" && professionalCount > professionalMaxCount) {
        // updatedErrors[index][fieldName] = `Maximum ${professionalMaxCount} professional references allowed.`;
        updatedErrors[index][fieldName] = (
          <div
            style={{
              color: "rgb(210 74 74)",  // Red color for errors
              fontSize: "13px",   // Font size
              marginLeft: "14px",
              marginTop: "5px",    // Spacing above the message
              // marginBottom: "5px",// Spacing below the message
              // textAlign: "left"   // Align left
            }}
          >
            Exceeded maximum number of professional references.
          </div>
        );
      } else if (value === "personal" && personalCount > personalMaxCount) {
        // updatedErrors[index][fieldName] = `Exceeded maximum number of personal references.`;
        updatedErrors[index][fieldName] = (
          <div style={{
            color: "rgb(210 74 74)",  // Red color for errors
            fontSize: "13px",   // Font size
            marginLeft: "14px",
            marginTop: "5px",   // Spacing above the message
            // marginBottom: "5px",// Spacing below the message
            // textAlign: "left"   // Align left
          }}
          >
            Exceeded maximum number of personal references.
          </div>
        );
      }
      // Check for minimum count (only if other references are already filled)
      else if (
        professionalCount < professionalMinCount &&
        value === "Professional"
      ) {
        updatedErrors[index][fieldName] = `At least ${professionalMinCount} professional references are required.`;
      } else if (
        personalCount < personalMinCount &&
        value === "Personal"
      ) {
        updatedErrors[index][fieldName] = `At least ${personalMinCount} personal references are required.`;
      } else {
        updatedErrors[index][fieldName] = ""; // Clear the error if valid
      }
    }


    // Update errors state
    setErrors(updatedErrors);

    // Check overall form validity
    const isFormValid = updatedErrors.every(
      (error) =>
        error &&
        Object.values(error).every((errMsg) => errMsg === "")
    );
    setFormValid(isFormValid);
  };


  const clearReference = (index) => {
    const updatedReferences = [...referenceData];
    updatedReferences[index] = {
      name: "",
      contactNumber: "",
      address: "",
      email: "",
      referenceType: "",
    };
    setReferenceData(updatedReferences);

    // Optionally clear errors for this reference
    const updatedErrors = [...errors];
    updatedErrors[index] = {};
    setErrors(updatedErrors);
  };
  const isFormValid = () => {
    const professionalCount = referenceData.filter(ref => ref.referenceType === "professional").length;
    const personalCount = referenceData.filter(ref => ref.referenceType === "personal").length;
    console.log("personalCount", personalCount);
    console.log("professionalCount", professionalCount);
    const professionalMinCount = Number(referenceThreshold?.data?.referenceThreshold?.professional?.minCount) || 0;
    const personalMinCount = Number(referenceThreshold?.data?.referenceThreshold?.personal?.minCount) || 0;
    console.log("personalMinCount", personalMinCount);
    console.log("professionalMinCount", professionalMinCount);
    console.log("professionalCount < professionalMinCount || personalCount < personalMinCount", professionalCount < professionalMinCount || personalCount < personalMinCount)
    if (professionalCount < professionalMinCount || personalCount < personalMinCount) {
      return false;
    }
    // Ensure errors array is initialized and each entry is an object
    // if (!Array.isArray(errors)) {
    //   return false; // If errors is not an array, return false
    // }
    console.log("referenceData", referenceData);
    // Check if all required fields are filled and valid
    // return referenceData.every((ref, index) => {
    //   // Ensure each error entry at index is an object
    //   const errorEntry = errors[index] || {};
    //   return !ref.referenceType || // Skip validation for empty referenceType
    //     (ref.referenceType && ref.name && ref.contactNumber && ref.address &&
    //       !Object.values(errorEntry).some(e => e)); // Check for any error messages
    // });
    return referenceData.every((ref, index) => {
      if (!ref.referenceType) return true;

      const errorEntry = errors[index] || {};
      const hasErrors = Object.values(errorEntry).some(e => e);

      const hasRequiredFields = ref.name &&
        ref.contactNumber &&
        ref.address;

      // For professional references, also check designation
      if (ref.referenceType === "professional") {
        return hasRequiredFields &&
          !!ref.designation &&
          !hasErrors;
      }

      return hasRequiredFields && !hasErrors;
    });
  };

  // Function to check if all required fields in all references are filled
  const isContinueButtonDisabled = () => {
    for (const reference of referenceData) {
      if (
        !reference.name ||
        !reference.contactNumber ||
        !reference.address ||
        !reference.referenceType
      ) {
        return true; // Disable button if any field is empty
      }
    }
    return false; // Enable button if all fields are filled
  };
  const handleBack = async () => {
    try {
      const response = await propertyLoanBackPageAPI();
      if (response?.data?.businessStatusCode === 2) {
        changeApplicantStatus(response?.data?.nextPage);
      } else {
        toast.info(response.data.message);
      }
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching loan data:", error);
    }
  };

  const handleContinue = async () => {
    if (!isFormValid()) {
      toast.error("Please fill all required fields and ensure the minimum number of references for each type.");
      return;
    }
    setIsButtonClicked(true);
    setIsLoading(true);

    try {
      // Generate the payload dynamically based on the user input
      const payload = {
        referencesDetails: referenceData.filter(ref => ref.referenceType).map(ref => ({
          name: ref.name,
          address: ref.address,
          referenceType: ref.referenceType,
          contactNumber: ref.contactNumber,
          email: ref.email,
          designation: ref.designation || "",
        })),
      };

      // Call the API with the payload
      const response = await propertyLoanStoreReferncesAPI(payload);

      // Check the response and take action accordingly
      if (response.status === 200) {
        // Handle success, you can navigate or trigger the next action
        if (response.data.businessStatusCode === 2) {
          changeApplicantStatus(response?.data?.nextPage);
        } else if (response.data.businessStatusCode === 1) {
          toast.error(response.data.message);
        }
      } else {
        // Handle other response statuses if needed
        toast.error(response.data.message);
      }
    } catch (error) {
      // Handle errors
      handleApiError(error);
      console.error("Error fetching loan data:", error);
    } finally {
      // Reset loading state after the operation is complete
      setIsLoading(false);
      setIsButtonClicked(false);
    }
  };

  const [errors, setErrors] = useState([]);
  console.log(referenceThreshold);

  const goToSummaryPage = () => {
    changeApplicantStatus("");
  };
  return (
    <>
      <form className="kycform">

        <div className="d-flex justify-content-between align-items-center py-2">
          <h6>References</h6>
          <div
            onClick={goToSummaryPage}
            className="text fw-bold"
            style={{
              color: "orange",
              marginLeft: "auto",
              textDecoration: "none",
              cursor: "pointer",
            }}
          >
            Go To Applicant Summary
          </div>
        </div>
        <p className="note-text" style={{ color: "#2a2a2a" }}>
          References cannot be from relatives. Minimum required:
          {referenceThreshold?.data?.referenceThreshold?.professional?.minCount} professional and
          {referenceThreshold?.data?.referenceThreshold?.personal?.minCount} personal references.
        </p>
        {referenceData.map((reference, index) => (
          <div key={index}>

            <div className="d-flex mt-3 " style={{ justifyContent: 'space-between' }}>
              <h6 className="mt-4">Reference {index + 1} :</h6>
              <button
                type="button"
                onClick={() => clearReference(index)}
                title={`Clear Reference ${index + 1}`}
                style={{
                  backgroundColor: '#cccccc',
                  color: '#333333',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  fontSize: '14px',
                  transition: 'background-color 0.3s, color 0.3s',
                  marginTop: '12px',
                }}

                onMouseOver={(e) => {
                  e.currentTarget.style.backgroundColor = '#f44336';
                  e.currentTarget.style.color = 'white';
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.backgroundColor = '#cccccc';
                  e.currentTarget.style.color = '#333333';
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
            <div className="row mt-4">

              <div className="col-12 col-md-6">
                <ArthaFormControl fullWidth error={!!errors[index]?.referenceType}>
                  <InputLabel
                    id={`relationship-label`}
                    style={{
                      color: errors[index]?.referenceType ? "rgb(210, 74, 74)" : undefined,
                    }}
                  >
                    Reference Type
                  </InputLabel>
                  <Select
                    labelId={`relationship-label`}
                    id={`referenceType`}
                    name={`referenceType`}
                    value={reference.referenceType}
                    onChange={(e) =>
                      handleInputChange(index, "referenceType", e.target.value)
                    }
                    error={!!errors[index]?.referenceType} // Ensures the select field shows an error state
                  >
                    <MenuItem value="personal">Personal</MenuItem>
                    <MenuItem value="professional">Professional</MenuItem>
                    {dropdownOptions.map((option, i) => (
                      <MenuItem key={i} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors[index]?.referenceType && (
                    <p className="error-text" style={{ color: "rgb(210, 74, 74)" }}>
                      {errors[index].referenceType}
                    </p>
                  )}
                </ArthaFormControl>
              </div>

              <div className="col-12 col-md-6">
                <ArthaTextField
                  name={`name`}
                  fullWidth
                  label={`Reference Name`}
                  value={reference.name}
                  onChange={(e) =>
                    handleInputChange(index, "name", e.target.value)
                  }
                  error={!!errors[index]?.name}
                  helperText={errors[index]?.name}
                />
              </div>

              <div className="col-12 col-md-6 mt-3">
                <ArthaTextField
                  name={`contactNumber`}
                  fullWidth
                  label={`Contact Number (Landline/Mobile No)`}
                  inputProps={{ maxLength: 10 }}
                  onKeyPress={handleKeyPress}
                  value={reference.contactNumber}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      "contactNumber",
                      e.target.value
                    )
                  }
                  error={!!errors[index]?.contactNumber}
                  helperText={errors[index]?.contactNumber}
                />
              </div>

              <div className="col-12 col-md-6 mt-3">
                <ArthaTextField
                  name={`address`}
                  min={5}
                  max={350}
                  fullWidth
                  label={`Address `}
                  value={reference.address}
                  onChange={(e) =>
                    handleInputChange(index, "address", e.target.value)
                  }
                  error={!!errors[index]?.address}
                  helperText={errors[index]?.address}
                />
              </div>

              <div className="col-12 col-md-6 mt-3">
                <ArthaTextField
                  name={`email`}
                  fullWidth
                  label={`Email (Optional)`}
                  value={reference.email}
                  onChange={(e) =>
                    handleInputChange(index, "email", e.target.value)
                  }
                  error={!!errors[index]?.email}
                  helperText={errors[index]?.email}
                />
              </div>

              {reference.referenceType === "professional" && (
                <div className="col-12 col-md-6 mt-3">
                  <ArthaFormControl fullWidth>
                    <ArthaInputLabel id="designation-label">Designation</ArthaInputLabel>
                    <Select
                      labelId="designation-label"
                      id="designation"
                      name="designation"
                      required
                      value={reference.designation}
                      onChange={handleChange(index)} // Pass the index here
                    >
                      {designationDropdown &&
                        designationDropdown.map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                    </Select>
                  </ArthaFormControl>
                </div>
              )}

            </div>
          </div>
        ))}

        <div className="row mt-4">
          <div className="col-6 d-flex justify-content-end">
            <ArthaClearButton variant="contained" onClick={handleBack}>
              Back
            </ArthaClearButton>
          </div>
          <div className="col-6 d-flex justify-content-start">
            <ArthaContinueButton
              variant="contained"
              onClick={handleContinue}
              disabled={
                isLoading ||
                isButtonClicked ||
                !isFormValid()
              }
            >
              {isLoading ? (
                <LoadingSpinner style={{ pointerEvents: "none" }} />
              ) : (
                "Continue"
              )}
            </ArthaContinueButton>
          </div>
        </div>
      </form>
    </>
  );
}
