import React, { useEffect, useRef, useState } from "react";
import "./personalLoan.css";
import ArthaTextField from "../../fields/ArthaTextField";
import ArthaFormControl from "../../fields/ArthaFormControl";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import {
    Button,
    FormControlLabel,
    FormControl,
    Dialog,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import UploadDocument from "./UploadDocument";
import { FaExclamationCircle, FaCheckCircle } from "react-icons/fa";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import ArthaClearButton from "../../fields/ArthaClearButton";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import AddBank from "./AddBank";
import { useNavigate } from "react-router-dom";
import { handleSignOut } from "./SessionExpiredApi";
import PreviousSalariedEmpDetails from "./PreviousSalariedEmpDetails";
import dayjs from "dayjs";
import Refernces from "./Refences";
import UploadSupportiveDocs from "./uploadSupportiveDocument";
import LoadingSpinner from "../../fields/spinner";
import UploadSupportiveBL from "./UploadSupportiveBL";
import UploadSingleDocument from "./uploadSingleDocument";

import {
    // businessLoanVerifyGSTN,
    // businessLoanFetchAddressApi,
    // businessLoanFetchProfessionalDetailsAPI,
    // businessLoanStoreProfessionalDetailsAPI,
    // businessLoanEmailVerification,
    // businessLoanEmailOtpValidation,
    // businessLoanFetchDataAPI,
    // BusinessfetchDistStateAPI,
    propertyLoanfetchProfessionalDetailsAPI,
    propertyLoanStoreProfessionalDetailsAPI,
    propertyLoanVerifyGSTN,
    propertyLoanEmailVerification,
    propertyLoanEmailOtpValidation,
    propertyLoanFetchDataAPI,
    propertyLoanfetchDistStateAPI,
    propertyLoanFetchAddressAPI,
    propertyLoanFetchAddressApi,
    propertyLoanBackPageAPI,
    propertyLoanUdyamVerificationAPI
} from "../../actioncreators/actioncreators";
import { toast } from "react-toastify";

const QualificationExpALL = ({
    onSubmit,
    onBack,
    onCancel,
    onContinue,
    changeStatus,
    changeApplicantStatus,
    setActiveStep,
    setItrFlagDetails,
    uidprops,
}) => {
    const [isLoading1, setIsLoading1] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [isLoading3, setIsLoading3] = useState(false);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [gstnVerified, setGstnVerified] = useState(false);
    const [udyamData, setUdyamData] = useState(null);
    const [isUdyamVerified, setIsUdyamVerified] = useState(false);
    const [isVerifyingUdyam, setIsVerifyingUdyam] = useState(false);
    const [isUdyamInvalid, setIsUdyamInvalid] = useState(false);
    const [isValidGST, setIsValidGST] = useState(true);
    const [isGSTVerified, setIsGSTVerified] = useState(false);
    const [isVerifying, setIsVerifying] = useState(false);
    const [gstTouched, setGstTouched] = useState(false);
    const navigate = useNavigate();
    const [udyamError, setUdyamError] = useState(false);
    const [showReference, setShowReference] = useState(false);
    const [response, setResponse] = React.useState([]);
    const [isUserInteracted, setIsUserInteracted] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(false);
    const [addresses, setAddresses] = React.useState([]);
    const [emailTouched, setEmailTouched] = useState(false);
    const [firmDetails, setFirmDetails] = React.useState({});
    const [selectedPermanentAddress, setSelectedPermanentAddress] = useState("");
    const [registrationDocumentTypes, setRegistrationDocumentTypes] =
        React.useState([
            "Shop Act License / Udyam Aadhaar proof",
            "Photo of Firm / Business (Outside Premises with business name)",
            "Photo of Firm / Business (Inside Premises)",
            "Firm / Business Address Proof",
        ]);

    const [registrationDocumentTypes1, setRegistrationDocumentTypes1] =
        React.useState([
            "Certificate of Registration"
        ]);
    const [documents, setDocuments] = useState([]);
    const [showAdditionalDiv, setShowAdditionalDiv] = useState(false);
    const [errorDisplayed, setErrorDisplayed] = useState(false);
    const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed
    const [resendTimer, setResendTimer] = useState(120);
    const [isResendClickable, setIsResendClickable] = useState(false);
    const [apiError, setApiError] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if (resendTimer > 0) {
            const intervalId = setInterval(() => {
                setResendTimer((prevTimer) => {
                    if (prevTimer <= 0) {
                        clearInterval(intervalId);
                        setIsResendClickable(true);
                        return 0;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, [resendTimer]);

    const handleApiError = (error) => {
        if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed

        setErrorDisplayed(true); // Set the state to true
        errorDisplayedRef.current = true; // Set the ref to true

        if (error.response && error.response.status === 401) {
            handleSignOut();
            toast.error(
                "Your session has expired. You will be redirected to the Login Page."
            );
            navigate("/");
        } else if (error.response && error.response.status === 429) {
            toast.error(
                "Too Many Requests: You have exceeded the rate limit. Please try again later."
            );
        } else {
            toast.error(
                "There appears to be a technical issue connecting to our servers. Could you please try again later."
            );
        }

        // Reset the error flag after a certain time
        setTimeout(() => {
            setErrorDisplayed(false);
            errorDisplayedRef.current = false;
        }, 2000); // 2 seconds
    };
    //   useEffect(() => {
    //     const fetchProfessionalDetails = async () => {
    //       try {
    //         const fetchResponse = await propertyLoanfetchProfessionalDetailsAPI();
    //         console.log(fetchResponse);
    //         setResponse(fetchResponse.data);

    //         const firmDetailsData = fetchResponse.data.firmDetails;
    //         if (firmDetailsData && firmDetailsData.length > 0) {
    //           const firmDetail = firmDetailsData[0];
    //           setFirmDetails(firmDetail);
    //           const email = firmDetail.officialEmail;
    //           setEmailAdded(email);
    //           const gstn = firmDetail.gstNumber;
    //           setGstnVerified(gstn);
    //           const address = firmDetail.address;
    //           const bankAccDetails = firmDetail.bankAccDetails;
    //           setBankDetails(
    //             bankAccDetails && Object.keys(bankAccDetails).length > 0
    //           );
    //           // setAddressAdded(address && Object.keys(address).length > 0);
    //         } else {
    //           // setAddressAdded(false);
    //           setBankDetails(false);
    //         }
    //       } catch (error) {
    //         handleApiError(error);
    //         console.error("Error fetching user data:", error);
    //       }
    //     };

    //     fetchProfessionalDetails();
    //   }, [navigate]);

    // useEffect(() => {
    //     const fetchProfessionalDetails = async () => {
    //         try {
    //             const fetchResponse = await propertyLoanfetchProfessionalDetailsAPI();
    //             console.log(fetchResponse);
    //             const data = fetchResponse.data;

    //             // Set individual form fields using setFormData or other setters
    //             setFormData((prevFormData) => ({
    //                 ...prevFormData,
    //                 qualification: data.qualificationDetails.qualification || "", 
    //                 totalExperience: data.qualificationDetails.totalExperience || "", 
    //                 qualificationYear: data.qualificationYear || null, 
    //                 registrationNumber: data.qualificationDetails.registrationNumber || "",
    //                 medicalCouncil: data.qualificationDetails.medicalCouncil || "",
    //                 registrationYear: data.registrationYear || null,
    //                 monthlyIncome: data.monthlyIncome || "",
    //                 monthlyObligation: data.monthlyObligation || "",
    //                 udyamAadhaar: data.udyamAadhaar || "",
    //             }));

    //             const firmDetailsData = data.firmDetails;

    //             if (firmDetailsData && firmDetailsData.length > 0) {
    //                 const firmDetail = firmDetailsData[0];

    //                 // Parse `workfrom` with Day.js to ensure it's a valid date object
    //                 const workfromDate = firmDetail.workfrom ? dayjs(firmDetail.workfrom) : null;

    //                 // Update firm details specific fields
    //                 setCurrentEmployeer((prevCurrentEmployeer) => ({
    //                     ...prevCurrentEmployeer,
    //                     gstNumber: firmDetail.gstNumber || "",
    //                     shopActNumber: firmDetail.shopActNumber || "",
    //                     firmName: firmDetail.firmName || "",
    //                     natureOfBusiness: firmDetail.natureOfBusiness || "",
    //                     firmVintage: firmDetail.firmVintage || "",
    //                     numberOfEmployeesInFirm: firmDetail.numberOfEmployeesInFirm || "",
    //                     incorporationMonth: firmDetail.incorporationMonth ? dayjs(firmDetail.incorporationMonth) : null,
    //                     firmPremisesOwner: firmDetail.firmPremisesOwner || "",
    //                     firmPremisesVintage: firmDetail.firmPremisesVintage || "",
    //                     yearsInCurrentProfession: firmDetail.yearsInCurrentProfession || "",
    //                     workfrom: workfromDate,  // Set workfrom as a Day.js object
    //                 }));

    //                 // Set additional fields like email, address, bank details
    //                 const email = firmDetail.officialEmail;
    //                 setEmailAdded(!!email);

    //                 const gstn = firmDetail.gstNumber;
    //                 setGstnVerified(!!gstn);

    //                 const address = firmDetail.address;
    //                 const bankAccDetails = firmDetail.bankAccDetails;
    //                 setBankDetails(!!(bankAccDetails && Object.keys(bankAccDetails).length > 0));
    //                 setAddressAdded(!!(address && Object.keys(address).length > 0));
    //             } else {
    //                 // Set defaults for optional fields
    //                 setBankDetails(false);
    //                 setAddressAdded(false);
    //             }
    //         } catch (error) {
    //             handleApiError(error);
    //             console.error("Error fetching user data:", error);
    //         }
    //     };

    //     fetchProfessionalDetails();
    // }, [navigate]);
    useEffect(() => {
        const fetchProfessionalDetails = async () => {
            try {
                const fetchResponse = await propertyLoanfetchProfessionalDetailsAPI();
                setResponse(fetchResponse?.data);
                console.log("fetchResponse", fetchResponse?.data);
                console.log("fetchResponse", fetchResponse?.data?.firmDetails[0].businessId);


                if (fetchResponse?.data?.businessStatusCode === 2) {
                    const requestBody = {
                        businessId: fetchResponse?.data?.firmDetails[0].businessId,
                    };
                    try {
                        const fetchAddress = await propertyLoanFetchAddressApi(requestBody);

                        setAddresses(fetchAddress?.data?.addresses);
                        // Check if any address is selected by the user
                        const selectedAddress = fetchAddress?.data?.addresses?.find(
                            (address) => address.selectedByUser
                        );
                        // If an address is selected by the user, set it as the current address
                        if (selectedAddress) {
                            setCurrentAddress({
                                line1: selectedAddress.line1,
                                line2: selectedAddress.line2,
                                pincode: selectedAddress.pin,
                                city: selectedAddress.dist,
                                state: selectedAddress.state,
                            });
                            setSelectedAddress(true);
                        }
                    } catch (error) {
                        handleApiError(error);
                        console.error("Error fetching user data:", error);
                    }
                }
                const firmDetailsData = fetchResponse?.data?.firmDetails;
                if (firmDetailsData && firmDetailsData.length > 0) {
                    const firmDetail = firmDetailsData[0];
                    console.log("New firmDetail", firmDetail);

                    setFirmDetails(firmDetail);
                    const email = firmDetail?.officialEmail;
                    setEmailAdded(email);
                    const gstn = firmDetail?.gstNumber;
                    setGstnVerified(gstn);
                    const udyam = firmDetail?.udyamAadhaar;
                    setIsUdyamVerified(udyam);
                    const address = firmDetail?.address;
                    // setAddressAdded(address);
                    // const address = firmDetail?.address;

// Explicitly check if address has meaningful fields
const isAddressValid =
    address &&
    Object.keys(address).length > 0 &&
    (address.line1 || address.pincode || address.city || address.state);

// Update the state accordingly
setAddressAdded(isAddressValid);

// If the address is valid, set it as the current address
if (isAddressValid) {
    setCurrentAddress({
        line1: address.line1 || "",
        line2: address.line2 || "",
        pincode: address.pincode || "",
        city: address.city || "",
        state: address.state || "",
    });
    setSelectedAddress(true);
} else {
    setCurrentAddress(null);
    setSelectedAddress(false);
}

                    if (address) {
                        setCurrentAddress({
                            line1: address.line1,
                            line2: address.line2,
                            pincode: address.pin,
                            city: address.dist,
                            state: address.state,
                        });
                        setSelectedAddress(true);
                    }
                    const bankAccDetails = firmDetail?.bankAccDetails;
                    setBankDetails(bankAccDetails && Object.keys(bankAccDetails).length > 0 ? bankAccDetails : false);

                    const certificateDoc = fetchResponse?.data?.qualificationDetails?.registrationCertificateDoc;
                    console.log("certificateDoc", certificateDoc);
                    setRegistrationDocument(certificateDoc);

                    setSelectedDocs({ document: firmDetail?.documents });
                } else {
                    setAddressAdded(false);
                    setBankDetails(false);
                }
            } catch (error) {
                handleApiError(error);
                console.error("Error fetching user data:", error);
            }
        };

        fetchProfessionalDetails();
    }, [navigate]);

    const [qualificationDropdown, setQualificationDropdown] = React.useState([]);
    const [totalExpDropdown, setTotalExpDropdown] = React.useState([]);
    const [natureOfBusinessDropdown, setNatureOfBusinessDropdown] =
        React.useState([]);
    const [medicalCouncilDropdown, setMedicalCouncilDropdown] = React.useState(
        []
    );
    const [firmVintageOwnerDropdown, setFirmVintageOwnerDropdown] =
        React.useState([]);
    const [firmPrimisesAreaDropdown, setFirmPrimisesAreaDropdown] =
        React.useState([]);
    const [firmVintageDropdown, setFirmVintageDropdown] = React.useState([]);
    const [noOfEmployeesDropdown, setNoOfEmployeesDropdown] = React.useState([]);
    const [firmPremisesVintageDropdown, setFirmPremisesVintageDropdown] =
        React.useState([]);
    const [currentProfessionYearsDropdown, setCurrentProfessionYearsDropdown] =
        React.useState([]);
    const [openBankDialog, setOpenBankDialog] = React.useState(false);
    const [bankDetails, setBankDetails] = React.useState(false);
    // const [selectedDocs, setSelectedDocs] = React.useState({});
    const [selectedDocs, setSelectedDocs] = useState({ document: [] });
    const [selectedPrevDocs, setSelectedPrevDocs] = React.useState({});

    const removeExistingDoc = (documentType) => {
        // Check if the document type matches the registration document type
        if (documentType === 'Certificate of Registration') {
            // Clear the registration document state
            setRegistrationDocument(null);
            checkFormValid(); // Revalidate the form after removing the document
        }

        // Continue to remove the document from the selectedDocs state if needed
        setSelectedDocs((prevState) => {
            const updatedDocuments = prevState.document?.filter(
                (doc) => doc.documentType !== documentType
            );

            return {
                ...prevState,
                document: updatedDocuments,
            };
        });
    };



    const [formData, setFormData] = React.useState({
        qualification: "",
        totalExperience: "",
        officialEmail: "",
        qualificationYear: "",
        monthlyIncome: "",
        monthlyObligation: "",
        medicalCouncil: "",
        registrationNumber: "",
        registrationYear: "",
        udyamAadhaar: "",
        registrationCertificateDoc: ""
    });



    console.log("firmDetails", firmDetails);

    const [currentEmployeer, setCurrentEmployeer] = React.useState({
        workfrom: "",
        worktill: "",
        businessId: "",
        gstNumber: "",
        // udyamAadhaar: "",
        shopActNumber: "",
        firmName: "",
        natureOfBusiness: "",
        firmVintage: "",
        numberOfEmployeesInFirm: "",
        yearsInCurrentProfession: "",
        // registrationNumber: "",
        firmPremisesVintage: "",
        incorporationMonth: null,
        firmPremisesOwner: "",
        businessPremisesArea: "",
        shopActNumber: "",
        selectedDocs: "",
        firmName: "",
        gstNumber: "",
        udyamAadhaar: "",
    });

    React.useEffect(() => {
        const initialFormData = {
            qualification: response?.qualificationDetails?.qualification || "",
            totalExperience: response?.qualificationDetails?.totalExperience || "",
            officialEmail: firmDetails?.officialEmail || "",
            udyamAadhaar: firmDetails?.udyamAadhaar || "",
            medicalCouncil: response?.qualificationDetails?.medicalCouncil || "",
            monthlyIncome: response?.monthlyIncome || "",
            monthlyObligation: response?.monthlyObligation || "",
            qualificationYear: response?.qualificationDetails?.qualificationYear
                ? dayjs().year(response.qualificationDetails.qualificationYear)
                : null,
            registrationYear: response?.qualificationDetails?.registrationYear
                ? dayjs().year(response.qualificationDetails.registrationYear)
                : null,
            registrationNumber: response?.qualificationDetails?.registrationNumber || "",
        };

        const initialEmployerData = {
            workfrom: firmDetails?.workfrom ? dayjs(firmDetails.workfrom) : "",
            worktill: firmDetails?.worktill ? dayjs(firmDetails.worktill) : "",
            businessId: firmDetails?.businessId || "",
            gstNumber: firmDetails?.gstNumber || "",
            udyamAadhaar: firmDetails?.udyamAadhaar || "",
            shopActNumber: firmDetails?.shopActNumber || "",
            firmName: firmDetails?.firmName || "",
            natureOfBusiness: firmDetails?.natureOfBusiness || "",
            firmVintage: firmDetails?.firmVintage || "",
            numberOfEmployeesInFirm: firmDetails?.numberOfEmployeesInFirm || "",
            yearsInCurrentProfession: firmDetails?.DateCount || "",
            incorporationMonth: firmDetails?.incorporationMonth
                ? dayjs(firmDetails.incorporationMonth)
                : "",
            firmPremisesOwner: firmDetails?.firmPremisesOwner || "",
            businessPremisesArea: firmDetails?.businessPremisesArea || "",
            firmPremisesVintage: firmDetails?.firmPremisesVintage || "",
        };

        setFormData(initialFormData);
        setCurrentEmployeer(initialEmployerData);

        setCurrentAddress({
            line1: firmDetails?.address?.line1 || "",
            line2: firmDetails?.address?.line2 || "",
            pincode: firmDetails?.address?.pincode || "",
            city: firmDetails?.address?.city || "",
            state: firmDetails?.address?.state || "",
        });
    }, [response, firmDetails]);

    //   const [prevSalariedEmployer, setPrevSalariedEmployer] = React.useState({
    //     companyName: "",
    //     employerType: "",
    //     employerWebsite: "",
    //     designation: "",
    //     workfrom: null,
    //     worktill: null,
    //     bankName: "",
    //     accNo: "",
    //   });
    const [isFormValid, setIsFormValid] = useState(false);

    const handleChange = (e) => {
        setGstn(e.target.value);
    };

    const handleChange2 = (e) => {
        const { name, value } = e.target;
        let updatedValue = value.trim();

        // Update the form data for the field
        setFormData((prevData) => ({
            ...prevData,
            [name]: updatedValue,
        }));

        // Mark user interaction
        setIsUserInteracted(true);

        if (name === "udyamAadhaar") {
            // setIsUdyamVerified(false); // Reset verification status
            // setIsUdyamInvalid(false); // Reset invalid state

            // Format value and validate Udyam Aadhaar
            updatedValue = updatedValue.toUpperCase(); // Ensure uppercase
            const isUdyamValid = /^UDYAM-[A-Za-z]{2}-\d{2}-\d{7}$/.test(updatedValue);

            // Update form data with formatted value
            setFormData((prevData) => ({
                ...prevData,
                udyamAadhaar: updatedValue,
            }));

            // Set validation error if invalid
            if (!isUdyamValid && updatedValue.length > 0) {
                setUdyamError(
                  "Invalid Udyam Number. Please enter in the specified format."
                );
                setIsVerifyingUdyam(false);
                setIsUdyamInvalid(false);
                setIsUdyamVerified(false);
              } else {
                setUdyamError("");
              }
              
              if (updatedValue.length === 0) {
                // Clear the error if udyamAadhaar is empty
                setUdyamError("");
                setIsUdyamInvalid(false);
                setIsVerifyingUdyam(false);
                setIsUdyamVerified(false);
              }

            // Clear dependent fields if Udyam Aadhaar is invalid or removed
            if (!isUdyamValid || updatedValue === "") {
                setCurrentEmployeer((prev) => ({
                    ...prev,
                    firmName: "",
                }));
                setFormData((prevData) => ({
                    ...prevData,
                    businessName: "",
                    commencementDate: "",
                }));
            } else {
                // Fetch and map name for valid Udyam Aadhaar
                const fetchedUdyamName = "Name from Udyam API"; // Replace with actual API call
                setFormData((prevData) => ({
                    ...prevData,
                    businessName: fetchedUdyamName,
                }));
            }
        } else if (name === "gstNo") {
            // Logic for handling GST Number
            const isGstValid = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}[Z]{1}[A-Z0-9]{1}$/.test(updatedValue);

            if (isGstValid) {
                // Fetch and map name for valid GST Number
                const fetchedGstName = "Name from GST API"; // Replace with actual API call
                setFormData((prevData) => ({
                    ...prevData,
                    businessName: fetchedGstName,
                }));
            } else if (updatedValue === "" && formData.udyamAadhaar) {
                // Restore name from Udyam Aadhaar if GST Number is cleared
                const restoredUdyamName = "Name from Udyam API"; // Replace with actual API call
                setFormData((prevData) => ({
                    ...prevData,
                    businessName: restoredUdyamName,
                }));
            } else if (updatedValue === "" && !formData.udyamAadhaar) {
                // Clear name if both GST and Udyam Aadhaar are empty
                setFormData((prevData) => ({
                    ...prevData,
                    businessName: "",
                }));
            }

            // Update GST Number in form data
            setFormData((prevData) => ({
                ...prevData,
                gstNo: updatedValue,
            }));
        }

        // Add additional field-specific logic if necessary
    };





    const formatDate = (inputDate) => {
        console.log("input date : ", inputDate);
        if (inputDate) {
            const [day, month, year] = inputDate.split("/");
            if (!/^\d{4}$/.test(year)) {
                console.error("Invalid year format");
                return "";
            }

            return `${year}-${month}-${day}`;
        } else {
            return "";
        }
    };
    const handleBlur = async () => {
        if (isUserInteracted && formData.udyamAadhaar === "" && !formData.gstNumber) {
            // Set firmName to null when both fields are empty
            setCurrentEmployeer((prev) => ({ ...prev, firmName: null }));
            return;
        }

        if (isUserInteracted && formData.udyamAadhaar && !udyamError) {
            setIsVerifyingUdyam(true);
            const requestBody = {
                udyamNumber: formData.udyamAadhaar,
                businessId: firmDetails?.businessId,
            };

            try {
                const response = await propertyLoanUdyamVerificationAPI(requestBody);

                if (response?.data?.httpResponseCode === 200) {
                    if (response?.data?.businessStatusCode === 2) {
                        setIsUdyamVerified(true);
                        setIsUdyamInvalid(false);
                        const { businessName } = response.data; // Fetch Udyam firm name
                        setCurrentEmployeer((prev) => ({
                            ...prev,
                            firmName: businessName, // Prioritize Udyam firm name
                        }));
                    } else if (response?.data?.businessStatusCode === 1) {
                        setIsUdyamInvalid(true);
                        setIsUdyamVerified(false);
                    }
                } else {
                    setIsUdyamVerified(false);
                    setIsUdyamInvalid(true);
                }
            } catch (error) {
                handleApiError(error);
                setIsUdyamVerified(false);
                setIsUdyamInvalid(true);
            } finally {
                setIsVerifyingUdyam(false);
                setIsButtonClicked(false);
                setIsLoading(false);
            }
        }
    };


    const [selectedYear, setSelectedYear] = useState(null);
    const fileInputRefID = useRef(null);
    const [isPopupOpen, setPopupOpen] = useState(false);
    // const [registrationDocument, setRegistrationDocument] = React.useState({});
    const [registrationDocument, setRegistrationDocument] = useState(null);

    const [gstn, setGstn] = React.useState("");
    const handleYearChange = (date) => {
        if (date) {
            setSelectedYear(date);
            setFormData({
                ...formData,
                qualificationYear: date,
            });
        }
    };

    const handleFileChangeID = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setFormData({
                    ...formData,
                    registrationCertificateDoc: {
                        ...formData.registrationCertificateDoc,
                        documentData: e.target.result.split(",")[1], // Assuming base64 data
                        documentName: file.name,
                        documentType: file.type,
                    },
                });
            };
            reader.readAsDataURL(file);
        }
    };

    const handleUploadDocument = () => {
        setPopupOpen(true);
    };

    // const handlePopupClose = (selectedFileNames) => {
    //   console.log(selectedFileNames);

    //   if (selectedFileNames.length > 0) {
    //     setSelectedDocs((prevSelectedEmployer) => {
    //       // Filter out documents that have the same documentType as the new ones
    //       const filteredDocuments = prevSelectedEmployer.document
    //         ? prevSelectedEmployer.document.filter(
    //           prevDoc => !selectedFileNames.some(newDoc => newDoc.documentType === prevDoc.documentType)
    //         )
    //         : [];

    //       // Merge the filtered documents with the new documents
    //       return {
    //         ...prevSelectedEmployer,
    //         document: [...filteredDocuments, ...selectedFileNames],
    //       };
    //     });
    //   }

    //   setPopupOpen(false);
    // };
    // const handlePopupClose = (selectedFileNames) => {
    //   console.log(selectedFileNames);

    //   if (selectedFileNames.length > 0) {
    //     setSelectedDocs((prevSelectedEmployer) => {
    //       // Filter out documents that have the same documentType AND fileName as the new ones
    //       const filteredDocuments = prevSelectedEmployer.document
    //         ? prevSelectedEmployer.document.filter(
    //           prevDoc => !selectedFileNames.some(newDoc => 
    //             newDoc.documentType === prevDoc.documentType && 
    //             newDoc.fileName === prevDoc.fileName
    //           )
    //         )
    //         : [];

    //       // Merge the filtered documents with the new documents
    //       return {
    //         ...prevSelectedEmployer,
    //         document: [...filteredDocuments, ...selectedFileNames],
    //       };
    //     });
    //   }

    //   setPopupOpen(false);
    // };
    const handlePopupClosePopup = () => {
        setOpenOTPDialog(false);
    };

    const handlePopupClose = (selectedFileNames) => {
        console.log(selectedFileNames);

        if (selectedFileNames.length > 0) {
            setSelectedDocs((prevSelectedEmployer) => {
                const filteredDocuments = prevSelectedEmployer.document
                    ? prevSelectedEmployer.document?.filter(
                        prevDoc => !selectedFileNames.some(newDoc =>
                            newDoc.documentType === prevDoc.documentType &&
                            newDoc.fileName === prevDoc.fileName
                        )
                    )
                    : [];

                // Add only the new documents to the filtered list
                const uniqueDocuments = [
                    ...filteredDocuments,
                    ...selectedFileNames?.filter(newDoc =>
                        !filteredDocuments.some(prevDoc =>
                            prevDoc.documentType === newDoc.documentType &&
                            prevDoc.fileName === newDoc.fileName
                        )
                    )
                ];

                return {
                    ...prevSelectedEmployer,
                    document: uniqueDocuments,
                };
            });
        }

        setPopupOpen(false);
    };




    // const onSave = (obj) => {
    //     setRegistrationDocument(obj);
    //     setPopupOpen(false);
    // };
    const onSave = (uploadedDocument) => {
        setRegistrationDocument(uploadedDocument);
        handleRegitrationPopupClose(); // close the popup after saving
    };


    const [verified, setVerified] = useState(false);
    const [shopActNoVerified, setShopActNoVerified] = React.useState(false);


    const handleVerification = async (e) => {
        const { name, value } = e.target;
        setGstTouched(true);

        
        if (name === "gstNumber") {
            if (value === "") {
                setGstnVerified(false);
                setIsValidGST(true); // GST is optional, so set it as valid when empty
                setCurrentEmployeer((prev) => ({
                    ...prev,
                    firmName: isUdyamVerified ? prev.firmName : null, // Set firmName to null if Udyam is also not verified
                }));
            } else if(name === 'gstNumber' && value.length === 15) {
                setIsVerifying(true);

                const payload = {
                    gstin: value,
                    businessId: firmDetails?.businessId,
                };
                try {
                    const response = await propertyLoanVerifyGSTN(payload);
            
                    if (response?.data?.httpResponseCode === 200) {
                      if (response?.data?.businessStatusCode === 2) {
                        setGstnVerified(true);
                        // setIsValidGST(true);
                        setIsValidGST(true); // GST is valid
                        setIsGSTVerified(false);
                        setIsVerifying(false);
                        const gstFirmName = response.data.firm_name; // Fetch GST firm name
                        setCurrentEmployeer((prev) => {
                            if (!isUdyamVerified) {
                                return { ...prev, firmName: gstFirmName }; // Set firm name if Udyam is not verified
                            }
                            return prev;
                        });
                      } else if (response?.data?.businessStatusCode === 1) {
                        setIsGSTVerified(true);
                        setGstnVerified(false);
                        setIsVerifying(false);
                      }
                    } else {
                      setGstnVerified(false);
                      // setIsValidGST(false);
                      setIsVerifying(false);
                    }
                  } catch (error) {
                    handleApiError(error);
                    setGstnVerified(false);
                    setIsValidGST(false); 
                    setIsVerifying(false);
                }
                
            }
            
        }else {
            setIsVerifying(false);
          }
    };







    const handleVerification1 = async (e) => {
        const { name, value } = e.target;
        setGstTouched(true);
        if (name === "shopActNumber") {
            if (value === "") {
                setShopActNoVerified(false);
            }
        }
    };

    //email dialog code started
    const [isOpenEmailDialog, setOpenEmailDialog] = useState(false);
    const [isEmailAdded, setEmailAdded] = React.useState(false);
    const [otp, setOtp] = useState(["", "", "", "", "", ""]);
    const [isInvalidOtp, setIsInvalidOtp] = useState(true);
    const inputRefs = useRef([]);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const handleInputKeyDown = (index, e) => {
        if (e.key === "Backspace" && !otp[index] && index > 0) {
            // Move focus to the previous input on Backspace
            inputRefs.current[index - 1].focus();
        }
    };
    const closeEmailDialog = () => {
        setOpenEmailDialog(false);
        if (!isEmailAdded) {
            setFormData({
                ...formData,
                officialEmail: "",
            });
        }
    };

    const [isVerifyButtonDisabled, setIsVerifyButtonDisabled] = useState(true);
    const handleVerifyClick = async (e) => {
        setIsButtonClicked(true);
        setIsLoading2(true);

        e.preventDefault();

        // setOpenOTPDialog(false);
        // setOpenEmailDialog(false);
        const requestBody = {
            otp: otp.join(""), // Join the otp array into a string
        };

        if (otp.join("") === "") {
            // Check if otp is empty
            setIsInvalidOtp(true);
            inputRefs.current[0].current.focus();
            return;
        }

        try {
            const response = await propertyLoanEmailOtpValidation(requestBody);
            if (
                response.data.httpResponseCode === 200 &&
                response.data.businessStatusCode === 2
            ) {
                setOpenOTPDialog(false);
                setOpenEmailDialog(false);
                setEmailAdded(true);
                toast.success("Email Added Successfully.");
            } else if (response.data.httpResponseCode === 200 && response.data.businessStatusCode === 1) {
                setOpenOTPDialog(true);
                // setIsInvalidOtp(true);
                toast.error(response.data.message);
            } else {
                console.log("Unexpected response status:", response.status);
                setEmailAdded(false);
            }
        } catch (error) {
            handleApiError(error);
            console.error("Error during OTP verification:", error);
        } finally {
            // Reset loading state after the operation is complete
            setIsLoading2(false);
            setIsButtonClicked(false);
        }
        //TODO1 remove below two lines
        // setOpenOTPDialog(false);
        // setEmailAdded(true);
    };

    useEffect(() => {
        inputRefs?.current[0]?.current?.focus();
        const timerStartTime = parseInt(localStorage.getItem("timerStartTime"));
        if (timerStartTime) {
            const elapsedTime = Math.floor((Date.now() - timerStartTime) / 1000);
            const remainingTime = Math.max(0, 120 - elapsedTime);
            setResendTimer(remainingTime);
        } else {
            saveTimerStartTime(); // Call saveTimerStartTime if timerStartTime doesn't exist in local storage
        }
    }, []);

    const saveTimerStartTime = () => {
        localStorage.setItem("timerStartTime", Date.now().toString());
    };

    const [isOpenOTPDialog, setOpenOTPDialog] = useState(false);
    const openOTPDialog = async () => {
        setOtp(["", "", "", "", "", ""]);
        setResendTimer(120);
        localStorage.removeItem("timerStartTime");
        saveTimerStartTime();


        const requestBody = { id: formData.officialEmail }
        try {
            const response = await propertyLoanEmailVerification(requestBody);
            if (response.data.httpResponseCode === 200 && response.data.businessStatusCode == 2) {
                setOpenOTPDialog(true);
                // setIsInvalidOtp(false);
                toast.success(response.data.message);
            } else {
                console.log("Unexpected response status:", response?.status);
            }

        } catch (error) {
            if (error.response && error?.response?.status === 401) {
                await handleSignOut();
                toast.error("Your Session has expired.You will be redirected to Login Page.")
                navigate('/')
            } else if (error.response && error?.response?.status === 429) {
                toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
            } else {
                toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
            }
            console.error("Error during OTP verification:", error);
        }
    }

    const handleReSendOtp = async () => {
        setOtp(["", "", "", "", "", ""]);
        setResendTimer(120);
        localStorage.removeItem("timerStartTime");
        saveTimerStartTime();

        const requestBody = { id: formData.officialEmail }

        try {
            // setOpenOTPDialog(true);
            const response = await propertyLoanEmailVerification(requestBody);
            // setOpenOTPDialog(true);
            // setIsInvalidOtp(false);
            if (response.data.httpResponseCode === 200 && response.data.businessStatusCode === 2) {
                setOpenOTPDialog(true);
                // setOtpResend(true);
                toast.success("OTP Resend Successfully");
            } else {
                toast.error(response.data.message);
                setApiError("Login failed. Invalid credentials.");
            }
        } catch (error) {
            handleApiError(error);
            setApiError("An error occurred while communicating with the server.");
        }
    };

    const handleOtpChange = (index, value) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (index < otp.length - 1 && value !== "") {
            // Move focus to the next input if not on the last input and a digit is entered
            inputRefs.current[index + 1].focus();
        }
        // const isAllFieldsFilled = newOtp.every((otpValue) => otpValue !== "");
        // // Enable or disable the "Continue" button based on the OTP fields status
        // setIsInvalidOtp(!isAllFieldsFilled);
        setIsVerifyButtonDisabled(newOtp.join("").length !== 6);
    };

    const isEmailValid = (email) => {
        return emailRegex.test(email);
    };
    //email dialog code ended

    //address dialog code started
    const [openAddressDialog, setOpenAddressDialog] = useState(false);
    const [isAddressAdded, setAddressAdded] = React.useState(false);
    // const [isDocumentAdded, setDocumentAdded] = React.useState(false);
    const [isRegistrationPopupOpen, setIsRegistrationPopupOpen] = useState(false);
    const [isDocumentAdded, setDocumentAdded] = useState(false);
    const [pincodeError, setPincodeError] = React.useState("");
    const [currentAddress, setCurrentAddress] = React.useState({
        line1: "",
        line2: "",
        pincode: "",
        city: "",
        state: "",
    });

    const openAddrDialog = () => {
        const fetchBusinessAddress = async () => {
            const requestBody = {
                businessId: response?.firmDetails?.[0]?.businessId,
            };
            try {
                const fetchAddress = await propertyLoanFetchAddressApi(requestBody);
                console.log("fetchAddress", fetchAddress?.data);
                setAddresses(fetchAddress?.data?.addresses);
            } catch (error) {
                handleApiError(error);
                console.error("Error fetching user data:", error);
            }
        };

        fetchBusinessAddress();
        setOpenAddressDialog(true);
        setPincodeError("");
    };

    const isPincodeValid = (pincode) => /^\d{6}$/.test(pincode);
    const isCityValid = (city) => /^[a-zA-Z\s]+$/.test(city);
    const isStateValid = (state) => /^[a-zA-Z\s]+$/.test(state);
    const [isFormFilled, setIsFormFilled] = useState(false);

    const handleAddressChange = (e) => {
        const { name, value } = e.target;
        setCurrentAddress((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const fetchAddressDetails = async (pincode) => {
        try {
            let data = {
                pinCode: pincode,
            };
            const response = await propertyLoanfetchDistStateAPI(data);
            const { district, stateName } = response.data;
            setCurrentAddress((prevState) => ({
                ...prevState,
                city: district || "",
                state: stateName || "",
            }));
        } catch (error) {
            console.error("Error fetching address details:", error);
            setCurrentAddress((prevState) => ({
                ...prevState,
                city: "",
                state: "",
            }));
        }
    };


    const checkFormFilled = () => {
        if (!currentAddress) return;

        const { line1, line2, pincode, city, state } = currentAddress;

        // Validate each field
        const isLine1Filled = line1 && line1.trim() !== "" && /^[a-zA-Z0-9\s.,-_]+$/.test(line1);
        const isLine2Filled = line2 && line2.trim() !== "" && /^[a-zA-Z0-9\s.,-_]+$/.test(line2);
        const isPincodeFilled = pincode && pincode.trim() !== "" && /^\d{6}$/.test(pincode);
        const isCityFilled = city && city.trim() !== "" && /^[a-zA-Z\s]+$/.test(city);
        const isStateFilled = state && state.trim() !== "" && /^[a-zA-Z\s]+$/.test(state);

        // Additional validation checks
        let isPincodeValidated = isPincodeFilled && isPincodeValid(pincode);
        if (pincode === "000000") {
            isPincodeValidated = false;
        }
        setPincodeError(isPincodeValidated ? "" : "Invalid pincode");

        const isCityValidated = isCityFilled && isCityValid(city);
        const isStateValidated = isStateFilled && isStateValid(state);

        // Check if an address is selected or if new address form is displayed
        const isAnyAddressSelected = addresses?.some(address => address.selectedByUser);
        const isAddressFilled = isAnyAddressSelected || showAdditionalDiv;

        // Update form filled state
        const formIsValid = (
            (isLine1Filled && isLine2Filled && isPincodeFilled && isCityFilled && isStateFilled &&
                isPincodeValidated && isCityValidated && isStateValidated && isAddressFilled) ||
            isAnyAddressSelected
        );

        setIsFormFilled(formIsValid);
    };



    useEffect(() => {
        checkFormFilled();
    }, [
        formData,
        currentAddress,
        addresses,
        showAdditionalDiv,
        isPincodeValid,
        isCityValid,
        isStateValid,

    ]);



    const closeAddrDialog = () => {
        // Deselect all addresses
        addresses?.forEach((item) => {
            item.selectedByUser = false;
        });

        // Clear selected address and reset the address state
        // setSelectedAddress(null);

        // Close the dialog
        setOpenAddressDialog(false);

        // Reset current address if no address is added
        if (!isAddressAdded) {
            setCurrentAddress({
                businessAddrId: "",
                addressSource: "",
                line1: "",
                line2: "",
                pincode: "",
                city: "",
                state: "",
            });
        }
    };

    //address dialog ended

    const handleBack = async () => {
        try {
            const response = await propertyLoanBackPageAPI();
            if (response?.data?.businessStatusCode === 2) {
                changeApplicantStatus(response?.data?.nextPage);
            } else {
                toast.info(response.data.message);
            }
        } catch (error) {
            handleApiError(error);
            console.error("Error fetching loan data:", error);
        }
    };

    //add bank dialog started

    const cancelBankDialog = () => {
        setOpenBankDialog(false);
    };

    const onBankDataSave = (data) => {
        setBankDetails(data);
        setOpenBankDialog(false);
    };

    const convertMonthsToDays = (months) => {
        return months * 30;
    };

    const [totalDays, setTotalDays] = React.useState(
        response
            ? convertMonthsToDays(
                parseInt(response.acceptedEmployerTimePeriodInMonths, 10)
            )
            : 0
    );
    const [isPreviousEmpReq, setPreviousEmpReq] = React.useState(false);
    const [prevEmpType, setPrevEmpType] = React.useState("");
    const [prevBankDetails, setPrevBankDetails] = React.useState();
    const handleWorkingSince = (e) => {
        setCurrentEmployeer({
            ...currentEmployeer,
            workfrom: e,
        });
        let formattedDate = new Date(e).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });
        let dateStr = formattedDate;
        let dateParts = dateStr.split("/");
        let date = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
        let today = new Date();
        let differenceInTime = date.getTime() - today.getTime();
        let differenceInDays = Math.abs(
            Math.ceil(differenceInTime / (1000 * 3600 * 24))
        );
        if (differenceInDays < totalDays) {
            setPreviousEmpReq(true);
        } else {
            setPreviousEmpReq(false);
        }
    };

    const onPrevBankDetailsSave = (data) => {
        setPrevBankDetails(data);
    };
    //add bank dialog eb=nded

    const currentDate = dayjs();
    const endOfYear = dayjs().endOf("year");

    // const startdate = currentEmployeer.workfrom ? currentEmployeer.workfrom.format("YYYY-MM-DD") : "";
    // const enddate = currentDate.format("YYYY-MM-DD");
    // console.log(startdate);
    // console.log(enddate);

    // const caldiff = (startdate - enddate);
    // console.log(caldiff);

    const workfrom = currentEmployeer.workfrom
        ? currentEmployeer.workfrom.format("YYYY-MM-DD")
        : "";
    const worktill = currentDate.format("YYYY-MM-DD");

    console.log("workfrom:", workfrom);
    console.log("worktill:", worktill);

    // Calculate the difference in years
    const workfromDate = moment(workfrom, "YYYY-MM-DD");
    const worktillDate = moment(worktill, "YYYY-MM-DD");
    const differenceInYears = worktillDate.diff(workfromDate, "years", true);

    const DateCount1 = Math.floor(differenceInYears);

    // console.log("Difference in years:", differenceInYears);
    console.log("Difference in years:", DateCount1);
    const yearsInCurrentProfession1 = DateCount1.toString() || "";

    const handleContinue = async () => {
        setIsButtonClicked(true);
        setIsLoading3(true);

        let currentEmployeerPayload = {
            address: currentAddress,
            bankAccDetails: bankDetails,
            documents: selectedDocs.document,
            businessEmployerType: "Current Employer",
            businessId: currentEmployeer.businessId || "",
            empSource: "USER",
            firmName: currentEmployeer.firmName || "",
            firmVintage: currentEmployeer.firmVintage || "",
            ...(currentEmployeer.gstNumber
                ? { gstNumber: currentEmployeer.gstNumber }
                : {}),
            numberOfEmployeesInFirm: currentEmployeer.numberOfEmployeesInFirm || "",
            shopActNumber: currentEmployeer.shopActNumber || "",
            udyamAadhaar: formData.udyamAadhaar || "",
            officialEmail: formData.officialEmail,
            workfrom: currentEmployeer.workfrom
                ? currentEmployeer.workfrom.format("YYYY-MM-DD")
                : "",
            worktill: currentDate.format("YYYY-MM-DD"),
            firmClosureMonth: "",
            incorporationMonth:
                currentEmployeer.incorporationMonth.format("YYYY-MM-DD"),
            firmPremisesOwner: currentEmployeer.firmPremisesOwner || "",
            firmPremisesVintage: currentEmployeer.firmPremisesVintage || "",
            yearsInCurrentProfession: yearsInCurrentProfession1 || "",
            natureOfBusiness: currentEmployeer.natureOfBusiness || "",
        };


        let firmDetailsPayload = [];
        firmDetailsPayload = [currentEmployeerPayload];
        let payload = {
            acceptedEmployerTimePeriodInMonths: "",
            monthlyIncome: formData.monthlyIncome,
            monthlyObligation: formData.monthlyObligation,
            "qualificationDetails": {
                medicalCouncil: formData.medicalCouncil,
                qualification: formData.qualification,
                qualificationYear: formData.qualificationYear ? `${formData.qualificationYear.year()}` : '',
                registrationNumber: formData.registrationNumber,
                registrationYear: formData.registrationYear ? `${formData.registrationYear.year()}` : '',
                totalExperience: formData.totalExperience,
                memberType: "",
                registrationCertificateDoc: registrationDocument,
            },
            firmDetails: firmDetailsPayload,
        };
        console.log("MyPaylaod", payload);
        try {
            const response = await propertyLoanStoreProfessionalDetailsAPI(payload);

            if (
                response.data.httpResponseCode === 200 &&
                response.data.businessStatusCode === 2
            ) {
                // setShowReference(true);
                // aaSuccess(true);
                changeApplicantStatus(response?.data?.nextPage);
            } else {
                // onContinue();
                // setActiveStep(5);
                // setShowAccountAggregator(true);
                // aaSuccess(false);
                toast.error(response.data.message);
                // setShowReference(true);
            }
        } catch (error) {
            handleApiError(error);
            console.error("Error storing employee history:", error);
        } finally {
            // Reset loading state after the operation is complete
            setIsLoading3(false);
            setIsButtonClicked(false);
        }
    };

    const [professionType, setProfessionType] = React.useState(
        localStorage.getItem("profession") || ""
    );

    // Fetching dropdown
    useEffect(() => {
        //fetching Qualification dropdown

        let apiPayload1 = {
            type: "TOTAL_EXPERIENCE",
            key: "TOTAL_EXPERIENCE",
            source: "json_value",
        };
        fetchDropDownValues(apiPayload1, "TOTAL_EXPERIENCE");

        let apiPayload3 = {
            type: "FIRM_PREMISES_OWNER",
            key: "FIRM_PREMISES_OWNER",
            source: "json_value",
        };
        fetchDropDownValues(apiPayload3, "FIRM_PREMISES_OWNER");

        let apiPayload4 = {
            type: "FIRM_VINTAGE",
            key: "FIRM_VINTAGE",
            source: "json_value",
        };
        fetchDropDownValues(apiPayload4, "FIRM_VINTAGE");

        let apiPayload6 = {
            type: "PERSONAL_LOAN_SELF_EMPLOYED",
            key: "NO_OF_EMPLOYEES",
            source: "json_value",
        };
        fetchDropDownValues(apiPayload6, "PERSONAL_LOAN_SELF_EMPLOYED");

        let apiPayload7 = {
            type: "BORROWER_QUALIFICATION",
            key: "BORROWER_QUALIFICATION",
            source: "json_value"
        }
        fetchDropDownValues(apiPayload7, "BORROWER_QUALIFICATION");

        let apiPayload8 = {
            type: "NATURE_OF_BUSINESS",
            key: "NATURE_OF_BUSINESS",
            source: "json_value"
        }
        fetchDropDownValues(apiPayload8, "NATURE_OF_BUSINESS");

        let apiPayload9 = {
            type: "BUSINESS_LOAN",
            key: "BUSINESS_PREMISES_AREA",
            source: "json_value"
        }
        fetchDropDownValues(apiPayload9, "BUSINESS_LOAN");

        let apiPayload10 = {
            "type": "STATE",
            "key": "MEDICAL_COUNCIL",
            "source": "json_value"
        }
        fetchDropDownValues(apiPayload10, 'MEDICAL_COUNCIL');

        let apiPayload11 = {
            "type": "NUMBER_OF_YEARS_IN_CURRENT_PROFESSION",
            "key": "NUMBER_OF_YEARS_IN_CURRENT_PROFESSION",
            "source": "json_value"
        }
        fetchDropDownValues(apiPayload11, 'NUMBER_OF_YEARS_IN_CURRENT_PROFESSION');
    }, []);
    const fetchDropDownValues = async (apiPayload, dropdownType) => {
        setQualificationDropdown([]);
        setTotalExpDropdown([]);
        setMedicalCouncilDropdown([]);
        setNatureOfBusinessDropdown([]);
        setFirmVintageOwnerDropdown([]);
        setFirmPrimisesAreaDropdown([]);
        setFirmVintageDropdown([]);
        setNoOfEmployeesDropdown([]);
        setFirmPremisesVintageDropdown([]);
        setCurrentProfessionYearsDropdown([]);

        try {
            const response = await propertyLoanFetchDataAPI(apiPayload);

            if (dropdownType == "BORROWER_QUALIFICATION") {
                setQualificationDropdown(response.data[0].values);
            } else if (dropdownType == "TOTAL_EXPERIENCE") {
                setTotalExpDropdown(response.data[0].values);
            } else if (dropdownType == "TOTAL_EXPERIENCE") {
                setFirmPremisesVintageDropdown(response.data[0].values);
            } else if (dropdownType == "FIRM_PREMISES_OWNER") {
                setFirmVintageOwnerDropdown(response.data[0].values);
            } else if (dropdownType == "FIRM_VINTAGE") {
                setFirmVintageDropdown(response.data[0].values);
            } else if (dropdownType == "PERSONAL_LOAN_SELF_EMPLOYED") {
                setNoOfEmployeesDropdown(response.data[0].List);
            } else if (dropdownType == "NATURE_OF_BUSINESS") {
                setNatureOfBusinessDropdown(response.data[0].values);
            } else if (dropdownType == "BUSINESS_LOAN") {
                setFirmPrimisesAreaDropdown(response.data[0].business_premises_area);
            }
            else if (dropdownType == 'MEDICAL_COUNCIL') {
                setMedicalCouncilDropdown(response.data[0].values);
            } else if (dropdownType == 'NUMBER_OF_YEARS_IN_CURRENT_PROFESSION') {
                setCurrentProfessionYearsDropdown(response.data[0].values);
            } else {
            }
        } catch (error) {
            handleApiError(error);
            console.error("Error fetching address vintage:", error);
        }
    };

    const currentYear = dayjs().year();
    const currentMonth = dayjs().month();
    const disableFutureYears = (date) => {
        return date.year() > currentYear;
    };

    const disableFutureYearsAndMonth = (date) => {
        return (
            date.year() > currentYear ||
            (date.year() === currentYear && date.month() > currentMonth)
        );
    };

    // const disableFutureYearsAndMonth1 = (date) => {
    //   return date.year() > currentDate.year();
    // };

    const handleRegistrationPopup = () => {
        setIsRegistrationPopupOpen(true);
    }
    const handleRegitrationPopupClose = () => {
        setIsRegistrationPopupOpen(false);
    };

    const checkFormValid = () => {
        const {
            monthlyIncome,
            monthlyObligation,
            qualification,
            registrationNumber,
        } = formData;
        // Determine if the Shop Act Number is present and set the document requirement
        const isShopActNumberPresent = currentEmployeer?.shopActNumber?.length > 0;
        const requiredDocumentCount = isShopActNumberPresent ? 4 : 3;
        const areDocumentsValid = selectedDocs?.document?.length >= requiredDocumentCount;
        const isMonthlyIncomeValid = /^\d+$/.test(monthlyIncome);
        const isMonthlyObligationValid = /^\d+$/.test(monthlyObligation);
        const isQualificationValid = !!qualification;
        const isRegistrationDocumentUploaded = !!registrationDocument; // Check if the registration document is uploaded
        const isRegistrationNumberValid = registrationNumber && registrationNumber.trim() !== "";

        // Validate form fields
        const isFormFilled =
            formData?.qualification &&
            formData?.totalExperience &&
            currentEmployeer?.firmName &&
            currentEmployeer?.firmVintage &&
            currentEmployeer?.numberOfEmployeesInFirm &&
            currentEmployeer?.workfrom &&
            currentEmployeer?.incorporationMonth &&
            currentEmployeer?.firmPremisesOwner &&
            currentEmployeer?.firmPremisesVintage &&
            currentEmployeer?.natureOfBusiness &&
            // currentEmployeer?.yearsInCurrentProfession &&
            bankDetails &&
            currentAddress &&
            areDocumentsValid &&
            isQualificationValid &&
            isMonthlyIncomeValid &&
            isMonthlyObligationValid &&
            isRegistrationNumberValid &&
            isRegistrationDocumentUploaded &&
            (currentEmployeer?.gstNumber === "" || isValidGST) &&
            (addresses?.some(address => address.selectedByUser) || showAdditionalDiv); // Ensure address is selected or new address is added


        setIsFormValid(isFormFilled);
    };





    useEffect(() => {
        checkFormValid();
    }, [
        formData,
        currentAddress,
        bankDetails,
        gstnVerified,
        isValidGST,
        currentEmployeer,
        registrationDocument,
        selectedDocs,
        isAddressAdded, // Used to determine if an address is added
        selectedAddress // Used to determine if a specific address is selected
    ]);


    const handleDateChange = (e) => {
        if (e) {
            handleWorkingSince(e);
        }
    };

    const handleDateChange1 = (date) => {
        if (date) {
            setCurrentEmployeer({
                ...currentEmployeer,
                incorporationMonth: date,
            });
        }
    };
    const [gstError, setGstError] = useState(false);
    const handleChange1 = (e) => {
        const { value } = e.target;

        // Update the GST number in the currentEmployer state
        setCurrentEmployeer({
            ...currentEmployeer,
            gstNumber: value,
        });

        // When GST number is removed, clear the firm name
        if (value === "") {
            setCurrentEmployeer((prev) => ({
                ...prev,
                firmName: isUdyamVerified ? prev.firmName : "", // Clear the firm name when GST number is removed
            }));
            setIsValidGST(false);  // Reset GST validation when the field is empty
            setGstnVerified(false); // Reset GST verification status
            setGstError(""); // Clear the GST error message
        } else {
            // Validate GST number format when a value is entered
            const updatedValue = value.replace(/\s/g, "").toUpperCase();
            const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
            const isValid = gstRegex.test(value);
            // setIsValidGST(isValid);

            if (updatedValue.length === 15 && isValid) {
                setIsValidGST(true);
                setGstError("");
                setGstnVerified(false);
                setIsGSTVerified(false);
              } else {
                setIsValidGST(false);
                setGstError(
                  updatedValue.length === 0
                    ? ""
                    : "Invalid GST Number. Please enter in the specified format."
                );
                setGstnVerified(false);
                setIsGSTVerified(false);
              }
        }

        // Check if the form is valid
        checkFormValid();
    };


    // Function to fetch the firm name from the API based on GST number




    const handleRegistrationYearChange = (date) => {
        checkFormValid();
        if (date) {
            setSelectedYear(date);
            setFormData({
                ...formData,
                registrationYear: date,
            })
        }
    };

    const goToSummaryPage = () => {
        changeApplicantStatus("");
    };

    return (
        <>
            {!showReference ? (
                <>
                    <div
                        onClick={goToSummaryPage}
                        className="col-12 text-end fw-bold py-1"
                        style={{
                            color: "orange",
                            marginLeft: "auto",
                            textDecoration: "none",
                            cursor: "pointer",

                        }}
                    >
                        Go To Applicant Summary
                    </div>

                    <h6 className="text">Qualification, Experience & Income Details</h6>
                    <p>Share your academic background and industry experience</p>

                    <form className="mt-4">
                        <>
                            <div className="row mt-4">
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                    <ArthaFormControl fullWidth>
                                        <InputLabel id="qualification-label">
                                            Qualification / Degree
                                        </InputLabel>
                                        <Select
                                            labelId="qualification-label"
                                            id="qualification"
                                            name="qualification"
                                            value={formData.qualification}
                                            onChange={handleChange2}
                                        >
                                            {qualificationDropdown &&
                                                qualificationDropdown.map((item) => {
                                                    return (
                                                        <MenuItem key={item} value={item}>
                                                            {item}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </ArthaFormControl>
                                </div>

                                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                    <ArthaFormControl fullWidth>
                                        <InputLabel id="totalExperience-label">
                                            Total Experience
                                        </InputLabel>
                                        <Select
                                            labelId="totalExperience-label"
                                            id="totalExperience"
                                            name="totalExperience"
                                            value={formData.totalExperience}
                                            onChange={(e) =>
                                                setFormData({
                                                    ...formData,
                                                    totalExperience: e.target.value,
                                                })
                                            }
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left",
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "left",
                                                },
                                                getContentAnchorEl: null,
                                                PaperProps: {
                                                    className: "custom-scrollbar", // Apply custom scrollbar class
                                                    style: {
                                                        maxHeight: 120, // Adjust the max height to control the size of the dropdown
                                                    },
                                                },
                                            }}
                                        >
                                            {totalExpDropdown.map((item, index) => {
                                                const [value, label] = item.split(" ");
                                                return (
                                                    <MenuItem key={index} value={value}>
                                                        {item}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </ArthaFormControl>
                                </div>

                            </div>
                        </>
                        {/* <div className="row mt-3 mt-md-4"> */}


                        {/* </div> */}


                        <div className="row mt-3 mt-md-4">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Registration Year - (YYYY) (Optional)"
                                        views={['year']}
                                        value={formData.registrationYear || null}
                                        onChange={handleRegistrationYearChange}
                                        renderInput={(params) => <TextField {...params} />}
                                        className="yearPicker"
                                        fullWidth
                                        shouldDisableDate={disableFutureYears}
                                        maxDate={currentDate}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <ArthaTextField
                                    name="registrationNumber"
                                    value={formData.registrationNumber || null}
                                    label="Member / Registration Number"
                                    fullWidth
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            registrationNumber: e.target.value,
                                        })
                                    }
                                    InputProps={{
                                        style: {
                                            height: '50px' // Adjust the height as needed
                                        }
                                    }}
                                    inputProps={{
                                        style: {
                                            maxLength: 80,
                                            padding: '18px 14px' // Adjust padding to center the text vertically
                                        }
                                    }}
                                />
                            </div>



                        </div>
                        <div className="row mt-3 mt-md-4">
                            <div className="col-8 col-md-4">
                                <p className="msg">Certificate of Registration</p>
                                {registrationDocument ? (
                                    <>
                                        <FaCheckCircle className="badge-icon" style={{ marginTop: "-7px", color: 'green' }} />
                                        <span className="mt-2 upload-lable" style={{ position: "relative", top: "-10px" }}>
                                            {registrationDocument.documentName}
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <FaExclamationCircle className="badge-icon" />
                                        <span className="mt-2 upload-lable" style={{ position: "relative", top: "-10px" }}>
                                            Not Uploaded
                                        </span>
                                    </>
                                )}
                            </div>
                            <div className="col-4 col-md-2">
                                <input
                                    type="file"
                                    ref={fileInputRefID}
                                    style={{ display: "none" }}
                                    onChange={handleFileChangeID}
                                />
                                <Button
                                    variant="contained"
                                    className="upload_btn btn_white shadow-none mt-3"
                                    onClick={handleRegistrationPopup}
                                >
                                    Upload
                                </Button>
                                <UploadSingleDocument
                                    open={isRegistrationPopupOpen}
                                    onRemove={() => removeExistingDoc('Certificate of Registration')}
                                    handleClose={handleRegitrationPopupClose}
                                    onSave={onSave}
                                    documentTypeDropdowns={registrationDocumentTypes1}
                                    existingDocument={registrationDocument}
                                />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-6">
                                <TextField
                                    id="outlined-basic"
                                    name="monthlyIncome"
                                    label="Monthly Income (₹)"
                                    fullWidth
                                    variant="outlined"
                                    value={formData.monthlyIncome}
                                    onChange={handleChange2}
                                    // InputProps={{
                                    //     style: {
                                    //         height: '50px' // Adjust the height as needed
                                    //     }
                                    // }}
                                    inputProps={{
                                        inputMode: "numeric",
                                        pattern: "[0-9]*",
                                        maxLength: 8,
                                        
                                    }}
                                />
                            </div>
                            <div className="col-md-6">
                                <TextField
                                    id="outlined-basic"
                                    name="monthlyObligation"
                                    label="Monthly Obligations (₹)"
                                    fullWidth
                                    variant="outlined"
                                    value={formData.monthlyObligation}
                                    onChange={handleChange2}   
                                    inputProps={{
                                        inputMode: "numeric",
                                        pattern: "[0-9]*",
                                        maxLength: 8,
                                        
                                    }}
                                />
                            </div>

                        </div>


                        <div className="row mt-5 mt-md-5">
                            <div className="col-8 col-md-12 mt-2">
                                <p>Please provide professional details for the past 2 years</p>
                            </div>
                        </div>

                        <div className="row mt-2 mt-md-2">
                            <div className="col-8 col-md-12">
                                <h6 className="text">Professional Details</h6>
                                <p>Enter Details of current profession</p>
                            </div>
                        </div>
                        <div className="row mt-2 md-4">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <TextField
                                    name="gstNumber"
                                    value={currentEmployeer.gstNumber}
                                    label="GST Number (Optional)"
                                    fullWidth
                                    inputProps={{ maxLength: 80 }}
                                    className={gstnVerified ? "verifiedBorder" : ""}
                                    InputProps={{
                                        endAdornment: isVerifying ? (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <CircularProgress size={20} />
                                                <Typography
                                                    variant="body2"
                                                    style={{ color: 'grey', marginLeft: '4px' }}
                                                >
                                                    Verifying
                                                </Typography>
                                            </div>
                                        ) : gstnVerified ? (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <CheckCircleIcon style={{ color: 'green' }} />
                                                <Typography
                                                    variant="body2"
                                                    style={{ color: 'green', marginLeft: '4px' }}
                                                >
                                                    Verified
                                                </Typography>
                                            </div>
                                        ) : currentEmployeer.gstNumber && isGSTVerified ? (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <CancelIcon style={{ color: 'grey' }} />
                                                <Typography
                                                    variant="body2"
                                                    style={{ color: 'grey', marginLeft: '4px' }}
                                                >
                                                    Unverified
                                                </Typography>
                                            </div>
                                        ) : null,
                                    }}
                                    onChange={(e) => handleChange1(e)}
                                    error={Boolean(gstError) || isGSTVerified}
                                    helperText={
                                      Boolean(gstError)
                                        ? gstError
                                        : isGSTVerified
                                          ? "No record found for this GST number"
                                          : ""
                                    }
                                    onBlur={handleVerification}
                                />
                            </div>

                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <ArthaTextField
                                    name="udyamAadhaar"
                                    value={formData.udyamAadhaar}
                                    label="Udyam Aadhaar (Optional) "
                                    placeholder="UDYAM-XX-XX-XXX720"
                                    fullWidth
                                    inputProps={{ maxLength: 19 }}
                                    className={isUdyamVerified ? "verifiedBorder" : ""}
                                    InputProps={{
                                        endAdornment: isVerifyingUdyam ? (
                                          <div style={{ display: "flex", alignItems: "center" }}>
                                            <CircularProgress size={20} />
                                            <Typography
                                              variant="body2"
                                              style={{ color: "grey", marginLeft: "4px" }}
                                            >
                                              Verifying
                                            </Typography>
                                          </div>
                                        ) : isUdyamVerified ? (
                                          <div style={{ display: "flex", alignItems: "center" }}>
                                            <CheckCircleIcon style={{ color: "green" }} />
                                            <Typography
                                              variant="body2"
                                              style={{ color: "green", marginLeft: "4px" }}
                                            >
                                              Verified
                                            </Typography>
                                          </div>
                                        ): formData.udyamAadhaar && isUdyamInvalid ? (
                                          <div style={{ display: "flex", alignItems: "center" }}>
                                            <CancelIcon style={{ color: "grey" }} />
                                            <Typography
                                              variant="body2"
                                              style={{ color: "grey", marginLeft: "4px" }}
                                            >
                                              Unverified
                                            </Typography>
                                          </div>
                                        ) : null,
                                      }}
                                    onChange={handleChange2}
                                    error={Boolean(udyamError) || isUdyamInvalid}
                                    helperText={
                                        Boolean(udyamError)
                                            ? udyamError
                                            : isUdyamInvalid
                                                ? "No record found for this Udyam number"
                                                : ""
                                    }
                                    onBlur={handleBlur}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{ fontSize: "12px" }}
                                />
                            </div>

                        </div>

                        <div className="row mt-3 mt-md-4">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <TextField
                                    name="shopActNumber"
                                    value={currentEmployeer.shopActNumber}
                                    label="Shop Act Number (Optional)"
                                    fullWidth
                                    inputProps={{ maxLength: 80 }}
                                    className={shopActNoVerified ? "verifiedBorder" : ""}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setCurrentEmployeer({
                                          ...currentEmployeer,
                                          shopActNumber: value,
                                        });
                                
                                        // When Shop Act Number is removed, remove related document
                                        if (value === "") {
                                          removeExistingDoc("Shop Act License / Udyam Aadhaar proof");
                                        }
                                      }}
                                    onBlur={(e) => {
                                        handleVerification1(e);
                                    }}
                                />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <TextField
                                    name="firmName"
                                    value={currentEmployeer.firmName}
                                    label="Name of Firm"
                                    fullWidth
                                    inputProps={{ maxLength: 80 }}
                                    onChange={(e) =>
                                        setCurrentEmployeer({
                                            ...currentEmployeer,
                                            firmName: e.target.value,
                                        })
                                    }
                                />
                            </div>


                        </div>

                        <div className="row mt-3 mt-md-4">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                <ArthaFormControl fullWidth>
                                    <InputLabel id="natureOfBusiness-label">
                                        Nature of Firm
                                    </InputLabel>
                                    <Select
                                        labelId="natureOfBusiness-label"
                                        id="natureOfBusiness"
                                        name="natureOfBusiness"
                                        value={currentEmployeer.natureOfBusiness}
                                        onChange={(e) =>
                                            setCurrentEmployeer({
                                                ...currentEmployeer,
                                                natureOfBusiness: e.target.value,
                                            })
                                        }
                                    >
                                        {natureOfBusinessDropdown &&
                                            natureOfBusinessDropdown.map((item) => {
                                                return (
                                                    <MenuItem key={item} value={item}>
                                                        {item}
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </ArthaFormControl>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                <ArthaFormControl fullWidth>
                                    <InputLabel id="firmVintage-label">
                                        Firm Vintage
                                    </InputLabel>
                                    <Select
                                        labelId="firmVintage-label"
                                        id="firmVintage"
                                        name="firmVintage"
                                        value={currentEmployeer.firmVintage}
                                        onChange={(e) =>
                                            setCurrentEmployeer({
                                                ...currentEmployeer,
                                                firmVintage: e.target.value,
                                            })
                                        }
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left",
                                            },
                                            getContentAnchorEl: null,
                                            PaperProps: {
                                                className: "custom-scrollbar", // Apply custom scrollbar class
                                                style: {
                                                    maxHeight: 120, // Adjust the max height to control the size of the dropdown
                                                },
                                            },
                                        }}
                                    >
                                        {firmVintageDropdown &&
                                            firmVintageDropdown.map((item, index) => {
                                                const [value, label] = item.split(" ");
                                                return (
                                                    <MenuItem key={index} value={value}>
                                                        {item}
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </ArthaFormControl>
                            </div>


                        </div>

                        <div className="row mt-3 mt-md-4">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                <ArthaFormControl fullWidth>
                                    <InputLabel id="noOfEmployees-label">
                                        Number of Employees in Firm
                                    </InputLabel>
                                    <Select
                                        labelId="firmVintage-label"
                                        id="firmVintage"
                                        name="numberOfEmployeesInFirm"
                                        value={currentEmployeer.numberOfEmployeesInFirm}
                                        onChange={(e) =>
                                            setCurrentEmployeer({
                                                ...currentEmployeer,
                                                numberOfEmployeesInFirm: e.target.value,
                                            })
                                        }
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left",
                                            },
                                            getContentAnchorEl: null,
                                            PaperProps: {
                                                className: "custom-scrollbar", // Apply custom scrollbar class
                                                style: {
                                                    maxHeight: 120, // Adjust the max height to control the size of the dropdown
                                                },
                                            },
                                        }}
                                    >
                                        {noOfEmployeesDropdown &&
                                            noOfEmployeesDropdown.map((item, index) => {
                                                const [value, label] = item.split(" ");
                                                return (
                                                    <MenuItem key={index} value={value}>
                                                        {item}
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </ArthaFormControl>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Incorporation / Commencement Month"
                                        value={currentEmployeer.incorporationMonth || null} // Ensure value is null if not defined
                                        onChange={(date) => handleDateChange1(date)}
                                        renderInput={(params) => <TextField {...params} />}
                                        className="yearPicker"
                                        format="MM-YYYY" // Set the format here
                                        openTo="month" // Open the DatePicker with the month view
                                        views={["month", "year"]}
                                        shouldDisableDate={disableFutureYears}
                                        maxDate={currentDate}
                                    />
                                </LocalizationProvider>
                            </div>


                        </div>

                        <div className="row mt-3 mt-md-4">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                <ArthaFormControl fullWidth>
                                    <InputLabel id="firmPremisesOwner-label">
                                        Firm Premises Owner
                                    </InputLabel>
                                    <Select
                                        labelId="firmPremisesOwner-label"
                                        id="firmPremisesOwner"
                                        name="firmPremisesOwner"
                                        value={currentEmployeer.firmPremisesOwner}
                                        onChange={(e) =>
                                            setCurrentEmployeer({
                                                ...currentEmployeer,
                                                firmPremisesOwner: e.target.value,
                                            })
                                        }
                                    >
                                        {firmVintageOwnerDropdown &&
                                            firmVintageOwnerDropdown.map((item) => {
                                                return (
                                                    <MenuItem key={item} value={item}>
                                                        {item}
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </ArthaFormControl>
                            </div>

                            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                <ArthaFormControl fullWidth>
                                    <InputLabel id="firmPremisesVintage-label">
                                        Firm Premises Vintage
                                    </InputLabel>
                                    <Select
                                        labelId="firmPremisesVintage-label"
                                        id="firmPremisesVintage"
                                        name="firmPremisesVintage"
                                        value={currentEmployeer.firmPremisesVintage}
                                        onChange={(e) =>
                                            setCurrentEmployeer({
                                                ...currentEmployeer,
                                                firmPremisesVintage: e.target.value,
                                            })
                                        }
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left",
                                            },
                                            getContentAnchorEl: null,
                                            PaperProps: {
                                                className: "custom-scrollbar", // Apply custom scrollbar class
                                                style: {
                                                    maxHeight: 120, // Adjust the max height to control the size of the dropdown
                                                },
                                            },
                                        }}
                                    >
                                        {firmVintageDropdown &&
                                            firmVintageDropdown.map((item, index) => {
                                                const [value, label] = item.split(" ");
                                                return (
                                                    <MenuItem key={index} value={value}>
                                                        {item}
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </ArthaFormControl>
                            </div>
                        </div>

                        <div className="row mt-3 mt-md-4">
                            {/* <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                <ArthaFormControl fullWidth>
                                    <InputLabel id="yearsInCurrentProfession-label">
                                        Number of Year in current profession
                                    </InputLabel>
                                    <Select
                                        labelId="yearsInCurrentProfession-label"
                                        id="yearsInCurrentProfession"
                                        name="yearsInCurrentProfession"
                                        value={currentEmployeer.yearsInCurrentProfession}
                                        onChange={(e) =>
                                            setCurrentEmployeer({
                                                ...currentEmployeer,
                                                yearsInCurrentProfession: e.target.value,
                                            })
                                        }
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'left',
                                            },
                                            getContentAnchorEl: null,
                                            PaperProps: {
                                                className: 'custom-scrollbar', // Apply custom scrollbar class
                                                style: {
                                                    maxHeight: 120, // Adjust the max height to control the size of the dropdown
                                                },
                                            },
                                        }}
                                    >
                                        {currentProfessionYearsDropdown.map((item, index) => {
                                            const [value, label] = item.split(" ");
                                            return (
                                                <MenuItem key={index} value={value}>
                                                    {item}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </ArthaFormControl>
                            </div> */}
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Working since"
                                        value={currentEmployeer.workfrom || null} // Ensure value is null if not defined
                                        onChange={(e) => handleDateChange(e)}
                                        renderInput={(params) => <TextField {...params} />}
                                        className="yearPicker"
                                        // maxDate={currentDate}
                                        maxDate={currentDate.subtract(1, "day")} // Allow only up to yesterday
                                        shouldDisableDate={(date) =>
                                          date.isSame(currentDate, "day") // Disable today’s date explicitly
                                        }
                                        onKeyDown={(e) => e.preventDefault()} // Prevent manual typing
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>

                        <div className="row mt-4 mt-md-5">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                <div className="row">
                                    <div className="col-8 col-md-7">
                                        <p className="msg">Official mail address (Optional)</p>

                                        {isEmailAdded ? (
                                            <>
                                                <FaExclamationCircle
                                                    className="badge-icon"
                                                    style={{ marginTop: "-7px", color: "green" }}
                                                />
                                                <span
                                                    className="mt-2 upload-lable"
                                                    style={{ position: "relative", top: "-10px" }}
                                                >
                                                    {" "}
                                                    Email Added
                                                </span>
                                            </>
                                        ) : (
                                            <>
                                                <FaExclamationCircle className="badge-icon" />
                                                <span
                                                    className="mt-2 upload-lable"
                                                    style={{ position: "relative", top: "-10px" }}
                                                >
                                                    {" "}
                                                    Not Added
                                                </span>
                                            </>
                                        )}
                                    </div>
                                    <div className="col-4 col-md-5">
                                        <input
                                            type="file"
                                            ref={fileInputRefID}
                                            style={{ display: "none" }}
                                            onChange={handleFileChangeID}
                                        />
                                        <Button
                                            variant="contained"
                                            className="upload_btn btn_white shadow-none mt-3"
                                            onClick={() => {
                                                setOpenEmailDialog(true);
                                            }}
                                        >
                                            {isEmailAdded ? "Edit" : "Add Email"}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-2">
                                <div className="row">
                                    <div className="col-8 col-md-7">
                                        <p className="msg">Firm / Business Address</p>

                                        {isAddressAdded ? (
                                            <>
                                                <FaCheckCircle
                                                    className="badge-icon"
                                                    style={{ marginTop: "-7px", color: "green" }}
                                                />
                                                <span
                                                    className="mt-2 upload-lable"
                                                    style={{ position: "relative", top: "-10px" }}
                                                >
                                                    {" "}
                                                    Address added!
                                                </span>
                                            </>
                                        ) : (
                                            <>
                                                <FaExclamationCircle className="badge-icon" />
                                                <span
                                                    className="mt-2 upload-lable"
                                                    style={{ position: "relative", top: "-10px" }}
                                                >
                                                    {" "}
                                                    Not Added
                                                </span>
                                            </>
                                        )}
                                    </div>
                                    <div className="col-4 col-md-5">
                                        <input
                                            type="file"
                                            ref={fileInputRefID}
                                            style={{ display: "none" }}
                                            onChange={handleFileChangeID}
                                        />
                                        <Button
                                            variant="contained"
                                            className="upload_btn btn_white shadow-none mt-3"
                                            onClick={openAddrDialog}
                                        >
                                            {isAddressAdded ? "Edit" : " Add Address "}
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                <div className="row">
                                    <div className="col-8 col-md-7 mt-3">
                                        <p className="msg">Bank Account Details</p>

                                        {bankDetails ? (
                                            <>
                                                <FaCheckCircle
                                                    className="badge-icon"
                                                    style={{ marginTop: "-7px", color: "green" }}
                                                />
                                                <span
                                                    className="mt-2 upload-lable"
                                                    style={{ position: "relative", top: "-10px" }}
                                                >
                                                    {" "}
                                                    Bank Details Added!
                                                </span>
                                            </>
                                        ) : (
                                            <>
                                                <FaExclamationCircle className="badge-icon" />
                                                <span
                                                    className="mt-2 upload-lable"
                                                    style={{ position: "relative", top: "-10px" }}
                                                >
                                                    {" "}
                                                    Not Added
                                                </span>
                                            </>
                                        )}
                                    </div>
                                    <div className="col-4 col-md-5">
                                        <input
                                            type="file"
                                            ref={fileInputRefID}
                                            style={{ display: "none" }}
                                            onChange={handleFileChangeID}
                                        />
                                        <Button
                                            variant="contained"
                                            className="upload_btn btn_white shadow-none mt-4"
                                            onClick={() => {
                                                setOpenBankDialog(true);
                                            }}
                                        >
                                            {bankDetails ? "Edit" : "Add Bank"}
                                        </Button>
                                        <AddBank
                                            openBankDialog={openBankDialog}
                                            onCancel={cancelBankDialog}
                                            onBankDataSave={onBankDataSave}
                                            firmDetails={firmDetails} // Pass firm details here
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                <div className="row">
                                    <div className="col-8 col-md-7 mt-3">
                                        <p className="msg">Supporting Documents</p>
                                        {selectedDocs &&
                                            selectedDocs.document &&
                                            selectedDocs.document.length > 0 ? (
                                            selectedDocs.document.map((doc, index) => (
                                                <React.Fragment key={`${doc.documentType}-${index}`}>
                                                    <FaCheckCircle className="badge-icon" style={{ marginTop: "-7px", color: "green" }} />
                                                    <span className="mt-2 upload-lable" style={{ position: "relative", top: "-10px" }}>
                                                        {doc.documentType} <br />
                                                    </span>
                                                </React.Fragment>
                                            ))

                                        ) : (
                                            <div>
                                                <FaExclamationCircle className="badge-icon" />
                                                <span className="mt-2 upload-lable" style={{ position: "relative", top: "-10px" }}>
                                                    Not Uploaded
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-4 col-md-5">
                                        <input
                                            type="file"
                                            ref={fileInputRefID}
                                            style={{ display: "none" }}
                                            onChange={handleFileChangeID}
                                        />
                                        <Button
                                            variant="contained"
                                            className="upload_btn btn_white shadow-none mt-4"
                                            onClick={handleUploadDocument}
                                        >
                                            Upload
                                        </Button>
                                        <UploadSupportiveBL
                                            open={isPopupOpen}
                                            existingDocuments={selectedDocs.document}
                                            onRemove={removeExistingDoc}
                                            handleClose={handlePopupClose}
                                            isShopActNumberPresent={currentEmployeer.shopActNumber.length > 0}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-3 mt-md-4"></div>

                        {/* Dialogs started */}

                        <Dialog
                            open={isOpenEmailDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <div className="modal row">
                                <div className="modal-content col-md-6">
                                    {!isEmailAdded ? (
                                        <h6> Add Official Email ID</h6>
                                    ) : (
                                        <h6>Edit Email</h6>
                                    )}

                                    <div className="row">
                                        <div className="col-md-12 mt-3">
                                            <span>
                                                Please enter official email id for your current
                                                employer. OTP validation will be needed for confirming
                                                the email id.
                                            </span>
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <ArthaTextField
                                                name="line2"
                                                onChange={(e) => {
                                                    setFormData({
                                                        ...formData,
                                                        officialEmail: e.target.value,
                                                    });
                                                }}
                                                onBlur={() => setEmailTouched(true)}
                                                value={formData.officialEmail}
                                                fullWidth
                                                label="Email ID"
                                                helperText={
                                                    emailTouched &&
                                                    formData?.officialEmail?.length > 0 &&
                                                    !isEmailValid(formData.officialEmail) &&
                                                    "Invalid email format"
                                                  }
                                                  error={
                                                    emailTouched &&
                                                    formData?.officialEmail?.length > 0 &&
                                                    !isEmailValid(formData.officialEmail)
                                                  }
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-4 mx-auto">
                                        <div className="col-6 d-flex justify-content-end mt-4">
                                            <ArthaClearButton
                                                variant="contained"
                                                onClick={closeEmailDialog}
                                            >
                                                Cancel
                                            </ArthaClearButton>
                                        </div>
                                        <div className="col-6 d-flex justify-content-start mt-4">
                                            <ArthaContinueButton
                                                variant="contained"
                                                disabled={!emailRegex.test(formData.officialEmail)} // Disable button if email format is invalid
                                                onClick={() => {
                                                    // handleContinue();
                                                    openOTPDialog();
                                                }}
                                                style={{
                                                    opacity: isButtonClicked ? 0.39 : 1,
                                                    pointerEvents: isButtonClicked ? "none" : "auto",
                                                }}
                                            >
                                                {isLoading1 ? (
                                                    <LoadingSpinner style={{ pointerEvents: "none" }} />
                                                ) : (
                                                    "Continue"
                                                )}
                                            </ArthaContinueButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Dialog>

                        <Dialog
                            open={isOpenOTPDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <div className="otpBox">
                                <div className="AdharOTPForm">
                                    <div className="AdharOTPHeading mt-3">
                                        <h5>Otp Validation</h5>

                                        <p
                                            className="msg mt-4"
                                            style={{ padding: "0", margin: "0" }}
                                        >
                                            Please Enter OTP Sent to
                                        </p>
                                        <span
                                            className="msg mt-4"
                                            style={{
                                                fontSize: "10px",
                                                color: "blue",
                                                padding: "0",
                                                margin: "0",
                                            }}
                                        >
                                            {formData.officialEmail}
                                        </span>

                                        <div className="Verify-OTP mt-5">
                                            <div className="Verify-OTP-section">
                                                <div className="otp-inputs">
                                                    {otp?.map((value, index) => (
                                                        <input
                                                            key={index}
                                                            type="text"
                                                            className={`otp-input ${isInvalidOtp ? "error" : ""}`}
                                                            maxLength="1"
                                                            value={value}
                                                            onChange={(e) => handleOtpChange(index, e.target.value)}
                                                            onKeyDown={(e) => handleInputKeyDown(index, e)}
                                                            ref={(ref) => (inputRefs.current[index] = ref)} // Store the reference to each input field
                                                        />
                                                    ))}
                                                    {/* {isInvalidOtp && (
                          <p style={{ color: "red", fontSize: "10px" }}>Invalid OTP</p>
                        )} */}
                                                </div>

                                                <br />



                                                {resendTimer > 0 ? (
                                                    <h6 className="text-center">
                                                        Resend OTP in:
                                                        {" "}{resendTimer < 10 ? `0${resendTimer}` : resendTimer} seconds
                                                    </h6>
                                                ) : (
                                                    <h6 className="text-center">
                                                        Didn't receive any code?{" "}
                                                        <a
                                                            style={{ color: "rgb(255, 136, 0)", textDecoration: "underline", cursor: "pointer" }}
                                                            onClick={() => { handleReSendOtp(); }} disabled={!isResendClickable}
                                                        >
                                                            <h6 className="resend-otp1">Resend OTP</h6>
                                                        </a>
                                                    </h6>

                                                )}

                                            </div>
                                        </div>
                                        <div className="row mt-5 ">
                                            <div className="col-6 d-flex justify-content-end">
                                                <ArthaClearButton
                                                    variant="contained"
                                                    onClick={handlePopupClosePopup}
                                                >
                                                    Back
                                                </ArthaClearButton>
                                            </div>

                                            <div className="col-6 d-flex justify-content-start">
                                                <ArthaContinueButton
                                                    onClick={handleVerifyClick}
                                                    variant="contained"
                                                    // disabled={isInvalidOtp}
                                                    disabled={isVerifyButtonDisabled}
                                                >
                                                    Continue
                                                </ArthaContinueButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Dialog>

                        {/* Address Dialog */}

                        <Dialog
                            open={openAddressDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <div className="modal row">
                                <div className="modal-content col-md-6">
                                    <h6>
                                        {!addresses?.some((address) => address.selectedByUser)
                                            ? "Add Firm / Business Address"
                                            : "Edit Address"}
                                    </h6>
                                    <div className="row">
                                        <div className="col-12">
                                            {addresses?.map((address, index) => (
                                                <div key={index} className="form-check pl-0">
                                                    <div
                                                        className={`card shadow-none address-card ${address.selectedByUser ? "selected-card" : ""
                                                            }`}
                                                    >
                                                        <div className="col-10 card-body">
                                                            <FormControlLabel
                                                                control={
                                                                    <input
                                                                        className="col-1"
                                                                        type="radio"
                                                                        checked={address.selectedByUser}
                                                                        onChange={(e) => {
                                                                            // Deselect all addresses
                                                                            addresses?.forEach((item) => {
                                                                                item.selectedByUser = false;
                                                                            });
                                                                            // Select the current address
                                                                            address.selectedByUser = true;

                                                                            // Hide the Add New div
                                                                            setShowAdditionalDiv(false);

                                                                            // Check form filled state
                                                                            checkFormFilled();

                                                                            // Log the selected address
                                                                            console.log("address", address);
                                                                            setSelectedAddress(address);
                                                                        }}
                                                                    />
                                                                }
                                                                value={address.businessAddressId}
                                                                label={`${address.line1}, ${address.line2}, ${address.dist}, ${address.state}, ${address.pin}`}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        <div className="col-3">
                                            <Button
                                                variant="contained"
                                                className="upload_btn add-new-btn btn_white shadow-none mt-3"
                                                onClick={() => {
                                                    // Deselect all addresses when Add New is clicked
                                                    addresses?.forEach((item) => {
                                                        item.selectedByUser = false;
                                                    });

                                                    // Show the Add New div
                                                    setShowAdditionalDiv(true);

                                                    // Check form filled state
                                                    checkFormFilled();
                                                }}
                                            >
                                                Add New
                                            </Button>
                                        </div>
                                        <div className="col-9"></div>
                                        <div className="col-12 mt-3">
                                            {showAdditionalDiv && (
                                                <>
                                                    <div className="col-12">
                                                        <div className="row border card-body">
                                                            <FormControlLabel
                                                                control={
                                                                    <input className="" type="radio" checked />
                                                                }
                                                            />
                                                            <div className="row">
                                                                <div className="col-md-6 mt-3">
                                                                    <ArthaTextField
                                                                        name="line1"
                                                                        fullWidth
                                                                        value={currentAddress.line1}
                                                                        onChange={handleAddressChange}
                                                                        label="Address Line 1"
                                                                        InputLabelProps={{ shrink: true }}
                                                                    />
                                                                </div>
                                                                <div className="col-md-6 mt-3">
                                                                    <ArthaTextField
                                                                        name="line2"
                                                                        fullWidth
                                                                        value={currentAddress.line2}
                                                                        onChange={handleAddressChange}
                                                                        label="Address Line 2"
                                                                        InputLabelProps={{ shrink: true }}
                                                                    />
                                                                </div>
                                                                {/* <div className="col-md-6 mt-3">
                                  <ArthaTextField
                                    name="pincode"
                                    fullWidth
                                    value={currentAddress.pincode}
                                    onChange={(e) => {
                                      const inputValue = e.target.value
                                        .replace(/\D/g, "")
                                        .slice(0, 6);
                                      handleAddressChange({
                                        target: {
                                          name: "pincode",
                                          value: inputValue,
                                        },
                                      });
                                      setPincodeError(
                                        inputValue.length === 6
                                          ? ""
                                          : "Invalid pincode"
                                      );
                                      if (inputValue.length === 6) {
                                        fetchAddressDetails(inputValue);
                                      } else {
                                        
                                      }
                                      checkFormFilled();
                                    }}
                                    label="Pincode"
                                    InputLabelProps={{ shrink: true }} // Add this line
                                  />
                                  <span
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    {pincodeError}
                                  </span>
                                </div> */}
                                                                <div className="col-md-6 mt-3">
                                                                    <ArthaTextField
                                                                        name="pincode"
                                                                        fullWidth
                                                                        value={currentAddress.pincode}
                                                                        onChange={(e) => {
                                                                            const inputValue = e.target.value.replace(/\D/g, "").slice(0, 6);
                                                                            handleAddressChange({
                                                                                target: {
                                                                                    name: "pincode",
                                                                                    value: inputValue,
                                                                                },
                                                                            });
                                                                            setPincodeError(inputValue.length === 6 ? "" : "Invalid pincode");

                                                                            if (inputValue.length === 6) {
                                                                                fetchAddressDetails(inputValue);
                                                                            } else {
                                                                                // Clear city and state if pincode is cleared
                                                                                setCurrentAddress((prevState) => ({
                                                                                    ...prevState,
                                                                                    city: "",
                                                                                    state: "",
                                                                                }));
                                                                            }
                                                                            checkFormFilled();
                                                                        }}
                                                                        label="Pincode"
                                                                        InputLabelProps={{ shrink: true }}
                                                                    />
                                                                    <span style={{ color: "red", fontSize: "12px" }}>
                                                                        {pincodeError}
                                                                    </span>
                                                                </div>

                                                                <div className="col-md-6 mt-3">
                                                                    <ArthaTextField
                                                                        name="city"
                                                                        fullWidth
                                                                        value={currentAddress.city}
                                                                        onChange={(e) => {
                                                                            const inputValue = e.target.value.replace(
                                                                                /[^a-zA-Z]/g,
                                                                                ""
                                                                            );
                                                                            handleAddressChange({
                                                                                target: {
                                                                                    name: "city",
                                                                                    value: inputValue,
                                                                                },
                                                                            });
                                                                        }}
                                                                        label="City"
                                                                        InputLabelProps={{ shrink: true }} // Add this line
                                                                    />
                                                                </div>

                                                                <div className="col-md-6 mt-3">
                                                                    <ArthaTextField
                                                                        name="state"
                                                                        fullWidth
                                                                        value={currentAddress.state}
                                                                        onChange={(e) => {
                                                                            const inputValue = e.target.value.replace(
                                                                                /[^a-zA-Z]/g,
                                                                                ""
                                                                            );
                                                                            handleAddressChange({
                                                                                target: {
                                                                                    name: "state",
                                                                                    value: inputValue,
                                                                                },
                                                                            });
                                                                        }}
                                                                        label="State"
                                                                        InputLabelProps={{ shrink: true }} // Add this line
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>

                                    <div className="row mt-4 mx-auto">
                                        <div className="col-6 d-flex justify-content-end mt-4">
                                            <ArthaClearButton
                                                variant="contained"
                                                onClick={closeAddrDialog}
                                            >
                                                Cancel
                                            </ArthaClearButton>
                                        </div>
                                        <div className="col-6 d-flex justify-content-start mt-4">
                                            <ArthaContinueButton
                                                variant="contained"
                                                disabled={!isFormFilled}
                                                onClick={() => {
                                                    setOpenAddressDialog(false);
                                                    setAddressAdded(true);
                                                    if (selectedAddress) {
                                                        setCurrentAddress(() => {
                                                            return {
                                                                addressSource:
                                                                    selectedAddress?.businessAddressSource,
                                                                businessAddrId:
                                                                    selectedAddress?.businessAddressId,
                                                            };
                                                        });
                                                    } else {
                                                        return {
                                                            line1: firmDetails?.address?.line1,
                                                            line2: firmDetails?.address?.line2,
                                                            pincode: firmDetails?.address?.pincode,
                                                            city: firmDetails?.address?.city,
                                                            state: firmDetails?.address?.state,
                                                        };
                                                    }
                                                }}

                                            >
                                                Save
                                            </ArthaContinueButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Dialog>

                        <div className="row mt-4">
                            <div className="col-6 d-flex justify-content-end">
                                <ArthaClearButton variant="contained" onClick={handleBack}>
                                    Back
                                </ArthaClearButton>
                            </div>
                            <div className="col-6 d-flex justify-content-start">
                                <ArthaContinueButton
                                    variant="contained"
                                    onClick={handleContinue}
                                    style={{
                                        color: "white",
                                        backgroundColor: "rgb(255, 98, 0)",
                                        opacity: isFormValid ? (isButtonClicked ? 0.39 : 1) : 0.5,
                                        transition: "opacity 0.3s ease-in-out", // Adding a transition for a smooth fade
                                        pointerEvents: isButtonClicked ? "none" : "auto",
                                    }}
                                    disabled={!isFormValid} // Disable button if form is invalid or GST is invalid
                                >
                                    {isLoading3 ? (
                                        <LoadingSpinner style={{ pointerEvents: "none" }} />
                                    ) : (
                                        "Continue"
                                    )}
                                </ArthaContinueButton>
                            </div>
                        </div>
                    </form>
                </>
            ) : (
                <Refernces
                    onContinue={onContinue}
                    onCancel={() => setShowReference(false)}
                    changeStatus={changeStatus}
                />
            )}
        </>
    );
};

export default QualificationExpALL;
