

import React, { useEffect, useRef, useState } from "react";
import "./personalLoan.css";
import ArthaTextField from "../../fields/ArthaTextField";
import ArthaFormControl from "../../fields/ArthaFormControl";
import moment from "moment";
import {
  Button,
  FormControlLabel,
  Dialog,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Radio,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FaExclamationCircle, FaCheckCircle } from "react-icons/fa";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ArthaClearButton from "../../fields/ArthaClearButton";
import { useDispatch,useSelector } from "react-redux";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import AddBank from "./AddBank";
import { useNavigate } from "react-router-dom";
import { handleSignOut } from "./SessionExpiredApi";
import dayjs from "dayjs";
import Refernces from "./Refences";
import LoadingSpinner from "../../fields/spinner";
import UploadSupportiveBL from "./UploadSupportiveBL";
import CircularProgress from "@mui/material/CircularProgress";
import {
  businessLoanVerifyGSTN,
  businessLoanFetchAddressApi,
  businessLoanFetchProfessionalDetailsAPI,
  businessLoanStoreProfessionalDetailsAPI,
  businessLoanEmailVerification,
  businessLoanEmailOtpValidation,
  businessLoanFetchDataAPI,
  BusinessfetchDistStateAPI,
} from "../../actioncreators/actioncreators";
import { toast } from "react-toastify";

const ProfessionalDetails = ({
  onBack,
  onContinue,
  changeStatus,

  onSubmit = (finalAddress) => console.log("Address submitted:", finalAddress), 
}) => {
  const [isVerifying, setIsVerifying] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [gstnVerified, setGstnVerified] = useState(false);
  const [resendTimer, setResendTimer] = useState(120);
  const [isResendClickable, setIsResendClickable] = useState(false);
  const [apiError, setApiError] = useState("");
  const [isValidGST, setIsValidGST] = useState(true);
  const [isGSTVerified, setIsGSTVerified] = useState(false);
  const [gstTouched, setGstTouched] = useState(false);
  const navigate = useNavigate();
  const response2 = useSelector((state) => state.FetchBuisnessAddress);
 
  console.log(response2);
  const responseApId = useSelector((state) => state.fetchbuisnessApid);
  console.log(responseApId);

  const [showReference, setShowReference] = useState(false);
  const [response, setResponse] = React.useState([]);
  const [selectedAddress, setSelectedAddress] = useState(false);
  const [addresses, setAddresses] = React.useState([]);
  const [emailTouched, setEmailTouched] = useState(false);
  const [firmDetails, setFirmDetails] = React.useState({});
  const [showAdditionalDiv, setShowAdditionalDiv] = useState(false);
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed
  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error.response.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }

    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };


  // const [selectedAddress, setSelectedAddress] = useState(null); // Currently selected address
  const [newAddress, setNewAddress] = useState(''); // For adding a new address
  const [isAddingNew, setIsAddingNew] = useState(false); // Toggle between selecting and adding a new address

  const handleAddressSelect = (address) => {
    setIsAddingNew(false); // Switch back to selecting mode
    setSelectedAddress(address); // Set the selected fetched address
    setNewAddress(''); // Clear new address input
  };

  const handleAddNewClick = () => {
    setCurrentAddress({
      line1: "",
      line2: "",
      city: "",
      state: "",
      pincode: "",
    });
    
    addresses?.forEach((item) => {
      item.selectedByUser = false;
    });

    // Reset the form and errors
    setCurrentAddress({
      line1: "",
      line2: "",
      pincode: "",
      city: "",
      state: "",
    });
    setPincodeError("");

    // Show the additional div
    setShowAdditionalDiv(true);
    setIsAddingNew(true); // Switch to adding new address mode
    setSelectedAddress(null); // Clear the current selection
  };

  const handleNewAddressChange = (e) => {
    setNewAddress(e.target.value); // Update new address input
  };


  const handleSubmit = () => {
    const finalAddress = isAddingNew ? currentAddress : selectedAddress;

    if (finalAddress) {
      onSubmit(finalAddress); // Pass the final address to the parent component or API
    } else {
      alert("Please select or add an address before submitting.");
    }
  };
  useEffect(() => {
    
    if (resendTimer > 0) {
      const intervalId = setInterval(() => {
        setResendTimer((prevTimer) => {
          if (prevTimer <= 0) {
            clearInterval(intervalId);
            setIsResendClickable(true);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [resendTimer]);

  useEffect(() => {
    const fetchProfessionalDetails = async () => {
      try {
        const fetchResponse = await businessLoanFetchProfessionalDetailsAPI();
        setResponse(fetchResponse?.data);

        console.log("fetchResponse", fetchResponse);
        const {
          gstAvailable
        } = fetchResponse?.data?.firmDetails[0];

        // Set GST radio button based on `gstAvailable` from API response
        // const gstAvailable = fetchResponse?.data?.firmDetails[0].gstAvailable;
        console.log("gstAvailable", gstAvailable);
        setGstgstAvailable(gstAvailable);

        setFormData({
          ...formData,
          gstAvailable: gstAvailable,
        });

        setShowGSTField(gstAvailable === "Available");

        if (fetchResponse?.data?.businessStatusCode === 2) {
          const requestBody = {
            businessId: fetchResponse?.data?.firmDetails?.[0]?.businessId,
          };
          try {
            const fetchAddress = await businessLoanFetchAddressApi(requestBody);

            setAddresses(fetchAddress?.data?.addresses);

            const rn = fetchResponse?.data?.firmDetails?.[0]?.address?.businessAddrId;
            console.log("rn", rn);

            // Check if any address is selected by the user
            const selectedAddress = fetchAddress?.data?.addresses?.find(
              (address) => address.selectedByUser
            );
            // If an address is selected by the user, set it as the current address
            if (selectedAddress) {
              setCurrentAddress({
                line1: selectedAddress.line1,
                line2: selectedAddress.line2,
                pincode: selectedAddress.pin,
                city: selectedAddress.dist,
                state: selectedAddress.state,
                // addressSource: selectedAddress?.businessAddressSource||"000",
                // businessAddrId: selectedAddress?.businessAddrId||"000",
              });
              setSelectedAddress(true);
            }
          } catch (error) {
            handleApiError(error);
            console.error("Error fetching user data:", error);
          }
        }
        const firmDetailsData = fetchResponse?.data?.firmDetails;
        if (firmDetailsData && firmDetailsData.length > 0) {
          const firmDetail = firmDetailsData[0];
          console.log("firmDetail", firmDetail);

          setFirmDetails(firmDetail);

          // Set GST radio button based on `gstAvailable` from API response
          // const gstAvailable = firmDetail?.gstAvailable;
          // console.log("gstAvailable",gstAvailable);

          // setFormData((prev) => ({
          //   ...prev,
          //   gstVerify: gstAvailable,
          // }));
          // setShowGSTField(gstAvailable === "Available");


          const email = firmDetail?.officialEmail;
          setEmailAdded(email);
          const gstn = firmDetail?.gstNumber;
          setGstnVerified(gstn);
          const address = firmDetail?.address;
          setAddressAdded(address);
          if (address) {
            setCurrentAddress({
              line1: address.line1,
              line2: address.line2,
              pincode: address.pin,
              city: address.dist,
              state: address.state,
              // addressSource: address?.businessAddressSource||"qqw",
              // businessAddrId: address?.businessAddressId||"qqwq",
            });
            setSelectedAddress(true);
          }
          const bankAccDetails = firmDetail?.bankAccDetails;
          setBankDetails(
            bankAccDetails && Object.keys(bankAccDetails).length > 0
          );
          setSelectedDocs({ document: firmDetails?.documents });
        } else {
          setAddressAdded(false);
          setBankDetails(false);
        }
      } catch (error) {
        handleApiError(error);
        console.error("Error fetching user data:", error);
      }
    };

    fetchProfessionalDetails();
  }, [navigate]);




  const [qualificationDropdown, setQualificationDropdown] = React.useState([]);
  const [totalExpDropdown, setTotalExpDropdown] = React.useState([]);
  const [natureOfBusinessDropdown, setNatureOfBusinessDropdown] =
    React.useState([]);
  const [medicalCouncilDropdown, setMedicalCouncilDropdown] = React.useState(
    []
  );
  const [firmVintageOwnerDropdown, setFirmVintageOwnerDropdown] =
    React.useState([]);
  const [firmPrimisesAreaDropdown, setFirmPrimisesAreaDropdown] =
    React.useState([]);
  const [firmVintageDropdown, setFirmVintageDropdown] = React.useState([]);
  const [noOfEmployeesDropdown, setNoOfEmployeesDropdown] = React.useState([]);
  const [firmPremisesVintageDropdown, setFirmPremisesVintageDropdown] =
    React.useState([]);
  const [currentProfessionYearsDropdown, setCurrentProfessionYearsDropdown] =
    React.useState([]);
  const [openBankDialog, setOpenBankDialog] = React.useState(false);
  const [bankDetails, setBankDetails] = React.useState(false);
  const [selectedDocs, setSelectedDocs] = React.useState({ document: [] });
  const [gstVerify, setGstVerify] = useState("");
  const [gstAvailable, setGstgstAvailable] = useState("");

  useEffect(() => {
    if (firmDetails.documents) {
      setSelectedDocs({ document: firmDetails.documents });
    }
  }, [firmDetails.documents]);

  const removeExistingDoc = (documentType) => {
    setSelectedDocs((prevState) => {
      const updatedDocuments = prevState.document.filter(
        (doc) => doc.documentType !== documentType
      );

      return {
        ...prevState,
        document: updatedDocuments,
      };
    });
  };
 

  const [formData, setFormData] = useState({
    qualification: "",
    totalExperience: "",
    officialEmail: "",
    gstAvailable: gstAvailable,
  });

  React.useEffect(() => {
    setFormData({
      qualification: response?.qualification || "",
      totalExperience: response?.workExperience || "",
      officialEmail: firmDetails?.officialEmail || "",
      gstAvailable: firmDetails?.gstAvailable || "",
    });

    setCurrentEmployeer({
      workfrom: firmDetails?.workfrom ? dayjs(firmDetails.workfrom) : "",
      worktill: firmDetails?.worktill ? dayjs(firmDetails.worktill) : "",
      businessId: firmDetails?.businessId || "",
      gstNumber: firmDetails?.gstNumber || "",
      shopActNumber: firmDetails?.shopActNumber || "",
      firmName: firmDetails?.firmName || "",
      natureOfBusiness: firmDetails?.natureOfBusiness || "",
      firmVintage: firmDetails?.firmVintage || "",
      numberOfEmployeesInFirm: firmDetails?.numberOfEmployeesInFirm || "",
      yearsInCurrentProfession: firmDetails?.DateCount || "",
      incorporationMonth: firmDetails?.incorporationMonth
        ? dayjs(firmDetails.incorporationMonth)
        : "",
      firmPremisesOwner: firmDetails?.firmPremisesOwner || "",
      businessPremisesArea: firmDetails?.businessPremisesArea || "",
      firmPremisesVintage: firmDetails?.firmPremisesVintage || "",

    });

    setCurrentAddress(() => {
      return {
        line1: firmDetails?.address?.line1,
        line2: firmDetails?.address?.line2,
        pincode: firmDetails?.address?.pincode,
        city: firmDetails?.address?.city,
        state: firmDetails?.address?.state,
        addressSource: firmDetails?.address?.addressSource || "11212",
        businessAddrId: firmDetails?.address?.businessAddrId || "11212",
      };

    });

  }, [response, firmDetails]);

  const [currentEmployeer, setCurrentEmployeer] = React.useState({
    workfrom: "",
    worktill: "",
    businessId: "",
    gstNumber: "",
    shopActNumber: "",
    firmName: "",
    natureOfBusiness: "",
    firmVintage: "",
    numberOfEmployeesInFirm: "",
    yearsInCurrentProfession: "",
    firmPremisesVintage: "",
    incorporationMonth: null,
    firmPremisesOwner: "",
    businessPremisesArea: "",
    shopActNumber: "",
    selectedDocs: "",
  });

  const [isFormValid, setIsFormValid] = useState(false);

  const handleChange = (e) => {
    setGstn(e.target.value);
  };
  const [selectedYear, setSelectedYear] = useState(null);
  const fileInputRefID = useRef(null);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [registrationDocument, setRegistrationDocument] = React.useState({});
  const [gstn, setGstn] = React.useState("");
  const handleYearChange = (date) => {
    if (date) {
      setSelectedYear(date);
      setFormData({
        ...formData,
        qualificationYear: date,
      });
    }
  };

  const handleFileChangeID = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFormData({
          ...formData,
          registrationCertificateDoc: {
            ...formData.registrationCertificateDoc,
            documentData: e.target.result.split(",")[1], // Assuming base64 data
            documentName: file.name,
            documentType: file.type,
          },
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUploadDocument = () => {
    setPopupOpen(true);
  };

  const handlePopupClose = (selectedFileNames) => {

    if (selectedFileNames.length > 0) {
      setSelectedDocs((prevSelectedEmployer) => {
        const filteredDocuments = prevSelectedEmployer.document
          ? prevSelectedEmployer.document.filter(
            prevDoc => !selectedFileNames.some(newDoc =>
              newDoc.documentType === prevDoc.documentType &&
              newDoc.fileName === prevDoc.fileName
            )
          )
          : [];

        // Add only the new documents to the filtered list
        const uniqueDocuments = [
          ...filteredDocuments,
          ...selectedFileNames.filter(newDoc =>
            !filteredDocuments.some(prevDoc =>
              prevDoc.documentType === newDoc.documentType &&
              prevDoc.fileName === newDoc.fileName
            )
          )
        ];

        return {
          ...prevSelectedEmployer,
          document: uniqueDocuments,
        };
      });
    }

    setPopupOpen(false);
  };

  const onSave = (obj) => {
    setRegistrationDocument(obj);
    setPopupOpen(false);
  };

  const [verified, setVerified] = useState(false);
  const [isUserInteracted, setIsUserInteracted] = useState(false);
  const [shopActNoVerified, setShopActNoVerified] = React.useState(false);
  // businessId: firmDetails?.businessId, // Ensure firmDetails is defined and accessible

  const handleVerification = async (e) => {
    const { name, value } = e.target;
    setIsUserInteracted(true);
    setIsVerifying(true);

    if (name === 'gstNumber' && value.length === 15) {
      const payload = {
        gstin: value,
        businessId: firmDetails?.businessId, // Ensure businessId is defined and accessible
      };

      try {
        const response = await businessLoanVerifyGSTN(payload);

        if (response?.data?.httpResponseCode === 200) {
          if (response?.data?.businessStatusCode === 2) {
            setGstnVerified(true);
            // setIsValidGST(true);
            setIsGSTVerified(false);
            setIsVerifying(false);
          } else if (response?.data?.businessStatusCode === 1) {
            setIsGSTVerified(true);
            setGstnVerified(false);
            setIsVerifying(false);
          }
        } else {
          setGstnVerified(false);
          // setIsValidGST(false);
          setIsVerifying(false);
        }
      } catch (error) {
        handleApiError(error);
        console.error('Error verifying GST Number:', error);
        setGstnVerified(false);
        // setIsValidGST(false);
        setIsVerifying(false);
      }
    } else {
      setIsVerifying(false);
    }

    // Update form validity state
    checkFormValid();
  };

  const handleVerification1 = async (e) => {
    const { name, value } = e.target;
    setGstTouched(true);
    if (name === "shopActNumber") {
      if (value === "") {
        setShopActNoVerified(false);
      }
    }
  };

  //email dialog code started
  const [isOpenEmailDialog, setOpenEmailDialog] = useState(false);
  const [isEmailAdded, setEmailAdded] = React.useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [isInvalidOtp, setIsInvalidOtp] = useState(true);
  const inputRefs = useRef([]);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const handleInputKeyDown = (index, e) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      // Move focus to the previous input on Backspace
      inputRefs.current[index - 1].focus();
    }
  };
  const closeEmailDialog = () => {
    setOpenEmailDialog(false);
    if (!isEmailAdded) {
      setFormData({
        ...formData,
        officialEmail: "",
      });
    }
  };

  useEffect(() => {
    inputRefs?.current[0]?.current?.focus();
    const timerStartTime = parseInt(localStorage.getItem("timerStartTime"));
    if (timerStartTime) {
      const elapsedTime = Math.floor((Date.now() - timerStartTime) / 1000);
      const remainingTime = Math.max(0, 120 - elapsedTime);
      setResendTimer(remainingTime);
    } else {
      saveTimerStartTime(); // Call saveTimerStartTime if timerStartTime doesn't exist in local storage
    }
  }, []);

  const saveTimerStartTime = () => {
    localStorage.setItem("timerStartTime", Date.now().toString());
  };

  const handleVerifyClick = async (e) => {
    setIsButtonClicked(true);
    setIsLoading2(true);

    e.preventDefault();

    // setOpenOTPDialog(false);
    // setOpenEmailDialog(false);
    const requestBody = {
      otp: otp.join(""), // Join the otp array into a string
    };

    if (otp.join("") === "") {
      // Check if otp is empty
      setIsInvalidOtp(true);
      inputRefs.current[0].current.focus();
      return;
    }

    try {
      const response = await businessLoanEmailOtpValidation(requestBody);
      if (
        response.data.httpResponseCode === 200 &&
        response.data.businessStatusCode === 2
      ) {
        setOpenOTPDialog(false);
        setOpenEmailDialog(false);
        setEmailAdded(true);
        toast.success("Email Added Successfully.");
      } else {
        setEmailAdded(false);
      }
    } catch (error) {
      handleApiError(error);
      console.error("Error during OTP verification:", error);
    } finally {
      // Reset loading state after the operation is complete
      setIsLoading2(false);
      setIsButtonClicked(false);
    }
    //TODO1 remove below two lines
    // setOpenOTPDialog(false);
    // setEmailAdded(true);
  };

  const [isOpenOTPDialog, setOpenOTPDialog] = useState(false);
  const openOTPDialog = async () => {
    setOtp(["", "", "", "", "", ""]);
    setResendTimer(120);
    localStorage.removeItem("timerStartTime");
    saveTimerStartTime();


    const requestBody = { id: formData.officialEmail }
    try {
      const response = await businessLoanEmailVerification(requestBody);
      if (response.data.httpResponseCode === 200 && response.data.businessStatusCode == 2) {
        setOpenOTPDialog(true);
        // setIsInvalidOtp(false);
        toast.success(response.data.message);
      } else {
        console.log("Unexpected response status:", response?.status);
      }

    } catch (error) {
      if (error.response && error?.response?.status === 401) {
        await handleSignOut();
        toast.error("Your Session has expired.You will be redirected to Login Page.")
        navigate('/')
      } else if (error.response && error?.response?.status === 429) {
        toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
      } else {
        toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
      }
      console.error("Error during OTP verification:", error);
    }
  }

  const handleReSendOtp = async () => {
    setOtp(["", "", "", "", "", ""]);
    setResendTimer(120);
    localStorage.removeItem("timerStartTime");
    saveTimerStartTime();

    const requestBody = { id: formData.officialEmail }

    try {
      // setOpenOTPDialog(true);
      const response = await businessLoanEmailVerification(requestBody);
      // setOpenOTPDialog(true);
      // setIsInvalidOtp(false);
      if (response.data.httpResponseCode === 200 && response.data.businessStatusCode === 2) {
        setOpenOTPDialog(true);
        // setOtpResend(true);
        toast.success("OTP Resend Successfully");
      } else {
        toast.error(response.data.message);
        setApiError("Login failed. Invalid credentials.");
      }
    } catch (error) {
      handleApiError(error);
      setApiError("An error occurred while communicating with the server.");
    }
  };

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (index < otp.length - 1 && value !== "") {
      // Move focus to the next input if not on the last input and a digit is entered
      inputRefs.current[index + 1].focus();
    }
    const isAllFieldsFilled = newOtp.every((otpValue) => otpValue !== "");
    // Enable or disable the "Continue" button based on the OTP fields status
    setIsInvalidOtp(!isAllFieldsFilled);
  };

  const isEmailValid = (email) => {
    return emailRegex.test(email);
  };
  //email dialog code ended

  //address dialog code started
  const [openAddressDialog, setOpenAddressDialog] = useState(false);
  const [isAddressAdded, setAddressAdded] = React.useState(false);
  // const [isDocumentAdded, setDocumentAdded] = React.useState(false);
  const [isDocumentAdded, setDocumentAdded] = useState(false);
  const [pincodeError, setPincodeError] = React.useState("");
  const [currentAddress, setCurrentAddress] = React.useState({
    line1: "",
    line2: "",
    pincode: "",
    city: "",
    state: "",
    businessAddrId: "",
    addressSource: "",
  });



  // const openAddrDialog = () => {
  //   const fetchBusinessAddress = async () => {
  //     const requestBody = {
  //       businessId: response?.firmDetails?.[0]?.businessId,
  //     };
  //     try {
  //       const fetchAddress = await businessLoanFetchAddressApi(requestBody);
  //       console.log("fetchAddress", fetchAddress?.data);
  //       setAddresses(fetchAddress?.data?.addresses);
  //       // Check if any address is selected by the user
  //       const selectedAddress = fetchAddress?.data?.addresses?.find(
  //         (address) => address.selectedByUser
  //       );
  //       setSelectedAddress(selectedAddress)
  //       console.log("businessAddressSource", selectedAddress?.businessAddressSource);
  //       console.log("selectedAddress?.businessAddressId", selectedAddress?.businessAddressId);

  //       // If an address is selected by the user, set it as the current address
  //       if (selectedAddress) {
  //         // setCurrentAddress({
  //         //   line1: selectedAddress.line1,
  //         //   line2: selectedAddress.line2,
  //         //   pincode: selectedAddress.pin,
  //         //   city: selectedAddress.dist,
  //         //   state: selectedAddress.state,
  //         //   businessAddressSource: selectedAddress?.businessAddressSource||"wee",
  //         //   businessAddressId: selectedAddress?.businessAddressId||"wewe",
  //         // });
  //         setSelectedAddress(true);
  //       }

  //     } catch (error) {
  //       handleApiError(error);
  //       console.error("Error fetching user data:", error);
  //     }
  //   };
  //   fetchBusinessAddress();
  //   setOpenAddressDialog(true);
  //   setPincodeError("");
  // };

  const [hasFetched, setHasFetched] = useState(false); 
  const hasFetchedRef = useRef(false); 


  // Call fetchBusinessAddress when the condition is met
  const [isEditMode, setIsEditMode] = useState(false); 
  const openAddrDialog = (isEditMode = false) => {
    // Track Edit or Add mode
    setIsEditMode(isEditMode);
  
    if (isEditMode) {
      // Edit Mode: Only map addresses from response2.addresses (no API call)
      setShowAdditionalDiv(false);
  
      // Set addresses directly from response2
      setAddresses(response2?.addresses || []);
    } else {
      // Add Mode: Hide "Add New" section and reset current address fields
      setShowAdditionalDiv(false);
      setCurrentAddress({
        line1: "",
        line2: "",
        city: "",
        state: "",
        pincode: "",
      });
  
      // Call API only in Add Mode
      const fetchBusinessAddress = async () => {
        const requestBody = {
          businessId: response?.firmDetails?.[0]?.businessId,
        };
  
        try {
          const fetchAddress = await businessLoanFetchAddressApi(requestBody);
          console.log("fetchAddress", fetchAddress?.data);
  
          const fetchedAddresses = fetchAddress?.data?.addresses || [];
          let finalAddresses = [];
  
          // Merge addresses conditionally
          if (response2.apId === responseApId) {
            finalAddresses = [
              // ...fetchedAddresses,
              response2.addresses.filter(
                (responseAddr) =>
                  !fetchedAddresses.some(
                    (fetchedAddr) =>
                      fetchedAddr.businessAddressId === responseAddr.businessAddressId
                  )
              ),
            ];
          } else {
            finalAddresses = fetchedAddresses;
          }
  
          // Check if any address has 'selectedByUser: true'
          const selectedAddress = finalAddresses.find((addr) => addr.selectedByUser);
  
          // Update state
          setAddresses(finalAddresses);
          if (selectedAddress) {
            setSelectedAddress(selectedAddress);
          }
        } catch (error) {
          handleApiError(error);
          console.error("Error fetching user data:", error);
        }
      };
  
      // Call the API only in Add Mode
      fetchBusinessAddress();
    }
  
    setOpenAddressDialog(true);
    setPincodeError(""); // Reset pincode error
  };
  
  
  
  
  
  
  

  const isPincodeValid = (pincode) => /^\d{6}$/.test(pincode);
  const isCityValid = (city) => /^[a-zA-Z\s]+$/.test(city);
  const isStateValid = (state) => /^[a-zA-Z\s]+$/.test(state);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [showGSTField, setShowGSTField] = useState(false); // Initial state to hide GST field

  const handleRadioChange = (e) => {
    const value = e.target.value;
    setFormData((prev) => ({
      ...prev,
      gstVerify: value,
    }));
    setShowGSTField(value === "Available"); // Show GST field only if "Yes" is selected

    if (value === "Unavailable") {
      // Clear the GST Number field when "No" is selected
      setCurrentEmployeer((prev) => ({
        ...prev,
        gstNumber: ""
      }));
      setGstnVerified(false);
    }

    checkFormValid();
  };


  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setCurrentAddress((prevState) => ({
      ...prevState,
      [name]: value,
      businessAddrType: "OTHER",
    }));
  };

  const fetchAddressDetails = async (pincode) => {
    try {
      let data = {
        pinCode: pincode,
      };
      const response = await BusinessfetchDistStateAPI(data);
      const { district, stateName } = response.data;
      setCurrentAddress((prevState) => ({
        ...prevState,
        city: district || "",
        state: stateName || "",
      }));
    } catch (error) {
      console.error("Error fetching address details:", error);
      setCurrentAddress((prevState) => ({
        ...prevState,
        city: "",
        state: "",
      }));
    }
  };


  const checkFormFilled = () => {
    if (!currentAddress) return;

    const { line1, line2, pincode, city, state, addressSource, businessAddrId } = currentAddress;

    // Validate each field
    const isLine1Filled = line1 && line1.trim() !== "" && /^[a-zA-Z0-9\s.,-_]+$/.test(line1);
    const isLine2Filled = line2 && line2.trim() !== "" && /^[a-zA-Z0-9\s.,-_]+$/.test(line2);
    const isPincodeFilled = pincode && pincode.trim() !== "" && /^\d{6}$/.test(pincode);
    const isCityFilled = city && city.trim() !== "" && /^[a-zA-Z\s]+$/.test(city);
    const isStateFilled = state && state.trim() !== "" && /^[a-zA-Z\s]+$/.test(state);

    // Additional validation checks
    let isPincodeValidated = isPincodeFilled && isPincodeValid(pincode);
    if (pincode === "000000") {
      isPincodeValidated = false;
    }
    // setPincodeError(isPincodeValidated ? "" : "Invalid pincode");

    const isCityValidated = isCityFilled && isCityValid(city);
    const isStateValidated = isStateFilled && isStateValid(state);

    // Check if an address is selected or if new address form is displayed
    const isAnyAddressSelected = addresses?.some(address => address.selectedByUser);
    const isAddressFilled = isAnyAddressSelected || showAdditionalDiv;

    // Update form filled state
    const formIsValid = (
      (isLine1Filled && isLine2Filled && isPincodeFilled && isCityFilled && isStateFilled &&
        isPincodeValidated && isCityValidated && isStateValidated && isAddressFilled) ||
      isAnyAddressSelected
    );

    setIsFormFilled(formIsValid);
  };



  useEffect(() => {
    checkFormFilled();
  }, [
    formData,
    currentAddress,
    addresses,
    showAdditionalDiv,
    isPincodeValid,
    isCityValid,
    isStateValid
  ]);



  const closeAddrDialog = () => {
    // Deselect all addresses
    // addresses?.forEach((item) => {
    //   item.selectedByUser = false;
    // });

    // // Clear selected address and reset the address state
    // setSelectedAddress(null);/

    // Close the dialog
    setOpenAddressDialog(false);

    // Reset current address if no address is added
    if (!isAddressAdded) {
      setCurrentAddress({
        businessAddrId: "",
        addressSource: "",
        line1: "",
        line2: "",
        pincode: "",
        city: "",
        state: "",
        businessAddressSource: "",
        businessAddressId: "",
      });
    }
  };

  //address dialog ended

  const handleBack = () => {
    onBack();
  };

  //add bank dialog started

  const cancelBankDialog = () => {
    setOpenBankDialog(false);
  };

  const onBankDataSave = (data) => {
    setBankDetails(data);
    setOpenBankDialog(false);
  };

  const convertMonthsToDays = (months) => {
    return months * 30;
  };

  const [totalDays, setTotalDays] = React.useState(
    response
      ? convertMonthsToDays(
        parseInt(response.acceptedEmployerTimePeriodInMonths, 10)
      )
      : 0
  );
  const [isPreviousEmpReq, setPreviousEmpReq] = React.useState(false);
  const [prevEmpType, setPrevEmpType] = React.useState("");
  const [prevBankDetails, setPrevBankDetails] = React.useState();
  const handleWorkingSince = (e) => {
    setCurrentEmployeer({
      ...currentEmployeer,
      workfrom: e,
    });
    checkFormValid();

    let formattedDate = new Date(e).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    let dateStr = formattedDate;
    let dateParts = dateStr.split("/");
    let date = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
    let today = new Date();
    let differenceInTime = date.getTime() - today.getTime();
    let differenceInDays = Math.abs(
      Math.ceil(differenceInTime / (1000 * 3600 * 24))
    );
    if (differenceInDays < totalDays) {
      setPreviousEmpReq(true);
    } else {
      setPreviousEmpReq(false);
    }
  };

  const onPrevBankDetailsSave = (data) => {
    setPrevBankDetails(data);
  };
  //add bank dialog eb=nded

  const currentDate = dayjs();
  const endOfYear = dayjs().endOf("year");

  const workfrom = currentEmployeer.workfrom
    ? currentEmployeer.workfrom.format("YYYY-MM-DD")
    : "";
  const worktill = currentDate.format("YYYY-MM-DD");

  // Calculate the difference in years
  const workfromDate = moment(workfrom, "YYYY-MM-DD");
  const worktillDate = moment(worktill, "YYYY-MM-DD");
  const differenceInYears = worktillDate.diff(workfromDate, "years", true);
  const DateCount1 = Math.floor(differenceInYears);
  const yearsInCurrentProfession1 = DateCount1.toString() || "";

  const handleContinue = async () => {
    setIsButtonClicked(true);
    setIsLoading3(true);


    let currentEmployeerPayload = {
      address: currentAddress,
      bankAccDetails: bankDetails,
      documents: selectedDocs.document,
      businessEmployerType: "Current Employer",
      businessId: currentEmployeer.businessId || "",
      empSource: "USER",
      firmName: currentEmployeer.firmName || "",
      firmVintage: currentEmployeer.firmVintage || "",
      ...(currentEmployeer.gstNumber
        ? { gstNumber: currentEmployeer.gstNumber }
        : {}),
      numberOfEmployeesInFirm: currentEmployeer.numberOfEmployeesInFirm || "",
      shopActNumber: currentEmployeer.shopActNumber || "",
      officialEmail: formData.officialEmail,
      workfrom: currentEmployeer.workfrom
        ? currentEmployeer.workfrom.format("YYYY-MM-DD")
        : "",
      worktill: currentDate.format("YYYY-MM-DD"),
      incorporationMonth:
        currentEmployeer.incorporationMonth.format("YYYY-MM-DD"),
      firmPremisesOwner: currentEmployeer.firmPremisesOwner || "",
      businessPremisesArea: currentEmployeer.businessPremisesArea || "",
      firmPremisesVintage: currentEmployeer.firmPremisesVintage || "",
      yearsInCurrentProfession: yearsInCurrentProfession1 || "",
      natureOfBusiness: currentEmployeer.natureOfBusiness || "",
    };

    let firmDetailsPayload = [];
    firmDetailsPayload = [currentEmployeerPayload];
    let payload = {
      acceptedEmployerTimePeriodInMonths: "",
      qualification: formData.qualification,
      workExperience: formData.totalExperience,
      firmDetails: firmDetailsPayload,

    };

    try {
      const response = await businessLoanStoreProfessionalDetailsAPI(payload);

      if (
        response.data.httpResponseCode === 200 &&
        response.data.businessStatusCode === 2
      ) {
        setShowReference(true);
        // aaSuccess(true);
      } else {
        // onContinue();
        // setActiveStep(5);
        // setShowAccountAggregator(true);
        // aaSuccess(false);
        toast.error(response.data.message);
        // setShowReference(true);
      }
    
    } catch (error) {
      handleApiError(error);
      console.error("Error storing employee history:", error);
    } finally {
      // Reset loading state after the operation is complete
      setIsLoading3(false);
      setIsButtonClicked(false);

    }
  };

  const [professionType, setProfessionType] = React.useState(
    localStorage.getItem("profession") || ""
  );

  // Fetching dropdown
  useEffect(() => {
    //fetching Qualification dropdown
    
    let apiPayload1 = {
      type: "TOTAL_EXPERIENCE",
      key: "TOTAL_EXPERIENCE",
      source: "json_value",
    };
    fetchDropDownValues(apiPayload1, "TOTAL_EXPERIENCE");

    let apiPayload3 = {
      type: "FIRM_PREMISES_OWNER",
      key: "FIRM_PREMISES_OWNER",
      source: "json_value",
    };
    fetchDropDownValues(apiPayload3, "FIRM_PREMISES_OWNER");

    let apiPayload4 = {
      type: "FIRM_VINTAGE",
      key: "FIRM_VINTAGE",
      source: "json_value",
    };
    fetchDropDownValues(apiPayload4, "FIRM_VINTAGE");

    let apiPayload6 = {
      type: "PERSONAL_LOAN_SELF_EMPLOYED",
      key: "NO_OF_EMPLOYEES",
      source: "json_value",
    };
    fetchDropDownValues(apiPayload6, "PERSONAL_LOAN_SELF_EMPLOYED");

    let apiPayload7 = {
      type: "BUSINESS_LOAN",
      key: "BORROWER_QUALIFICATION",
      source: "json_value"
    }
    fetchDropDownValues(apiPayload7, "BORROWER_QUALIFICATION");

    let apiPayload8 = {
      type: "NATURE_OF_BUSINESS",
      key: "NATURE_OF_BUSINESS",
      source: "json_value"
    }
    fetchDropDownValues(apiPayload8, "NATURE_OF_BUSINESS");

    let apiPayload9 = {
      type: "BUSINESS_LOAN",
      key: "BUSINESS_PREMISES_AREA",
      source: "json_value"
    }
    fetchDropDownValues(apiPayload9, "BUSINESS_LOAN");
  }, []);
  const fetchDropDownValues = async (apiPayload, dropdownType) => {
    
    setQualificationDropdown([]);
    setTotalExpDropdown([]);
    setMedicalCouncilDropdown([]);
    setNatureOfBusinessDropdown([]);
    setFirmVintageOwnerDropdown([]);
    setFirmPrimisesAreaDropdown([]);
    setFirmVintageDropdown([]);
    setNoOfEmployeesDropdown([]);
    setFirmPremisesVintageDropdown([]);
    setCurrentProfessionYearsDropdown([]);
    try {
      const response = await businessLoanFetchDataAPI(apiPayload);

      if (dropdownType == "BORROWER_QUALIFICATION") {
        setQualificationDropdown(response.data[0].values);
      } else if (dropdownType == "TOTAL_EXPERIENCE") {
        setTotalExpDropdown(response.data[0].values);
      } else if (dropdownType == "TOTAL_EXPERIENCE") {
        setFirmPremisesVintageDropdown(response.data[0].values);
      } else if (dropdownType == "FIRM_PREMISES_OWNER") {
        setFirmVintageOwnerDropdown(response.data[0].values);
      } else if (dropdownType == "FIRM_VINTAGE") {
        setFirmVintageDropdown(response.data[0].values);
      } else if (dropdownType == "PERSONAL_LOAN_SELF_EMPLOYED") {
        setNoOfEmployeesDropdown(response.data[0].List);
      } else if (dropdownType == "NATURE_OF_BUSINESS") {
        setNatureOfBusinessDropdown(response.data[0].values);
      } else if (dropdownType == "BUSINESS_LOAN") {
        setFirmPrimisesAreaDropdown(response.data[0].business_premises_area);
      } else {
      }
    } catch (error) {
      handleApiError(error);
      // console.error("Error fetching address vintage:", error);
    }
  };

  const currentYear = dayjs().year();
  const currentMonth = dayjs().month();
  const disableFutureYears = (date) => {
    return date.year() > currentYear;
  };

  const disableFutureYearsAndMonth = (date) => {
    return (
      date.year() > currentYear ||
      (date.year() === currentYear && date.month() > currentMonth)
    );
  };

  // const disableFutureYearsAndMonth1 = (date) => {
  //   return date.year() > currentDate.year();
  // };

  const checkFormValid = () => {
    // Determine if the Shop Act Number is present and set the document requirement
    const isShopActNumberPresent = currentEmployeer?.shopActNumber?.length > 0;
    const requiredDocumentCount = isShopActNumberPresent ? 4 : 3;
    const areDocumentsValid = selectedDocs?.document?.length >= requiredDocumentCount;

    const isGSTValidCondition =
      formData.gstAvailable === "Unavailable" ||
      (formData.gstAvailable === "Available" && currentEmployeer?.gstNumber && isValidGST);

    const isGstVerifySelected = formData.gstAvailable === "Available" || formData.gstAvailable === "Unavailable";

    const isDateComplete =
      currentEmployeer?.workfrom &&
      !isNaN(new Date(currentEmployeer.workfrom).getTime());

    // Validate form fields
    const isFormFilled =
      formData?.qualification &&
      formData?.totalExperience &&
      currentEmployeer?.firmName &&
      currentEmployeer?.firmVintage &&
      currentEmployeer?.numberOfEmployeesInFirm &&
      isDateComplete &&
      currentEmployeer?.incorporationMonth &&
      currentEmployeer?.firmPremisesOwner &&
      currentEmployeer?.businessPremisesArea &&
      currentEmployeer?.firmPremisesVintage &&
      currentEmployeer?.natureOfBusiness &&
      bankDetails &&
      currentAddress &&
      areDocumentsValid &&
      isGstVerifySelected &&
      isGSTValidCondition &&
      // (currentEmployeer?.gstNumber === "" || isValidGST) 
      (addresses?.some(address => address.selectedByUser) || showAdditionalDiv); // Ensure address is selected or new address is added


    setIsFormValid(isFormFilled);
  };





  useEffect(() => {
    checkFormValid();
  }, [
    formData,
    currentAddress,
    bankDetails,
    gstnVerified,
    isValidGST,
    currentEmployeer,
    registrationDocument,
    selectedDocs,
    isAddressAdded, // Used to determine if an address is added
    selectedAddress // Used to determine if a specific address is selected
  ]);
  // useEffect(() => {
  //   const fetchBusinessAddress = async () => {
  //     const requestBody = {
  //       businessId: response?.firmDetails?.[0]?.businessId,
  //     };
  //     try {
    
  //       const fetchAddress = await businessLoanFetchAddressApi(requestBody);
  //       console.log("fetchAddress", fetchAddress?.data);

  //       const addressesData = fetchAddress?.data?.addresses || [];
  //       setAddresses(addressesData);

  //       // Check if any address is already selected
  //       const selectedAddress = addressesData.find((address) => address.selectedByUser);
  //       if (selectedAddress) {
  //         setSelectedAddress(selectedAddress);
  //         setShowAdditionalDiv(false); // Hide the "Add New" section
  //       } else {
  //         setShowAdditionalDiv(true); // Show the "Add New" section if no address is selected
  //       }
  //       if (fetchAddress.addresses) {
  //         const uniqueAddresses = fetchAddress.addresses.filter(
  //           (newAddress) =>
  //             !addresses.some(
  //               (existingAddress) =>
  //                 existingAddress.businessAddressId === newAddress.businessAddressId
  //             )
  //         );
  //         setAddresses([...addresses, ...uniqueAddresses]);
  //       }
  //     } catch (error) {
  //       handleApiError(error);
  //       console.error("Error fetching user data:", error);
  //     }
  //   };

  //   fetchBusinessAddress();
   
  // }, [
  //  // Used to determine if a specific address is selected
  // ]);

  useEffect(() => {
    const fetchProfessionalDetails = async () => {
      try {
        // First API call
        const fetchResponse = await businessLoanFetchProfessionalDetailsAPI();
        console.log("fetchResponse", fetchResponse);
  
        // Extract required data from the response
        const firmDetailsData = fetchResponse?.data?.firmDetails || [];
        const gstAvailable = firmDetailsData?.[0]?.gstAvailable;
        const businessId = firmDetailsData?.[0]?.businessId;
  
        // Update state with fetched details
        setResponse(fetchResponse?.data);
        setFirmDetails(firmDetailsData?.[0]);
        setGstgstAvailable(gstAvailable);
        setFormData({
          ...formData,
          gstAvailable: gstAvailable,
        });
        setShowGSTField(gstAvailable === "Available");
  
        // If businessStatusCode is 2 and businessId is available, make the second API call
        if (fetchResponse?.data?.businessStatusCode === 2 && businessId) {
          await fetchBusinessAddress(businessId);
        }
      } catch (error) {
        console.error("Error fetching professional details:", error);
        handleApiError(error);
      }
    };
  
    const fetchBusinessAddress = async (businessId) => {
      try {
        // Second API call with the businessId
        const requestBody = { businessId };
        const fetchAddress = await businessLoanFetchAddressApi(requestBody);
        console.log("fetchAddress", fetchAddress);
  
        setAddresses(fetchAddress?.data?.addresses);
  
        // Handle selected address logic
        const selectedAddress = fetchAddress?.data?.addresses?.find(
          (address) => address.selectedByUser
        );
        if (selectedAddress) {
          setCurrentAddress({
            line1: selectedAddress.line1,
            line2: selectedAddress.line2,
            pincode: selectedAddress.pin,
            city: selectedAddress.dist,
            state: selectedAddress.state,
          });
          setSelectedAddress(true);
        }
      } catch (error) {
        console.error("Error fetching business address:", error);
        handleApiError(error);
      }
    };
  
    fetchProfessionalDetails();
  }, [navigate]);
  

  // const handleDateChange = (e) => {
  //   if (e) {
  //     handleWorkingSince(e);
  //   }
  // };
  const handleDateChange = (e) => {
    if (e) {
      handleWorkingSince(e);
    } else {
      // Set workfrom to null if the date is incomplete
      setCurrentEmployeer({
        ...currentEmployeer,
        workfrom: null,
      });
      setIsFormValid(false); // Immediately disable the Continue button if date is incomplete
    }
  };


  const [gstError, setGstError] = useState(false);
  const handleDateChange1 = (date) => {
    if (date) {
      setCurrentEmployeer({
        ...currentEmployeer,
        incorporationMonth: date,
      });
    }
  };
  const handleChange1 = (e) => {
    const { value } = e.target;
    setCurrentEmployeer({
      ...currentEmployeer,
      gstNumber: value,
    });


    // Validate GST number format
    // const updatedValue = value.trim().toUpperCase();
    // const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    // const isValid = gstRegex.test(updatedValue);

    // if (value.length === 15 && isValid) {
    //   setIsValidGST(true);
    //   setGstError("");
    //   setGstnVerified(false);
    //   setIsGSTVerified(false);
    // } else {
    //   setIsValidGST(false);
    //   setGstError(value.length === 0 ? "" : "Invalid GST Number. Please enter in the specified format.");
    // }
    // if (!isValid && updatedValue.length > 0) {
    //   setGstError(
    //     "Invalid GST Number. Please enter in the specified format."
    //   );
    //   setIsVerifying(false);
    //   setGstnVerified(false);
    //   setIsGSTVerified(false);
    // } else {
    //   setGstError("");
    // }
    const updatedValue = value.trim().toUpperCase();
    const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}Z[0-9A-Z]{1}$/;
    const isValid = gstRegex.test(updatedValue);

    if (updatedValue.length === 15 && isValid) {
      setIsValidGST(true);
      setGstError("");
      setGstnVerified(false);
      setIsGSTVerified(false);
    } else {
      setIsValidGST(false);
      setGstError(
        updatedValue.length === 0
          ? ""
          : "Invalid GST Number. Please enter in the specified format."
      );
      setGstnVerified(false);
      setIsGSTVerified(false);
    }
    // Update form validity state
    checkFormValid();
  };

  return (
    <>
      {!showReference ? (
        <>
          <h6 className="text">Qualification & Experience</h6>
          <p>Share your academic background and industry experience</p>

          <form className="mt-4">
            <>
              <div className="row mt-4">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <ArthaFormControl fullWidth>
                    <InputLabel id="qualification-label">
                      Qualification / Degree
                    </InputLabel>
                    <Select
                      labelId="qualification-label"
                      id="qualification"
                      name="qualification"
                      value={formData.qualification}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          qualification: e.target.value,
                        })
                      }
                    >
                      {qualificationDropdown &&
                        qualificationDropdown.map((item) => {
                          return (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </ArthaFormControl>
                </div>

                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <ArthaFormControl fullWidth>
                    <InputLabel id="totalExperience-label">
                      Total Business Experience
                    </InputLabel>
                    <Select
                      labelId="totalExperience-label"
                      id="totalExperience"
                      name="totalExperience"
                      value={formData.totalExperience}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          totalExperience: e.target.value,
                        })
                      }
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        PaperProps: {
                          className: "custom-scrollbar", // Apply custom scrollbar class
                          style: {
                            maxHeight: 120, // Adjust the max height to control the size of the dropdown
                          },
                        },
                      }}
                    >
                      {totalExpDropdown.map((item, index) => {
                        const [value, label] = item.split(" ");
                        return (
                          <MenuItem key={index} value={value}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </ArthaFormControl>
                </div>
              </div>
            </>

            <div className="row mt-3 mt-md-4">
              <div className="col-8 col-md-12">
                <p>Please provide minimum 2 years of business details</p>
              </div>
            </div>

            <div className="row mt-3 mt-md-4">
              <div className="col-8 col-md-12">
                <h6 className="text">Business Details</h6>
                <p>Enter Details of current Business</p>
              </div>
            </div>

            <div className="row mt-5 mt-md-5">
              <div className="col-md-12 col-sm-6 col-lg-6">
                <div className="col-md-12 p-0">
                  <p className="mt-n3 mb-0">Do you have a GST number?</p>
                </div>
                <div className="row">
                  <div className="col-auto">
                    <FormControlLabel
                      control={
                        <Radio
                          name="gstAvailable"
                          value="Available"
                          checked={formData.gstAvailable === "Available"}
                          // onChange={handleRadioChange}
                          onChange={(e) => {
                            setFormData({ ...formData, gstAvailable: "Available" });
                            setShowGSTField(true);
                          }}
                          className={
                            formData.gstAvailable === "Available" ? "radio-selected" : ""
                          }
                        />
                      }
                      label="Yes"
                    />
                  </div>
                  <div className="col-auto">
                    <FormControlLabel
                      control={
                        <Radio
                          name="gstAvailable"
                          value="Unavailable"
                          checked={formData.gstAvailable === "Unavailable"}
                          onChange={(e) => {
                            setFormData({ ...formData, gstAvailable: "Unavailable" });

                            if (e.target.value === "Unavailable") {
                              // Clear the GST Number field when "No" is selected
                              setCurrentEmployeer((prev) => ({
                                ...prev,
                                gstNumber: ""
                              }));
                              setGstnVerified(false);
                              setShowGSTField(false);
                              setGstError("");
                            }
                          }}
                          className={formData.gstAvailable === "Unavailable" ? "radio-selected" : ""}
                        />
                      }
                      label="No"
                    />
                  </div>

                </div>
              </div>

              {/* Conditionally render the GST Number field based on showGSTField state */}
              {showGSTField && (
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <TextField
                    name="gstNumber"
                    value={currentEmployeer.gstNumber}
                    label="GST Number"
                    fullWidth
                    inputProps={{ maxLength: 15 }}
                    className={gstnVerified ? 'verifiedBorder' : ''}
                    InputProps={{
                      endAdornment: isVerifying ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <CircularProgress size={20} />
                          <Typography
                            variant="body2"
                            style={{ color: 'grey', marginLeft: '4px' }}
                          >
                            Verifying
                          </Typography>
                        </div>
                      ) : gstnVerified ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <CheckCircleIcon style={{ color: 'green' }} />
                          <Typography
                            variant="body2"
                            style={{ color: 'green', marginLeft: '4px' }}
                          >
                            Verified
                          </Typography>
                        </div>
                      ) : currentEmployeer.gstNumber && isGSTVerified ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <CancelIcon style={{ color: 'grey' }} />
                          <Typography
                            variant="body2"
                            style={{ color: 'grey', marginLeft: '4px' }}
                          >
                            Unverified
                          </Typography>
                        </div>
                      ) : null,
                    }}
                    onChange={handleChange1}
                    // error={!isValidGST || isGSTVerified}
                    error={Boolean(gstError) || isGSTVerified}
                    // helperText={
                    //   !isValidGST
                    //     ? 'Invalid GST Number'
                    //     : isGSTVerified
                    //       ? 'No record found for this GST number'
                    //       : ''
                    // }
                    helperText={
                      Boolean(gstError)
                        ? gstError
                        : isGSTVerified
                          ? "No record found for this GST number"
                          : ""
                    }
                    onBlur={handleVerification}
                  />
                </div>
              )}
            </div>

            <div className="row mt-3 mt-md-4">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <TextField
                  name="shopActNumber"
                  value={currentEmployeer.shopActNumber}
                  label="Shop Act Number (Optional)"
                  fullWidth
                  inputProps={{ maxLength: 80 }}
                  className={shopActNoVerified ? "verifiedBorder" : ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    setCurrentEmployeer({
                      ...currentEmployeer,
                      shopActNumber: value,
                    });

                    // When Shop Act Number is removed, remove related document
                    if (value === "") {
                      removeExistingDoc("Shop Act License / Udyam Aadhaar proof");
                    }
                  }}
                  onBlur={(e) => {
                    handleVerification1(e);
                  }}
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6">
                <TextField
                  name="firmName"
                  value={currentEmployeer.firmName}
                  label="Name of Firm / Business"
                  fullWidth
                  inputProps={{ maxLength: 80 }}
                  onChange={(e) =>
                    setCurrentEmployeer({
                      ...currentEmployeer,
                      firmName: e.target.value,
                    })
                  }
                />
              </div>


            </div>

            <div className="row mt-3 mt-md-4">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <ArthaFormControl fullWidth>
                  <InputLabel id="natureOfBusiness-label">
                    Nature of Firm / Business
                  </InputLabel>
                  <Select
                    labelId="natureOfBusiness-label"
                    id="natureOfBusiness"
                    name="natureOfBusiness"
                    value={currentEmployeer.natureOfBusiness}
                    onChange={(e) =>
                      setCurrentEmployeer({
                        ...currentEmployeer,
                        natureOfBusiness: e.target.value,
                      })
                    }
                  >
                    {natureOfBusinessDropdown &&
                      natureOfBusinessDropdown.map((item) => {
                        return (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </ArthaFormControl>
              </div>

              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <ArthaFormControl fullWidth>
                  <InputLabel id="firmVintage-label">
                    Firm / Business Vintage
                  </InputLabel>
                  <Select
                    labelId="firmVintage-label"
                    id="firmVintage"
                    name="firmVintage"
                    value={currentEmployeer.firmVintage}
                    onChange={(e) =>
                      setCurrentEmployeer({
                        ...currentEmployeer,
                        firmVintage: e.target.value,
                      })
                    }
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      PaperProps: {
                        className: "custom-scrollbar", // Apply custom scrollbar class
                        style: {
                          maxHeight: 120, // Adjust the max height to control the size of the dropdown
                        },
                      },
                    }}
                  >
                    {firmVintageDropdown &&
                      firmVintageDropdown.map((item, index) => {
                        const [value, label] = item.split(" ");
                        return (
                          <MenuItem key={index} value={value}>
                            {item}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </ArthaFormControl>
              </div>


            </div>

            <div className="row mt-3 mt-md-4">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <ArthaFormControl fullWidth>
                  <InputLabel id="noOfEmployees-label">
                    Number of Employees in Firm / Business
                  </InputLabel>
                  <Select
                    labelId="firmVintage-label"
                    id="firmVintage"
                    name="numberOfEmployeesInFirm"
                    value={currentEmployeer.numberOfEmployeesInFirm}
                    onChange={(e) =>
                      setCurrentEmployeer({
                        ...currentEmployeer,
                        numberOfEmployeesInFirm: e.target.value,
                      })
                    }
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      PaperProps: {
                        className: "custom-scrollbar", // Apply custom scrollbar class
                        style: {
                          maxHeight: 120, // Adjust the max height to control the size of the dropdown
                        },
                      },
                    }}
                  >
                    {noOfEmployeesDropdown &&
                      noOfEmployeesDropdown.map((item, index) => {
                        const [value, label] = item.split(" ");
                        return (
                          <MenuItem key={index} value={value}>
                            {item}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </ArthaFormControl>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Incorporation / Commencement Month"
                    value={currentEmployeer.incorporationMonth || null} // Ensure value is null if not defined
                    onChange={(date) => handleDateChange1(date)}
                    renderInput={(params) => <TextField {...params} />}
                    className="yearPicker"
                    format="MM-YYYY" // Set the format here
                    openTo="month" // Open the DatePicker with the month view
                    views={["month", "year"]}
                    shouldDisableDate={disableFutureYears}
                    maxDate={currentDate}
                  />
                </LocalizationProvider>
              </div>


            </div>

            <div className="row mt-3 mt-md-4">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <ArthaFormControl fullWidth>
                  <InputLabel id="firmPremisesOwner-label">
                    Firm / Business Premises Owner
                  </InputLabel>
                  <Select
                    labelId="firmPremisesOwner-label"
                    id="firmPremisesOwner"
                    name="firmPremisesOwner"
                    value={currentEmployeer.firmPremisesOwner}
                    onChange={(e) =>
                      setCurrentEmployeer({
                        ...currentEmployeer,
                        firmPremisesOwner: e.target.value,
                      })
                    }
                  >
                    {firmVintageOwnerDropdown &&
                      firmVintageOwnerDropdown.map((item) => {
                        return (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </ArthaFormControl>
              </div>

              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <ArthaFormControl fullWidth>
                  <InputLabel id="businessPremisesArea-label">
                    Firm / Business Premises Area
                  </InputLabel>
                  <Select
                    labelId="businessPremisesArea-label"
                    id="businessPremisesArea"
                    name="businessPremisesArea"
                    value={currentEmployeer.businessPremisesArea}
                    onChange={(e) =>
                      setCurrentEmployeer({
                        ...currentEmployeer,
                        businessPremisesArea: e.target.value,
                      })
                    }
                  >
                    {firmPrimisesAreaDropdown &&
                      firmPrimisesAreaDropdown.map((item) => {
                        return (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </ArthaFormControl>
              </div>


            </div>

            <div className="row mt-3 mt-md-4">
              {/* <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <ArthaFormControl fullWidth>
                  <InputLabel id="yearsInCurrentProfession-label">
                    Number of Year in current profession
                  </InputLabel>
                  <Select
                    labelId="yearsInCurrentProfession-label"
                    id="yearsInCurrentProfession"
                    name="yearsInCurrentProfession"
                    value={currentEmployeer.yearsInCurrentProfession}
                    onChange={(e) =>
                      setCurrentEmployeer({
                        ...currentEmployeer,
                        yearsInCurrentProfession: e.target.value,
                      })
                    }
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                      PaperProps: {
                        className: 'custom-scrollbar', // Apply custom scrollbar class
                        style: {
                          maxHeight: 120, // Adjust the max height to control the size of the dropdown
                        },
                      },
                    }}
                  >
                    {currentProfessionYearsDropdown.map((item, index) => {
                      const [value, label] = item.split(" ");
                      return (
                        <MenuItem key={index} value={value}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </ArthaFormControl>
              </div> */}
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <ArthaFormControl fullWidth>
                  <InputLabel id="firmPremisesVintage-label">
                    Firm / Business Premises Vintage
                  </InputLabel>
                  <Select
                    labelId="firmPremisesVintage-label"
                    id="firmPremisesVintage"
                    name="firmPremisesVintage"
                    value={currentEmployeer.firmPremisesVintage}
                    onChange={(e) =>
                      setCurrentEmployeer({
                        ...currentEmployeer,
                        firmPremisesVintage: e.target.value,
                      })
                    }
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      PaperProps: {
                        className: "custom-scrollbar", // Apply custom scrollbar class
                        style: {
                          maxHeight: 120, // Adjust the max height to control the size of the dropdown
                        },
                      },
                    }}
                  >
                    {firmVintageDropdown &&
                      firmVintageDropdown.map((item, index) => {
                        const [value, label] = item.split(" ");
                        return (
                          <MenuItem key={index} value={value}>
                            {item}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </ArthaFormControl>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Working since"
                    value={currentEmployeer.workfrom || null} // Ensure value is null if not defined
                    onChange={(e) => handleDateChange(e)}
                    renderInput={(params) => <TextField {...params} />}
                    className="yearPicker"
                    maxDate={currentDate}
                    onKeyDown={(e) => e.preventDefault()} // Prevent manual typing
                  />
                </LocalizationProvider>
              </div>
            </div>

            <div className="row mt-3 mt-md-4">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="row">
                  <div className="col-8 col-md-7">
                    <p className="msg">Official mail address (Optional)</p>

                    {isEmailAdded ? (
                      <>
                        <FaExclamationCircle
                          className="badge-icon"
                          style={{ marginTop: "-7px", color: "green" }}
                        />
                        <span
                          className="mt-2 upload-lable"
                          style={{ position: "relative", top: "-10px" }}
                        >
                          {" "}
                          Email Added
                        </span>
                      </>
                    ) : (
                      <>
                        <FaExclamationCircle className="badge-icon" />
                        <span
                          className="mt-2 upload-lable"
                          style={{ position: "relative", top: "-10px" }}
                        >
                          {" "}
                          Not Added
                        </span>
                      </>
                    )}
                  </div>
                  <div className="col-4 col-md-5">
                    <input
                      type="file"
                      ref={fileInputRefID}
                      style={{ display: "none" }}
                      onChange={handleFileChangeID}
                    />
                    <Button
                      variant="contained"
                      className="upload_btn btn_white shadow-none mt-3"
                      onClick={() => {
                        setOpenEmailDialog(true);
                      }}
                    >
                      {isEmailAdded ? "Edit" : "Add Email"}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="row">
                  <div className="col-8 col-md-7">
                    <p className="msg">Firm / Business Address</p>

                    {isAddressAdded ? (
                      <>
                        <FaCheckCircle
                          className="badge-icon"
                          style={{ marginTop: "-7px", color: "green" }}
                        />
                        <span
                          className="mt-2 upload-lable"
                          style={{ position: "relative", top: "-10px" }}
                        >
                          {" "}
                          Address added!
                        </span>
                      </>
                    ) : (
                      <>
                        <FaExclamationCircle className="badge-icon" />
                        <span
                          className="mt-2 upload-lable"
                          style={{ position: "relative", top: "-10px" }}
                        >
                          {" "}
                          Not Added
                        </span>
                      </>
                    )}
                  </div>
                  <div className="col-4 col-md-5">
                    <input
                      type="file"
                      ref={fileInputRefID}
                      style={{ display: "none" }}
                      onChange={handleFileChangeID}
                    />
                    <Button
                      variant="contained"
                      className="upload_btn btn_white shadow-none mt-3"
                      onClick={() => openAddrDialog(isAddressAdded)}
                    >
                      {isAddressAdded ? "Edit" : "Add Address"}
                    </Button>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="row">
                  <div className="col-8 col-md-7 mt-3">
                    <p className="msg">Bank Account Details</p>

                    {bankDetails ? (
                      <>
                        <FaCheckCircle
                          className="badge-icon"
                          style={{ marginTop: "-7px", color: "green" }}
                        />
                        <span
                          className="mt-2 upload-lable"
                          style={{ position: "relative", top: "-10px" }}
                        >
                          {" "}
                          Bank Details Added!
                        </span>
                      </>
                    ) : (
                      <>
                        <FaExclamationCircle className="badge-icon" />
                        <span
                          className="mt-2 upload-lable"
                          style={{ position: "relative", top: "-10px" }}
                        >
                          {" "}
                          Not Added
                        </span>
                      </>
                    )}
                  </div>
                  <div className="col-4 col-md-5">
                    <input
                      type="file"
                      ref={fileInputRefID}
                      style={{ display: "none" }}
                      onChange={handleFileChangeID}
                    />
                    <Button
                      variant="contained"
                      className="upload_btn btn_white shadow-none mt-4"
                      onClick={() => {
                        setOpenBankDialog(true);
                      }}
                    >
                      {bankDetails ? "Edit" : "Add Bank"}
                    </Button>
                    <AddBank
                      openBankDialog={openBankDialog}
                      onCancel={cancelBankDialog}
                      onBankDataSave={onBankDataSave}
                      firmDetails={firmDetails} // Pass firm details here
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="row">
                  <div className="col-8 col-md-7 mt-3">
                    <p className="msg">Supporting Documents</p>

                    {selectedDocs &&
                      selectedDocs.document &&
                      selectedDocs.document.length > 0 ? (
                      selectedDocs.document.map((doc, index) => (
                        <React.Fragment key={`${doc.documentType}-${index}`}>
                          <FaCheckCircle className="badge-icon" style={{ marginTop: "-7px", color: "green" }} />
                          <span className="mt-2 upload-lable" style={{ position: "relative", top: "-10px" }}>
                            {doc.documentType} <br />
                          </span>
                        </React.Fragment>
                      ))

                    ) : (
                      <div>
                        <FaExclamationCircle className="badge-icon" />
                        <span className="mt-2 upload-lable" style={{ position: "relative", top: "-10px" }}>
                          Not Uploaded
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="col-4 col-md-5">
                    <input
                      type="file"
                      ref={fileInputRefID}
                      style={{ display: "none" }}
                      onChange={handleFileChangeID}
                    />
                    <Button
                      variant="contained"
                      className="upload_btn btn_white shadow-none mt-4"
                      onClick={handleUploadDocument}
                    >
                      Upload
                    </Button>
                    <UploadSupportiveBL
                      open={isPopupOpen}
                      existingDocuments={selectedDocs.document}
                      onRemove={removeExistingDoc}
                      handleClose={handlePopupClose}
                      isShopActNumberPresent={currentEmployeer.shopActNumber.length > 0}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3 mt-md-4"></div>

            {/* Dialogs started */}

            <Dialog
              open={isOpenEmailDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div className="modal row">
                <div className="modal-content col-md-6">
                  {!isEmailAdded ? (
                    <h6> Add Official Email ID</h6>
                  ) : (
                    <h6>Edit Email</h6>
                  )}

                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <span>
                        Please enter official email id for your current
                        employer. OTP validation will be needed for confirming
                        the email id.
                      </span>
                    </div>
                    <div className="col-md-12 mt-3">
                      <ArthaTextField
                        name="line2"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            officialEmail: e.target.value,
                          });
                        }}
                        onBlur={() => setEmailTouched(true)}
                        value={formData.officialEmail}
                        fullWidth
                        label="Email ID"
                        helperText={
                          emailTouched &&
                          formData?.officialEmail?.length > 0 &&
                          !isEmailValid(formData.officialEmail) &&
                          "Invalid email format"
                        }
                        error={
                          emailTouched &&
                          formData?.officialEmail?.length > 0 &&
                          !isEmailValid(formData.officialEmail)
                        }
                      />
                    </div>
                  </div>

                  <div className="row mt-4 mx-auto">
                    <div className="col-6 d-flex justify-content-end mt-4">
                      <ArthaClearButton
                        variant="contained"
                        onClick={closeEmailDialog}
                      >
                        Cancel
                      </ArthaClearButton>
                    </div>
                    <div className="col-6 d-flex justify-content-start mt-4">
                      <ArthaContinueButton
                        variant="contained"
                        disabled={!emailRegex.test(formData.officialEmail)} // Disable button if email format is invalid
                        onClick={() => {
                          // handleContinue();
                          openOTPDialog();
                        }}
                        style={{
                          opacity: isButtonClicked ? 0.39 : 1,
                          pointerEvents: isButtonClicked ? "none" : "auto",
                        }}
                      >
                        {isLoading1 ? (
                          <LoadingSpinner style={{ pointerEvents: "none" }} />
                        ) : (
                          "Continue"
                        )}
                      </ArthaContinueButton>
                    </div>
                  </div>
                </div>
              </div>
            </Dialog>

            <Dialog
              open={isOpenOTPDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div className="otpBox">
                <div className="AdharOTPForm">
                  <div className="AdharOTPHeading mt-3">
                    <h5>Otp Validation</h5>

                    <p
                      className="msg mt-4"
                      style={{ padding: "0", margin: "0" }}
                    >
                      Please Enter OTP Sent to
                    </p>
                    <span
                      className="msg mt-4"
                      style={{
                        fontSize: "10px",
                        color: "blue",
                        padding: "0",
                        margin: "0",
                      }}
                    >
                      {formData.officialEmail}
                    </span>

                    <div className="Verify-OTP mt-5">
                      <div className="Verify-OTP-section">
                        <div className="otp-inputs">
                          {otp.map((value, index) => (
                            <input
                              key={index}
                              type="text"
                              className={`otp-input ${isInvalidOtp ? "error" : ""
                                }`}
                              maxLength="1"
                              value={value}
                              onChange={(e) =>
                                handleOtpChange(index, e.target.value)
                              }
                              onKeyDown={(e) => handleInputKeyDown(index, e)}
                              ref={(ref) => (inputRefs.current[index] = ref)} // Store the reference to each input field
                            />
                          ))}
                        </div>

                        <br />

                        {resendTimer > 0 ? (
                          <h6 className="text-center">
                            Resend OTP in:
                            {" "}{resendTimer < 10 ? `0${resendTimer}` : resendTimer} seconds
                          </h6>
                        ) : (
                          <h6 className="text-center">
                            Didn't receive any code?{" "}
                            <a
                              style={{ color: "rgb(255, 136, 0)", textDecoration: "underline", cursor: "pointer" }}
                              onClick={() => { handleReSendOtp(); }} disabled={!isResendClickable}
                            >
                              <h6 className="resend-otp1">Resend OTP</h6>
                            </a>
                          </h6>

                        )}
                      </div>
                      <div className="row mt-5 ">
                        <div className="col-6 d-flex justify-content-end">
                          <ArthaClearButton
                            variant="contained"
                            onClick={() => {
                              setOpenOTPDialog(false);
                            }}
                          >
                            Back
                          </ArthaClearButton>
                        </div>

                        <div className="col-6 d-flex justify-content-start">
                          <ArthaContinueButton
                            onClick={handleVerifyClick}
                            variant="contained"
                            disabled={isInvalidOtp}
                            style={{
                              opacity: isButtonClicked ? 0.39 : 1,
                              pointerEvents: isButtonClicked ? "none" : "auto",
                            }}
                          >
                            {isLoading2 ? (
                              <LoadingSpinner
                                style={{ pointerEvents: "none" }}
                              />
                            ) : (
                              "Continue"
                            )}
                          </ArthaContinueButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Dialog>

            {/* Address Dialog */}

            <Dialog
  open={openAddressDialog}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
  <div className="modal row">
    <div className="modal-content col-md-6">
      <h6>
        {!addresses?.some((address) => address.selectedByUser)
          ? "Add Firm / Business Address"
          : "Edit Address"}
      </h6>
      <div className="row">
        {/* Existing Addresses */}
        <div className="col-12">
          {addresses
            ?.filter(
              (address, index, self) =>
                index === self.findIndex(
                  (a) => a.businessAddressId === address.businessAddressId
                )
            )
            .map((address, index) => (
              <div key={index} className="form-check pl-0">
                <div
                  className={`card shadow-none address-card ${
                    address.selectedByUser ? "selected-card" : ""
                  }`}
                >
                  <div className="col-10 card-body">
                    <FormControlLabel
                      control={
                        <input
                          className="col-1"
                          type="radio"
                          checked={address.selectedByUser}
                          onChange={() => {
                            const updatedAddresses = addresses.map((item) => {
                              if (item.businessAddressId === address.businessAddressId) {
                                return { ...item, selectedByUser: true }; // Mark this address as selected
                              }
                              return { ...item, selectedByUser: false }; // Deselect other addresses
                            });
                          
                            setAddresses(updatedAddresses); // Update addresses state
                            setSelectedAddress(address); // Set the selected address to the correct one
                            setShowAdditionalDiv(false); // Hide "Add New" section
                            checkFormFilled(); // Perform any form checks
                          }}
                          
                        />
                      }
                      value={address.businessAddressId}
                      label={`${address.line1 || ""}${address.line2 ? `, ${address.line2}` : ""}${address.dist ? `, ${address.dist}` : ""}${address.state ? `, ${address.state}` : ""}${address.pin ? `, ${address.pin}` : ""}`}
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="col-3">
          <Button
            variant="contained"
            className="upload_btn add-new-btn btn_white shadow-none mt-3"
            onClick={() => {
              // Reset 'selectedByUser' for all addresses
              const updatedAddresses = addresses.map((item) => ({
                ...item,
                selectedByUser: false, // Deselect all existing addresses
              }));
              setAddresses(updatedAddresses);

              // Clear the current form fields for adding new address
              setCurrentAddress({
                line1: "",
                line2: "",
                pincode: "",
                city: "",
                state: "",
              });

              // Reset error states and additional logic
              setPincodeError("");
              setShowAdditionalDiv(true); // Show "Add New" address form
              
              // Optional: Reset any validation flags or form checks
              checkFormFilled(); // If this function sets additional form states
            }}
          >
            Add New
          </Button>
        </div>

        {/* Add New Section */}
        {showAdditionalDiv && !addresses.some((address) => address.selectedByUser) && (
  <div className="col-12 mt-3">
    <div className="row border card-body">
      <FormControlLabel
        control={<input className="" type="radio" checked />}
        label="Add New Address"
      />
      <div className="row">
        <div className="col-md-6 mt-3">
          <ArthaTextField
            name="line1"
            fullWidth
            value={currentAddress.line1}
            onChange={handleAddressChange}
            label="Address Line 1"
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className="col-md-6 mt-3">
          <ArthaTextField
            name="line2"
            fullWidth
            value={currentAddress.line2}
            onChange={handleAddressChange}
            label="Address Line 2"
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className="col-md-6 mt-3">
          <ArthaTextField
            name="pincode"
            fullWidth
            value={currentAddress.pincode}
            onChange={(e) => {
              const inputValue = e.target.value.replace(/\D/g, "").slice(0, 6);
              handleAddressChange({
                target: {
                  name: "pincode",
                  value: inputValue,
                },
              });
              setPincodeError(inputValue.length === 6 ? "" : "Invalid pincode");
              if (inputValue.length === 6) {
                fetchAddressDetails(inputValue);
              } else if (inputValue.length === 0) {
                setPincodeError("");
              }
            }}
            label="Pincode"
            InputLabelProps={{ shrink: true }}
          />
          <span style={{ color: "red", fontSize: "12px" }}>
            {pincodeError}
          </span>
        </div>
        <div className="col-md-6 mt-3">
          <ArthaTextField
            name="city"
            fullWidth
            value={currentAddress.city}
            onChange={(e) =>
              handleAddressChange({
                target: {
                  name: "city",
                  value: e.target.value.replace(/[^a-zA-Z]/g, ""),
                },
              })
            }
            label="City"
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className="col-md-6 mt-3">
          <ArthaTextField
            name="state"
            fullWidth
            value={currentAddress.state}
            onChange={(e) =>
              handleAddressChange({
                target: {
                  name: "state",
                  value: e.target.value.replace(/[^a-zA-Z]/g, ""),
                },
              })
            }
            label="State"
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </div>
    </div>
  </div>
)}

      </div>

      {/* Footer Buttons */}
      <div className="row mt-4 mx-auto">
        <div className="col-6 d-flex justify-content-end mt-4">
          <ArthaClearButton variant="contained" onClick={closeAddrDialog}>
            Cancel
          </ArthaClearButton>
        </div>
        <div className="col-6 d-flex justify-content-end mt-4">
        <ArthaContinueButton
  variant="contained"
  disabled={!isFormFilled}
  onClick={() => {
    // If we are in "Add New" mode, update the address list
    if (showAdditionalDiv) {
      // Add the new address with businessAddrType as 'other'
      const updatedAddresses = { 
        ...currentAddress, 
       
        businessAddrType: "OTHER"  // Set businessAddrType as 'other'
      };
      
      // setCurrentAddress(updatedAddresses); // Update the addresses state
      setCurrentAddress(updatedAddresses); // Set the selected address to the new address
    } else {
      // Save the selected address (for editing existing ones)
      console.log("Saving address:", selectedAddress); 
      setCurrentAddress(selectedAddress); // Make sure the selected address is saved
    }

    setOpenAddressDialog(false); // Close dialog
    setAddressAdded(true); // Address added state
  }}
>
  Save
</ArthaContinueButton>



        </div>
      </div>
    </div>
  </div>
</Dialog>

            <div className="row mt-4">
              <div className="col-6 d-flex justify-content-end">
                <ArthaClearButton variant="contained" onClick={handleBack}>
                  Back
                </ArthaClearButton>
              </div>
              <div className="col-6 d-flex justify-content-start">
                <ArthaContinueButton
                  variant="contained"
                  onClick={handleContinue}
                  style={{
                    color: "white",
                    backgroundColor: "rgb(255, 98, 0)",
                    opacity: isFormValid ? (isButtonClicked ? 0.39 : 1) : 0.5,
                    transition: "opacity 0.3s ease-in-out", // Adding a transition for a smooth fade
                    pointerEvents: isButtonClicked ? "none" : "auto",
                  }}
                  disabled={!isFormValid} // Disable button if form is invalid or GST is invalid
                >
                  {isLoading3 ? (
                    <LoadingSpinner style={{ pointerEvents: "none" }} />
                  ) : (
                    "Continue"
                  )}
                </ArthaContinueButton>
              </div>
            </div>
          </form>
        </>
      ) : (
        <Refernces
          onContinue={onContinue}
          onCancel={() => setShowReference(false)}
          changeStatus={changeStatus}
        />
      )}
    </>
  );
};

export default ProfessionalDetails;
