import React, { useState, useRef } from "react";
import Webcam from "react-webcam";
import ArthaClearButton from "../../fields/ArthaClearButton";
import { MdDelete } from "react-icons/md";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import ReactSpeedometer from "react-d3-speedometer";
import { handleSignOut } from "./SessionExpiredApi";
import {
  propertyLoanCibilAPI,
  propertyLoanGetImageMapAPI,
  propertyLoanImageMatchAPI,
  propertyLoanBackPageAPI,
  propertyLoanFetchPropertyCrifScoreApi,
  propertyLoanContinueCrifDetailsAPI,
} from "../../actioncreators/actioncreators";
import FetchingCreditscorePopup from "./FetchingCreditscorePopup";
import CreditScore from "./CreditScore";
import "./CreditScore.css";
import LoadingSpinner from "../../fields/spinner";
import { toast } from "react-toastify";

function LapCreditScore({
  onContinue,
  changeApplicantStatus,
}) {
  const [uploadedImage, setUploadedImage] = useState(null);
     const [isFormValid, setIsFormValid] = useState(false);
  const [isPhotoUploaded, setIsPhotoUploaded] = useState(false);
  const webcamRef = useRef(null);
  const [captureMode, setCaptureMode] = useState(false);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);
  const [base64String, setBase64String] = useState(null);
  const location = useLocation();
  const { ap_id, loan_app_id } = location.state || {};
  const [formData, setFormData] = useState({ imageData: [] });
  const [showEmployementDetails, setShowEmployementDetails] = useState(false);
  const [Successmessage, Setmessage] = useState(false);
  const [Status, setStatus] = useState(false);
  
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [creditScore, setCreditScore] = useState();
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed
  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error.response.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }

    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };
  
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await propertyLoanGetImageMapAPI();
  //       const { imageData } = response.data;
  //       setFormData(imageData);
  //       setUploadedImage(`data:image/jpeg;base64,${imageData}`);
  //       const { status } = response.data;
  //       setStatus(status);
  //       // const { message } = response.data;
  //       const { message } = "Successfully Fetched Score";

  //       Setmessage(message);
  //     } catch (error) {
  //       handleApiError(error);
  //       console.error("Error fetching user data:", error);
  //     }
  //   };
  //   fetchData();
  // }, [ap_id, loan_app_id]);
  const [statusCode, setStatusCode] = useState(null);
  const handleClear = async () => {
    // changeStatus("PROPERTY_SUMMARY_DETAILS");
    // changeApplicantStatus("PropertySummary");
      try {
          const response = await propertyLoanBackPageAPI();
          if (response?.data?.businessStatusCode === 2) {
            changeApplicantStatus(response?.data?.nextPage);
          } else {
            toast.info(response.data.message);
          }
        } catch (error) {
          handleApiError(error);
          console.error("Error fetching loan data:", error);
        }

};
 
const hasFetched = useRef(false);

useEffect(() => {
  const fetchCreditScore = async () => {
    if (!hasFetched.current) {
      hasFetched.current = true;
      try {
        const response = await propertyLoanFetchPropertyCrifScoreApi();
        const { crifScore, statusCode } = response.data;
        setCreditScore(crifScore);
        setStatusCode(statusCode);

        // Handle modal display based on statusCode
        if (statusCode === 2.1) {
          setIsModalOpen1(true); // Show FetchingCreditscorePopup
        } else {
          setIsModalOpen(true); // Show Credit Score modal
        }
      } catch (error) {
        handleApiError(error);
        console.error("Error fetching loan data:", error);
      }
    }
  };

  fetchCreditScore();
}, []);

  const closeModal1 = () => setIsModalOpen(false);
  const closeModal2 = () => setIsModalOpen1(false);


  const handleDeleteImage = () => {
    setStatus(null);
    setUploadedImage(null);
    setBase64String(null);
    setCaptureMode(false);
    setIsPhotoUploaded(false);
  };

  const handleTakePhoto = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const hasCamera = devices.some((device) => device.kind === "videoinput");

      if (!hasCamera) {
        showCameraNotFoundAlert();
        return;
      }

      if (webcamRef.current) {
        const photo = webcamRef.current.getScreenshot();
        setUploadedImage(photo);
        setIsPhotoUploaded(true);
        setBase64String(photo.split(",")[1]);
        setCaptureMode(true); // Set capture mode to true after taking the photo
      }
    } catch (error) {
      handleApiError(error);
      console.error("Error capturing photo:", error);
      showCameraErrorAlert();
    }
  };

  const handleRetakePhoto = () => {
    setUploadedImage(null);
    setIsPhotoUploaded(false);
    setBase64String(null);
    setStatus(null);
    setCaptureMode(false); // Set capture mode to false to show the camera window again
  };

  const showCameraNotFoundAlert = () => {
    toast.error(
      "No camera found. Please check if the camera is connected and working properly."
    );
  };

  const showCameraErrorAlert = () => {
    toast.error(
      "Error accessing the camera. Please check if the camera is connected and working properly."
    );
  };


  const handleContinue = async () => {
    setIsLoading(true);
    try {
      const response = await propertyLoanContinueCrifDetailsAPI();
      // const cibilScore = response?.data?.cibilScore;
      // setCreditScore(
      //   cibilScore !== -1 ? cibilScore : "No Credit History available"
      // );
      if (
        response.httpResponseCode === 200 &&
        response.businessStatusCode === 2
      ) {
        changeApplicantStatus(response.nextPage);
      }else {
        // setShowEmployementDetails(true);
        // onContinue();
      }
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching loan data:", error);
    }
  };

  const handleBack = async () => {
    try {
      const response = await propertyLoanBackPageAPI();
      if (response?.data?.businessStatusCode === 2) {
        changeApplicantStatus(response?.data?.nextPage);
      } else {
        toast.info(response.data.message);
      }
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching loan data:", error);
    }
  };

  const closeModal = () => {
    setIsOpen(false);
  };


  const goToSummaryPage = () => {
    changeApplicantStatus("");
  };
  return (

    <>
      <div className="row">
        {/* Modal for Credit Score */}
       

        {/* Modal for Fetching Credit Score */}
     

        {/* Credit Score Section */}
        <div className="col-12">
          <div className="d-flex justify-content-between align-items-center py-2">
            <h6>Credit Score</h6>
            <div
              // onClick={() => console.log("Navigate to Applicant Summary")}
              onClick={goToSummaryPage}
              className="text fw-bold"
              style={{
                color: "orange",
                marginLeft: "auto",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              Go To Applicant Summary
            </div>
          </div>
          <p className="small-heading kyc-form-text-size">
            {creditScore ? "Your credit score is shown below :" : "Fetching  credit score..."}
          </p>
        </div>

        {/* Credit Score Meter */}
        <div className="row py-4" style={{ backgroundColor: "rgb(241 240 238)" }}>
          <div className="col-12 d-flex justify-content-center align-items-center p-4">
            {creditScore ? (
              <div className="position-relative">
                <ReactSpeedometer
                  maxValue={900}
                  minValue={300}
                  value={parseInt(creditScore, 10)}
                  needleColor="#056cf3"
                  startColor="#ff4d4d"
                  segments={5}
                  endColor="#32cd32"
                  textColor="#000000"
                  ringWidth={50}
                  height={200}
                  width={300}
                  needleHeightRatio={0.6}
                  currentValueText={`Credit Score : ${creditScore}`} // Display current score
                  customSegmentLabels={[
                    {
                      text: "Poor",
                      position: "OUTSIDE",
                      color: "rgb(222 110 54)",
                    },
                    {
                      text: "Fair",
                      position: "OUTSIDE",
                      color: "rgb(216 128 49)",
                    },
                    {
                      text: "Good",
                      position: "OUTSIDE",
                      color: "rgb(207 216 45)",
                    },
                    {
                      text: "Very Good",
                      position: "OUTSIDE",
                      color: "rgb(134 166 134)",
                    },
                    {
                      text: "Excellent",
                      position: "OUTSIDE",
                      color: "#33cc33",
                    },
                  ]}
                  renderCustomSegmentLabels={(props) => {
                    const { text, position, color } = props;

                    return (
                      <span className="custom-label" style={{ color }}>
                        {text}
                      </span>
                    );
                  }}
                />
              </div>
            ) : (
              <FetchingCreditscorePopup />
            )}
          </div>
        </div>
      </div>
      <div className="row">

        {Successmessage && (
          <div
            className="col-12 text-center mt-3"
            style={{ color: "green", fontWeight: "bold" }}
          >
            <span>{Successmessage}</span>
          </div>
        )}
      </div>

      <div className="row mt-4">


      <div className="row mt-4 mx-auto">
                        <div className="col-6 d-flex justify-content-end mt-4">
                            <ArthaClearButton variant="contained" onClick={handleClear}>
                                Back
                            </ArthaClearButton>
                        </div>

                        <div className="col-6 d-flex justify-content-start mt-4">
                            <ArthaContinueButton
                                variant="contained"
                                onClick={handleContinue}
                                // disabled={!isFormValid}
                                // style={{
                                //     backgroundColor: isFormValid ? "rgba(255, 115, 0, 1)" : "rgb(255 98 0 / 39%)",
                                //     pointerEvents: isFormValid ? "auto" : "none",
                                // }}
                            >
                                {isLoading ? (
                                    <LoadingSpinner style={{ pointerEvents: "none" }} />
                                ) : (
                                    "Continue"
                                )}
                            </ArthaContinueButton>

                        </div>
                    </div>
      </div>
      {/*<ProcessFailed isOpen={isOpen} closeModal={closeModal} />*/}
    </>

  );
}

export default LapCreditScore;